import React, { useState, useEffect, useRef } from "react";
import { useBreakPoints, useViewport } from "../HistoricalChart/Hooks";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tips } from "../Common/Tooltip";
import Chart from "react-apexcharts";
import { Form, Col, Row, InputGroup, Button, Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Input } from "antd-mobile";
import { Carousel, Collapse, Tabs, Popover } from 'antd';
import ScrollBar, { ScrollBarPercentage, InputWithPercentage } from "../Common/components/ScrollBar";
import { commaize, commaizeFloat } from "../Common";
import {IncomeLimits, newCountyIncomeLimit } from "./Utils";
import "./CalHFA.css";
import { get, set } from "lodash";
import { DownOutlined } from '@ant-design/icons';
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { purchase } from "../State";
import SmartlookClient from "smartlook-client";
import { GMCCDisclaimer } from "./Disclaimer";


const CalHFAComponent = (props) => {
  //without DPA
  const r1 = 6.5;
  const r2 = 6.375;

  //with DPA
  const r3 = 6.625;
  const r4 = 6.5;

  const hbFee1 = 1.369;
  const hbFee2 = 1.358;

  const loid = props.frameLoid == undefined ? props.urlLoid : props.frameLoid;
  const [termIncome, setTermIncome] = useState(100000);
  const [creditScore, setCreditScore] = useState(0);

  const [loanCounty, setLoanCounty] = useState("");
  const [purchasePrice, setPurchsePrice] = useState(500000);
  const [baseRate, setBaseRate] = useState(r3);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [downPaymentDollar, setDownPaymentDollar] = useState(0);
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(0);
  const [assistancePercentage, setAssistancePercentage] = useState(20);
  const [assistancePercentageSplit, setAssistancePercentageSplit] =
    useState(20);
  const [loanAmount, setLoanAmount] = useState(
    purchasePrice * (1 - 0.01 * (assistancePercentage + downPaymentDollar))
  );
  const [appreciationRate, setAppreciationRate] = useState(7.58);
  const [sellYear, setSellYear] = useState(7);
  const [closingCostsPercentage, setClosingCostsPercentage] = useState(7.2);
  const [firsttimehomebuyer, setFirsttimehomebuyer] = useState("");
  const [qualifiedAlien,setQualifiedAlien] = useState("");
  const [affordability, setAffordability] = useState(500000);
  const [smallScreen, setSmallScreen] = useState(false);
  const [firstgenerationHomebuyer, setFirstgenerationHomebuyer] = useState("");
  const [medianIncome, setMedianIncome] = useState(94500);
  const [qualifiedTab, setQualifiedTab] = useState(1);
  const [affordabilityLoanCounty, setAffordabilityLoanCounty] = useState("Alameda");
  const [showContactmeModal, setShowContactmeModal] = useState(false);
  const [showContactDoneModal, setShowContactDoneModal] = useState(false);
  const [borrowerName, setBorrowerName] = useState("");
  const [borrowerEmail, setBorrowerEmail] = useState("");
  const [borrowerPhone, setBorrowerPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [incomeOpen, setIncomeOpen] = useState(false);
  const [countyOpen, setCountyOpen] = useState(false);
  const [countyOpen2, setCountyOpen2] = useState(false);
  const [incomeOpen2, setIncomeOpen2] = useState(false);
  const [homepriceOpen, setHomePriceOpen] = useState(false);
  const [DPAOpen, setDPAOpen] = useState(false);
  const [downpaymentOpen, setDownpaymentOpen] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [affordabilityCreditScore, setAffordabilityCreditScore] = useState(680);
  const [overafford, setOverafford] = useState(false);
  const [calcMaxLoanAmount, setCalcMaxLoanAmount] = useState(1209750);
  const [calcMaxmp, setCalcMaxmp] = useState(0);
  const [modalFromQualify, setModalFromQualify] = useState(false);
  const [overIncomeLimit, setOverIncomeLimit] = useState(false);
  const [ltv, setLtv] = useState(80);
  const [overLTV, setOverLTV] = useState(false);

  const carousel = useRef(null)
  const getCounties = () => {
    let options = [<option value="">Select</option>];
    for (let c in IncomeLimits) {
      options.push(<option value={c}>{c}</option>);
    }
    return options;
  };

  const getcustomerid = () => {
    let searchparams = new URLSearchParams(window.location.search);
    let customerid = searchparams.get("customerid");

    const segments = new URL(window.location.href).pathname.split("/");
    const last = segments.pop() || segments.pop(); // Handle potential trailing slash
    return last;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //determine monthly payment by current loan amount
  useEffect(() => {
    let n = 360;
    let r = (baseRate * 0.01) / 12;
    let mp = (loanAmount * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
    setMonthlyPayment(mp);
  }, [loanAmount, termIncome, affordabilityLoanCounty]);

  useEffect(() => {
    let dti = 0.45
    if (affordabilityLoanCounty !== "" && termIncome !== 0) {
      if (affordabilityCreditScore > 680){
        dti = 0.5
      }
      if (termIncome > newCountyIncomeLimit[affordabilityLoanCounty]){
        setOverIncomeLimit(true)
      }else{
        setOverIncomeLimit(false)
      }
      let maxLoanAmount = 1209750;

      let lamount = purchasePrice * (1 - 0.01 * (assistancePercentage + downPaymentDollar))
      setLoanAmount(
        Math.round(
          (purchasePrice * (1 - 0.01 * (assistancePercentage + downPaymentDollar))/10)*10
        )
        );
      setLtv((lamount/purchasePrice)*100)
      if (lamount *100 /purchasePrice > 97) {
        setOverLTV(true)
      }else{
        setOverLTV(false)
      }
      let maxmp = (termIncome / 12) * dti;
      setCalcMaxmp(maxmp);

      let n = 360;
      let r = (baseRate * 0.01) / 12;

      setCalcMaxLoanAmount(Math.min(maxLoanAmount, (maxmp * (Math.pow(1 + r, n) - 1)) / (r * Math.pow(1 + r, n))));
      let lm = (maxmp * (Math.pow(1 + r, n) - 1)) / (r * Math.pow(1 + r, n))
      let hp =
        Math.min(maxLoanAmount, lm) +
        purchasePrice * 0.01 * assistancePercentage +
        downPaymentDollar*0.01*purchasePrice;
      setAffordability(hp);
      if (hp < purchasePrice) {
        setOverafford(true);
      }else{
        setOverafford(false);
      }

    }
  }, [termIncome, purchasePrice, downPaymentDollar, assistancePercentage, affordabilityCreditScore, baseRate]);

  //determine base rate by termincome and county
  useEffect(() => {
    if (affordabilityLoanCounty !== "") {
      if (termIncome < 0.8 * medianIncome) {
        if (assistancePercentage === 0) {
          setBaseRate(r2);
        } 
        else {
          setBaseRate(r4);
        }
      } else {
        if (assistancePercentage === 0) {
          setBaseRate(r1);
        }
        else {
          setBaseRate(r3);
        }
      }
    }
  }, [affordabilityLoanCounty, termIncome, assistancePercentage]);

  useEffect(() => {
    if (assistancePercentageSplit > assistancePercentage) {
      setAssistancePercentageSplit(assistancePercentage);
    }
  });

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setSmallScreen(true);
    }
  }, []);

  useEffect(() => {
    if (affordabilityLoanCounty !== "") {
      getMedianIncome();
    }
  }, [affordabilityLoanCounty]);

  useEffect(() => {
    if (qualifiedTab === 1 && firsttimehomebuyer === 'true' && qualifiedAlien === 'true' && firstgenerationHomebuyer === 'true') {
      handleHfaTab(2)
    }
  }, [firsttimehomebuyer, qualifiedAlien, firstgenerationHomebuyer]);
  const getMedianIncome = () => {
      fetch("/data/medianincomebycounty", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          State: "CA",
          County: affordabilityLoanCounty,
        })
      })
      .then((res) => {
        if (!res.ok) {
          console.log("medianincomebycounty:",res);
          return
        }
        res.json().then((js) => {
          let ami = js.MedianIncome
          setMedianIncome(ami)
        });
      })
      .catch((err) => {
        console.error(err);
        alert("Some internal error happened. Please try again later.")
      });
  }

  const getAppreciationPercentage = () => {
    if (affordabilityLoanCounty !== "") {
      if (termIncome < 0.8 * medianIncome) {
        return assistancePercentage * 0.75;
      }
    }

    return assistancePercentage;
  };

  const getMortgagePayoff = () => {
    let loanTerm = 30;
    let p = loanAmount;
    let n = loanTerm * 12;
    let ir = baseRate / 1200;
    let denominator = 1 - (1 + ir) ** -n;
    const oneToNMonth = [...Array(360).keys()];

    let remaining = oneToNMonth.slice(0, n).map(function (i, index) {
      let remain = (p * (1 - (1 + ir) ** (-n + i))) / denominator;
      return remain;
    });
    return Math.round(remaining[12 * sellYear]);
  };

  const getMessage = (cat) => {
    let qualfied = 0;
    let qualifiedMsg = "";
    let unQualifiedMsg = "";
    if (cat === "firsttimehomebuyer") {
      if (firsttimehomebuyer !== "") { 
        if (firsttimehomebuyer === "true") {
          qualfied = 1;
          // qualifiedMsg = "First time home buyer";
          qualifiedMsg = "";
        } else {
          qualfied = 2;
          // unQualifiedMsg = "This program is for first time home buyer";
          unQualifiedMsg = "";
        }
      }
    } else if (cat === "qualifiedAlien") {
      if (qualifiedAlien !== "") { 
        if (qualifiedAlien === "true") {
          qualfied = 1;
          // qualifiedMsg = "You are a qualified alien";
          qualifiedMsg = "";
        } else {
          qualfied = 2;
          // unQualifiedMsg = "This program is for qualified aliens";
          unQualifiedMsg = "";
        }
      }
    } else if (cat === "firstgenerationHomebuyer") {
      if (firstgenerationHomebuyer !== "") {
        if (firstgenerationHomebuyer === "true") {
          qualfied = 1;
          // qualifiedMsg = "First-generation home buyer";
          qualifiedMsg = "";
        } else {
          qualfied = 2;
          // unQualifiedMsg = "This program is for first-generation home buyers";
          unQualifiedMsg = "";
        }
      }
    } else if (cat === "county") {
      if (loanCounty !== "") {
        qualfied = 1;
        // qualifiedMsg =
        //   "The income limit in your county is $" +
        //   commaize(newCountyIncomeLimit[loanCounty]);
        qualifiedMsg = "";
      }
    } else if (cat === "income") {
      if (loanCounty !== "") {
        let limit = newCountyIncomeLimit[loanCounty];
        if (termIncome > limit) {
          qualfied = 2;
          unQualifiedMsg =
            "You're over the income limit of $" + commaize(limit);
        } else {
          qualfied = 1;
          // qualifiedMsg =
          //   "You're under the income limit of $" + commaize(limit);
          qualifiedMsg = "";
        }
      }
    } else if (cat === "fico") {
      if (loanCounty !== "") {
        let limit = medianIncome;
        if (
          (termIncome < 0.8 * limit && creditScore < 660) ||
          (termIncome >= 0.8 * limit && creditScore < 680)
        ) {
          if(creditScore!==0 && termIncome < 0.8 * limit && creditScore < 660){
            qualfied = 2;
            unQualifiedMsg = `Your credit score must be at least 660`;
          }
          if(creditScore!==0 && termIncome >= 0.8 * limit && creditScore < 680){
            qualfied = 2;
            unQualifiedMsg = `Your credit score must be at least 680`;
          }
        } else {
          qualfied = 1;
          // qualifiedMsg = "Your credit score is good";
          qualifiedMsg = "";
        }
      }
    }


    return (
      <div
        className="d-flex"
        style={{ fontSize: "12px" }}
      >
        <img
            src={
              qualfied === 0 ? "/images/checked-grey.svg" : qualfied === 1 ? "/images/checked-blue.svg" :  "/images/unchecked.svg"
            }
            style={{
              width: "18px",
              height: "18px",
              marginBottom: "8px",
              marginRight: "5px",
            }}
          />
        <div>{qualfied ===1 ? qualifiedMsg : qualfied===2? unQualifiedMsg:""}</div>
      </div>
    );
  };

  const getIncomeClassname = () => {
    if (termIncome === 0 || loanCounty === "") {
      return "hfaUnselectedBackground";
    }

    if (termIncome > newCountyIncomeLimit[loanCounty]) {
      return "hfaUnqualifiedBackground";
    }
    return "hfaQualifiedBackground";
  };

  const getFicoClassname = () => {
    if (creditScore === 0 || loanCounty === "") {
      return "hfaUnselectedBackground";
    }

    let limit = medianIncome;
    if (
      (termIncome < 0.8 * limit && creditScore < 660) ||
      (termIncome >= 0.8 * limit && creditScore < 680)
    ) {
      return "hfaUnqualifiedBackground";
    }
    return "hfaQualifiedBackground";
  };

  const getResultClassname = () => {
    let q = getQualification();
    if (q === true) {
      return "hfaQualifiedResultBackground";
    } else if (q === false) {
      return "hfaUnqualifiedResultBackground";
    }
    return "";
  };

  const getQualification = () => {
    if (firsttimehomebuyer === "false" || qualifiedAlien === "false" || firstgenerationHomebuyer === "false") {
      return false;
    }

    if (
      loanCounty !== "" &&
      termIncome !== 0 &&
      termIncome > newCountyIncomeLimit[loanCounty] 
    ) {
      return false;
    }

    if (loanCounty !== "" && termIncome !== 0 && creditScore !== 0) {
      let limit = medianIncome;
      if (
        firsttimehomebuyer === "true" && qualifiedAlien === "true" && firstgenerationHomebuyer === "true" &&
        termIncome <= newCountyIncomeLimit[loanCounty] &&
        ((termIncome < 0.8 * limit && creditScore >= 660) ||
          (termIncome >= 0.8 * limit && creditScore >= 680))
      ) {
        return true;
      }
      return false;
    }
    return null;
  };

  const handleCloseModal = () => {
    setShowContactmeModal(false);
    setShowContactDoneModal(false);
  };

  const getAction = () => {
    setModalFromQualify(false)
    setShowContactmeModal(true)
  };

  const getQualifierAction = () => {
    setModalFromQualify(true)
    setShowContactmeModal(true)
  };
  const submitContact = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoadingContact(true);
    fetch("/borrower/calhfa/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CustomerID: getcustomerid(),
        BorrowerName: borrowerName,
        BorrowerEmail: borrowerEmail,
        BorrowerPhone: borrowerPhone,
        County: loanCounty,
        IsQualified: getQualification(),
        LOID: loid === null ? "" : loid,
      }),
    })
    .then((res) => {
      if (!res.ok) {
        setLoadingContact(false);
        console.log("Error in calhfa/contactme:",res);
        alert("Some internal error happened. Please try again later or contact support@zeitro.com.")
        return
      }
      setLoadingContact(false);
      setShowContactmeModal(false);
      setShowContactDoneModal(true);
    })
    .catch((err) => {
      console.error("other issue in /calhfa/contactme:",err);
      setLoadingContact(false);
      alert("Some internal error happened. Please try again later or contact support@zeitro.com.")
    });
    // setShowContactDoneModal(true);
  }
  const getLoanofficerInfo = () => {
    // fetch
  }
  const getContactDoneModal = () => {
    return (
      <Modal id="doneModal" show={showContactDoneModal} onHide={() => setShowContactDoneModal(false)} dialogClassName="contactDoneModal">
        <Modal.Body>
          <div className="callbackDoneTitle pt-3">
            Your request has been submitted successfully. The loan officer will contact you soon.
          </div>
        </Modal.Body>
        <Modal.Footer><Button onClick={handleCloseModal}>Close</Button></Modal.Footer>
      </Modal>
    );
  }

  const getContactmeModal = () => {
    return (
      <Modal
        show={showContactmeModal}
        onHide={() => setShowContactmeModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={modalFromQualify?false:true}
        id = "contactmeModal"
        // dialogClassName="contactmeModal"
      >
        <Modal.Header style={{borderBottom:"none"}} closeButton />
        <Modal.Body>
          <div className="callbackInModal text-center">
            <div className="callbackFormInModal">
              {!isMobile ? (
                <div className="callbackTitle mb-3">
                  Connect now
                </div>
              ):(
                <div className="callbackTitle mb-3">
                  Take the next step
                </div>
              )}
              {!isMobile ? (
                <div className="callbackSubTitle">
                  Your loan officer will be in touch with you shortly.
                </div>
              ):(
                <div className="callbackSubTitle">
                  Request a call back from your loan officer.
                </div>
              )}
              <Form onSubmit={submitContact}>
                <Form.Group className="text-left mt-3 mb-3">
                  <Form.Label className="callbackFormLabel">Name</Form.Label>
                  <Form.Control
                    required
                    className="callbackInput"
                    placeholder="Your name"
                    value={borrowerName}
                    onChange={(e) => setBorrowerName(e.target.value)}
                  />
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    className="callbackInput"
                    placeholder="Your email address"
                    value={borrowerEmail}
                    onChange={(e) => setBorrowerEmail(e.target.value)}
                  />
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    className="callbackInput"
                    placeholder="Phone"
                    value={borrowerPhone}
                    onChange={(e) => setBorrowerPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Row className="d-flex justify-content-center" >
                  {loadingContact ? (
                    <Spinner animation="border" variant="primary" />
                  ) : !isMobile?(
                    <Button className="callbackButton" type="submit">Connect with your loan officer</Button>
                  ):(
                    <Button className="callbackButton" type="submit">Connect now</Button>
                  )}
                </Form.Row>
              </Form>
              {/* <div className="callbackButton mt-2" onClick={() => setShowContactmeModal(false)}>
                Close
              </div> */}
            </div>
            {showContactDoneModal && getContactDoneModal()}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const getEducation = (color) => {
    return (
      <Tips
        placement={smallScreen ? "top" : "left"}
        color={color}
        title="Before you apply"
        content={
          <div>
            <div className="mb-3">
              Your profile is qualified! You must finish the 8-hours Homebuyer
              Education, before you apply for the program.
            </div>
            <div className="mb-3">
              eHome's eight-hour Homebuyer Education and Counseling course is
              the only online course accepted by CalHFA. (fee: $99) Other online
              courses like Frameworks and HomeView are not acceptable because
              they do not provide a one-hour, 1-on-1 counseling follow-up
              session.
            </div>
            <div>
              Please Note: Due to an increase in applicants for the Dream for
              All Shared Appreciation Loan, follow up housing counseling
              sessions and certificates may take up to 7 business days to be
              received.
            </div>
          </div>
        }
      />
    );
  };

  const getQualificationResult = () => {
    let r = getQualification();
    if (r === null) {
      if(getcustomerid()==="zeitro"){
        return <div></div>
      }
      return (
        <div style={{display:"flex",flexDirection:"column",alignItems:"center", justifyContent:"center"}}>
        <div className="hfa-btn apply disabled">Apply now</div>
        {getcustomerid() === "individualcustomers" && loid === "6502d405-7e12-4a7e-8e7e-8ffe960a267d" && <div className="educationLinks text-center" style={{color:"blue",marginTop:"10px"}} onClick={() => window.open("https://calendly.com/mtsay/q-a-for-loan-application","_blank")}>Consult with a CalHFA certified loan officer</div>}
        </div>
      );
    }
    if (r === true) {
      return (
        <div style={{marginTop: 26}} className="text-center">
          <div className="qualification-tips">Congratulations! You are qualified for this program.</div>
          {getcustomerid() ==="individualcustomers" && loid ==="6502d405-7e12-4a7e-8e7e-8ffe960a267d" ? (
            <div>
              <div className="hfa-btn apply mb-2" onClick={() => window.open("https://app.zeitro.com/services/preapp?customerid=individualcustomers&loid=6502d405-7e12-4a7e-8e7e-8ffe960a267d","_blank")}>Apply now</div>
              <span className="educationLinks" style={{color:"blue"}} onClick={() => window.open("https://calendly.com/mtsay/q-a-for-loan-application","_blank")}>Consult with a CalHFA certified loan officer</span>
            </div>
          ):(
            getcustomerid()!=="zeitro" && <div className="hfa-btn apply" onClick={() => getQualifierAction()}>Apply now</div>
          )}
        </div>
      );
    }
    if (r === false) {
      let cid = getcustomerid()
      let redirectUrl = "https://app.zeitro.com/services/preapp?customerid=" + cid
      if (cid === "whatsamortgage") {
        redirectUrl = "https://whatsamortgage.com"
      }
      return (
        <div style={{marginTop: 47}} className="text-center">
          <div className="qualification-tips">Sorry, you are not Pre-Qualified for this program. We may have other options for you.</div>
            {getcustomerid() ==="individualcustomers" && loid ==="6502d405-7e12-4a7e-8e7e-8ffe960a267d" ? (
              <div className="hfa-btn apply" onClick={() => window.open("https://calendly.com/mtsay/q-a-for-loan-application","_blank")}>
                    Mortgage Consultation
              </div>
            ):(
            getcustomerid() !== "zeitro" && <div 
              className="hfa-btn explore-more"
              onClick={() =>
                getQualifierAction()
              }>Explore more products 
            </div>
            )}
        </div>
      );
    }
  };

  const loanDetailsChartColors = ["#D6E2FB", "#C3CDEA", "#8895BC", "#1F3988"];

  const getStackedBarChart = (series,colors,showtitle) => {
    const state = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            barHeight: '100%',
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: showtitle?"5 YEARS LATER":"",
          align: 'left',
          margin: 10,
          offsetX: 20,
          offsetY: 20,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            color: '#1F3988'
          },
        },
        xaxis: {
          categories: [''],
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
        },
        yaxis: {
          show: false
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false
        },
        colors: colors
      },
    };

    return (
      <div>
        <Chart
          options={state.options}
          series={state.series}
          type="bar"
          height={250}
        />
      </div>
    );
  }


  const earningBreakdownItems = [
    {
      key: '1',
      label: (
        <div style={{display:"flex",justifyContent:"space-between"}}>
          {!isMobile ? (
            <div className="hfaBreakdownText">
              You will earn (check to you at closing):
            </div>
          ):(
            <div className="hfaBreakdownText">
              You will earn:
            </div>
          )}
          <div>
            <b className="hfaBodyTitle mr-1">
              $
              {commaize(
                purchasePrice *
                Math.pow(1 + 0.01 * appreciationRate, sellYear) -
                getMortgagePayoff() -
                purchasePrice * 0.01 * assistancePercentage -
                0.01 *
                getAppreciationPercentage() *
                purchasePrice *
                (Math.pow(
                1 + 0.01 * appreciationRate,
                sellYear) -1) -
                0.01 *
                closingCostsPercentage *
                purchasePrice *
                Math.pow(1 + 0.01 * appreciationRate, sellYear)
              )}
            </b>
          </div>
        </div>
      ),
      children:(
        <div className="breakDownCard">
          <div className="d-flex justify-content-between mb-3">
            <div className="hfaBreakdownSubText">Home sold value:</div>
            <b className="hfaBreakdownSubText">
              $
              {commaize(
                purchasePrice *
                  Math.pow(1 + 0.01 * appreciationRate, sellYear)
              )} 
            </b>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div className="hfaBreakdownSubText">- Total loan repayments</div>
            <b className="hfaBreakdownSubText">
              - $
              {commaize(
                purchasePrice * 0.01 * assistancePercentage +
                0.01 *
                  getAppreciationPercentage() *
                  purchasePrice *
                  (Math.pow(
                    1 + 0.01 * appreciationRate,
                    sellYear
                  ) -1) + getMortgagePayoff()
                
              )} 
            </b>
          </div>
          <ul className="hfaBreakdownSubDetails">
            <li>
              <div className="d-flex" style={{justifyContent:"space-between"}}>
                  <div>Dream for All Shared Appreciation Loan</div>
                  <p>
                    {"("+ '$'+
                    `${commaize(
                      purchasePrice * 0.01 * assistancePercentage +
                        0.01 *
                          getAppreciationPercentage() *
                          purchasePrice *
                          (Math.pow(
                            1 + 0.01 * appreciationRate,
                            sellYear
                          ) -
                            1)
                    )}` +")"}
                  </p>
              </div>
            </li>
            <li>
              <div className="d-flex" style={{justifyContent:"space-between"}}>
                <div>Dream for All Conventional First Mortgage remaining balance</div>
                <p>
                  {"("+ '$'+
                  `${commaize(getMortgagePayoff())}` +")"}
                </p>
              </div>
            </li>
          </ul>
          
          <div className="d-flex justify-content-between mb-3">
            <div className="hfaBreakdownSubText">- Cost to sell</div>
            <b className="hfaBreakdownSubText">
              - $
              {commaize(
                0.01 *
                  closingCostsPercentage *
                  purchasePrice *
                  Math.pow(1 + 0.01 * appreciationRate, sellYear)
              )} 
            </b>
          </div>
          <hr />
          <div className="d-flex justify-content-between mb-3">
            {!isMobile ? (
              <div className="hfaBreakdownSubText">
                You will earn (check to you at closing)
              </div>
            ):(
              <div className="hfaBreakdownSubText">
                You will earn
              </div>
            )}
            <b className="hfaBreakdownSubText">
            $
              {commaize(
                purchasePrice *
                Math.pow(1 + 0.01 * appreciationRate, sellYear) -
                getMortgagePayoff() -
                purchasePrice * 0.01 * assistancePercentage -
                0.01 *
                getAppreciationPercentage() *
                purchasePrice *
                (Math.pow(
                1 + 0.01 * appreciationRate,
                sellYear) -1) -
                0.01 *
                closingCostsPercentage *
                purchasePrice *
                Math.pow(1 + 0.01 * appreciationRate, sellYear)
              )}
            </b>
          </div>
        </div>
      ),
    },
  ]

  const totalAppreciationItems = [
    {
      key: 'totalappreciation',
      label: (
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <div className="d-flex align-items-center">
            <div style={{backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
            Total appreciation
          </div>
          <div className="chartDetailsValue">
            $
            {commaize(
                  purchasePrice *
                  Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                  purchasePrice
            )}
          </div>
        </div>
      ),
      children: (
        <>

          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              Your share of appreciation
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                    purchasePrice *
                    Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                    purchasePrice -
                    ( 
                      0.01 *
                      getAppreciationPercentage() *
                      purchasePrice *
                      (Math.pow(
                        1 + 0.01 * appreciationRate,
                        sellYear
                      ) -1)
                    )
              )}
            </div>
          </div>
          
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              DFA share of appreciation
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                  0.01 *
                  getAppreciationPercentage() *
                  purchasePrice *
                  (Math.pow(
                    1 + 0.01 * appreciationRate,
                    sellYear
                  ) -1)
              )}
            </div>
          </div>

        </>
      ),
    }
  ]

  const loansTakenOutItems = [
    {
      key: 'loanstakenout',
      label: (
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <div className="d-flex align-items-center">
            <div style={{backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
            Loans taken out
          </div>
          <div className="chartDetailsValue">
            $
            {commaize(
                 loanAmount+
                  0.01*assistancePercentage*purchasePrice
            )}
          </div>
        </div>
      ),
      children:(
        <>

          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
              DFA Shared Appreciation Loan
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                   0.01*assistancePercentage*purchasePrice
              )}
            </div>
          </div>
          
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
              DFA Conventional First Mortgage
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                 loanAmount
              )}
            </div>
          </div>

        </>
      ),
    }
  ]

  const homeOwnerEquityItems = [
    {
      key: 'homeownerequity',
      label: (
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <div className="d-flex align-items-center">
            <div style={{backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
            Homeowner equity
          </div>
          <div className="chartDetailsValue">
            $
            {commaize(
                 downPaymentDollar*0.01*purchasePrice
            )}
          </div>
        </div>
      ),
      children:(
        <>

          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              Down payment (out of your pocket)
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                   downPaymentDollar*0.01*purchasePrice
              )}
            </div>
          </div>
          
        </>
      ),
    }
  ]

  const loanRepaymentsItems = [
    {
      key: 'loanrepayments',
      label: (
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <div className="d-flex align-items-center">
            <div style={{backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
              Loan repayments
          </div>
          <div className="chartDetailsValue">
            $
            {commaize(
                  0.01 *
                  getAppreciationPercentage() *
                  purchasePrice *
                  (Math.pow(
                    1 + 0.01 * appreciationRate,
                    sellYear
                  ) -1)+
                  purchasePrice * 0.01 * assistancePercentage+
                  getMortgagePayoff()
            )}
          </div>
        </div>
      ),
      children:(
        <>

          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
              DFA Shared Appreciation Loan
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                    0.01 *
                    getAppreciationPercentage() *
                    purchasePrice *
                    (Math.pow(
                      1 + 0.01 * appreciationRate,
                      sellYear
                    ) -1)+
                    purchasePrice * 0.01 * assistancePercentage

              )}
            </div>
          </div>
          
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
              DFA Conventional First Mortgage remaining balance
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                 getMortgagePayoff()
              )}
            </div>
          </div>

        </>
      ),
    }
  ]

  const homeOwnerEquityItems2 = [
    {
      key: 'homeownerequity2',
      label: (
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          <div className="d-flex align-items-center">
            <div style={{backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              Homeowner equity
          </div>
          <div className="chartDetailsValue">
            $
            {commaize(
                 purchasePrice *
                 Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                 purchasePrice -
                 ( 
                   0.01 *
                   getAppreciationPercentage() *
                   purchasePrice *
                   (Math.pow(
                     1 + 0.01 * appreciationRate,
                     sellYear
                   ) -1)
                 ) +
                 (loanAmount-getMortgagePayoff()) +
                  downPaymentDollar*0.01*purchasePrice 
            )}
          </div>
        </div>
      ),
      children:(
        <>

          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              Your share of appreciation
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                    purchasePrice *
                    Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                    purchasePrice -
                    ( 
                      0.01 *
                      getAppreciationPercentage() *
                      purchasePrice *
                      (Math.pow(
                        1 + 0.01 * appreciationRate,
                        sellYear
                      ) -1)
                    )
              )}
            </div>
          </div>
          
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              DFA Conventional First Mortgage paydown
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                  loanAmount-
                  getMortgagePayoff()
              )}
            </div>
          </div>

          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              Down payment (out of your pocket)
            </div>
            <div className="chartDetailsSub mr-2">
              $
              {commaize(
                    downPaymentDollar*0.01*purchasePrice
              )}
            </div>
          </div>

          {/* <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="d-flex align-items-center">
              <div style={{borderRadius:"11px",backgroundColor:"#F8D778",width:"12px",height:"12px",marginRight:"5px"}}/>
              Cost to sell
            </div>
            <div className="chartDetailsSub mr-2">
              -$
              {commaize(
                     0.01 *
                     closingCostsPercentage *
                     purchasePrice *
                     Math.pow(1 + 0.01 * appreciationRate, sellYear)
              )}
            </div>
          </div> */}

        </>
      ),
    }
  ]

  const breakDownTabs = [
    {
      key: 'loandetailsbreakdown',
      label: 'Home value breakdown',
      children: (
        <div className="loansDetailsWraper d-flex px-3 py-3" style={{border:"1px solid #ECECEC", justifyContent:"space-between"}}>
          <div className="homeInitialValueBreakdown">
            <div className="chartHeader" style={{display:"flex", justifyContent:"space-between"}}>
              Home value
              <b className="chartHeaderValue">${commaize(purchasePrice)}</b>
            </div>
            <div id="stackedBarChart">
              {getStackedBarChart(
                [
                  {
                    name: 'Loans Taken Out',
                    data: [loanAmount+
                      0.01*assistancePercentage*purchasePrice
                    ]
                  },
                  {
                    name: 'Homeowner Equity',
                    data: [downPaymentDollar*0.01*purchasePrice]
                  }
                ],
                ["#081265","#F8D778"],
                false
              )}
            </div>
            <Collapse
              className="mb-3"
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (<DownOutlined rotate={isActive ? 180 : 0} style={{color:"#8A8C90"}}/>)}
              items={loansTakenOutItems}
              ghost        
            /> 
            <Collapse
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (<DownOutlined rotate={isActive ? 180 : 0} style={{color:"#8A8C90"}}/>)}
              items={homeOwnerEquityItems}
              ghost        
            /> 
          </div>
          <div className="homeSoldValueBreakdown">
            <div className="chartHeader" style={{display:"flex", justifyContent:"space-between"}}>
              Home sold value
              <b className="chartHeaderValue">$
                {commaize(
                  purchasePrice *
                    Math.pow(1 + 0.01 * appreciationRate, sellYear)
                )}
              </b>
            </div>
            <div id="stackedBarChart">
              {getStackedBarChart(
                [
                  {
                    name: 'Loan Repayments',
                    data: [ 0.01 *
                      getAppreciationPercentage() *
                      purchasePrice *
                      (Math.pow(
                        1 + 0.01 * appreciationRate,
                        sellYear
                      ) -1)+
                      purchasePrice * 0.01 * assistancePercentage+
                      getMortgagePayoff()
                    ]
                  },
                  {
                    name: 'Homeowner Equity',
                    data: [purchasePrice *
                      Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                      purchasePrice -
                      ( 
                        0.01 *
                        getAppreciationPercentage() *
                        purchasePrice *
                        (Math.pow(
                          1 + 0.01 * appreciationRate,
                          sellYear
                        ) -1)
                      ) +
                      (loanAmount-getMortgagePayoff()) +
                      downPaymentDollar*0.01*purchasePrice -
                        0.01 *
                        closingCostsPercentage *
                        purchasePrice *
                        Math.pow(1 + 0.01 * appreciationRate, sellYear)
                    ]
                  }
                ],
                ["#081265","#F8D778"],
                true
              )}
            </div>
            <Collapse
              className="mb-3"
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (<DownOutlined rotate={isActive ? 180 : 0} style={{color:"#8A8C90"}}/>)}
              items={loanRepaymentsItems}
              ghost        
            /> 
            <Collapse
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (<DownOutlined rotate={isActive ? 180 : 0} style={{color:"#8A8C90"}}/>)}
              items={homeOwnerEquityItems2}
              ghost        
            /> 
          </div>
        </div>
      ),
    },
    {
      key: 'homevaluebreakdown',
      label: 'Appreciation breakdown',
      children: (
        <div className="homeValueWraper d-flex px-3 py-3" style={{border:"1px solid #ECECEC", justifyContent:"space-between"}}>
          <div className="homeInitialValueBreakdown">
            <div className="chartHeader" style={{display:"flex", justifyContent:"space-between"}}>
              Home value
              <b className="chartHeaderValue" >${commaize(purchasePrice)}</b>
            </div>
            <div id="stackedBarChart">
              {getStackedBarChart(
                [
                  {
                    name: 'Initial Home Value',
                    data: [purchasePrice]
                  },
                  {
                    name: 'Total Appreciation',
                    data: [
                      purchasePrice *
                      Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                      purchasePrice
                    ]
                  }
                ],
                ["#081265","#F8F9FE"],
                false
              )}
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
              <div className="d-flex align-items-center">
                <div style={{backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
                Initial home value
              </div>
              <div className="chartDetailsValue">${commaize(purchasePrice)}</div>
            </div>
          </div>
          <div className="homeSoldValueBreakdown">
            <div className="chartHeader" style={{display:"flex", justifyContent:"space-between"}}>
              Home sold value
              <b className="chartHeaderValue">$
                {commaize(
                  purchasePrice *
                    Math.pow(1 + 0.01 * appreciationRate, sellYear)
                )}
              </b>
            </div>
            {/* <div id="breakDownChart">
              <Chart 
                options={StackedBarChart().options}
                series={StackedBarChart().series}
                type="bar"
                height={350}
              />
            </div> */}
            <div id="stackedBarChart">
              {getStackedBarChart(
                [
                  {
                    name: 'Initial Home Value',
                    data: [purchasePrice]
                  },
                  {
                    name: 'Total Appreciation',
                    data: [
                      purchasePrice *
                      Math.pow(1 + 0.01 * appreciationRate, sellYear)-
                      purchasePrice
                    ]
                  }
                ],
                ["#081265","#F8D778"],
                true
              )}
            </div>
            <div className="mb-3" style={{display:"flex", justifyContent:"space-between"}}>
              <div className="d-flex align-items-center">
                <div style={{backgroundColor:"#081265",width:"12px",height:"12px",marginRight:"5px"}}/>
                Initial home value
              </div>
              <div className="chartDetailsValue mr-4">${purchasePrice}</div>
            </div>
            <Collapse
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (<DownOutlined rotate={isActive ? 180 : 0} style={{color:"#8A8C90"}}/>)}
              items={totalAppreciationItems}
              ghost        
            />  
          </div>
        </div>
      ),
    }
  ]

  const getChart = (series, labels, colors, totalLabel, width, clsname) => {
    let options = {
      tooltip: {
        y: {
          formatter: function (value) {
            return "$" + commaizeFloat(Math.round(value));
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: { show: true },
              total: {
                show: true,
                showAlways: true,
                label: totalLabel,
                fontSize: "12px",
                fontWeight: "500",
                color: "#6E6E70",
                formatter: function (w) {
                  return (
                    "$" +
                    commaize(
                      Math.round(
                        w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0)
                      )
                    )
                  );
                },
              },
              value: {
                formatter: function (w) {
                  return "$" + commaize(Math.round(w));
                },
              },
            },
            size: "55%",
          },
          customScale: 0.9,
        },
      },
      labels: labels,
      chart: {
        width: width,
        height: "100%",
        type: "donut",
      },
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      legend: {
        fontSize: "14px",
        show: false,
        position: "top",
        formatter: function (val, opts) {
          return (
            val + ": $" + opts.w.globals.series[opts.seriesIndex].toFixed(0)
          );
        },
        horizontalAlign: "left",
        itemMargin: {
          horizontal: 120,
        },
      },
      responsive: [
        {
          breakpoint: 700,
          options: {
            chart: {
              width: "85%",
              // offsetX: -30,
            },
          },
        },
      ],
    };

    return (
      <div className={clsname}>
        <Chart
          key={series}
          options={options}
          series={series}
          type="donut"
          height="100%"
        />
      </div>
    );
  };

  const getDate = () => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return month + "/" + date + "/" + year;
  };

  const irr = (values) => {
    if (values.length === 0) {
      return 0;
    }
    let x0 = 0;
    let x1 = 0;
    for (let i = 0; i < 20; i++) {
      let fValue = 0;
      let fDerivative = 0;
      for (let k = 0; k < values.length; k++) {
        let fk = k;
        fValue += values[k] / Math.pow(1.0 + x0, fk);
        fDerivative += (-fk * values[k]) / Math.pow(1.0 + x0, fk + 1.0);
      }
      x1 = x0 - fValue / fDerivative;
      if (Math.abs(x1 - x0) <= 0.00000001) {
        return x1;
      }
      x0 = x1;
    }
    return 0;
  };

  const getAPR = () => {
    if (affordabilityLoanCounty === "") {
      return 0;
    }
    let costs = 0;
    if (loanAmount <= 726200) {
      costs = loanAmount * 0.0125 + 2000;
    } else {
      if (termIncome < IncomeLimits[affordabilityLoanCounty][0]) {
        costs = loanAmount * (0.0125 + hbFee1 * 0.01) + 3000;
      } else {
        costs = loanAmount * (0.0125 + hbFee2 * 0.01) + 3000;
      }
    }
    let cashFlow = [-(loanAmount - costs)];
    for (let i = 0; i < 30 * 12; i++) {
      cashFlow.push(monthlyPayment);
    }
    let a = irr(cashFlow);
    let apr = a * 12 * 100;
    return Math.round(apr * 1000) / 1000;
  };
  const hfaTabDisable = () => {
    return firsttimehomebuyer !== 'true' || qualifiedAlien !== 'true' || firstgenerationHomebuyer !== 'true'
  }
  const handleHfaTab = (index) => {
    if (index === 1 && qualifiedTab !== 1) {
      carousel.current.prev()
      setQualifiedTab(index)
    } else if (index === 2 && qualifiedTab !== 2) {
      if (!hfaTabDisable()) {
        setQualifiedTab(index)
        carousel.current.next()
      }
    }
  }

  const incomeHide = () => {
    setIncomeOpen(false);
  }

  const handleIncomeOpenChange = (newOpen) => {
    setIncomeOpen(newOpen);
  }

  const countyHide = () => {
    setCountyOpen(false);
  }

  const handleCountyOpenChange = (newOpen) => {
    setCountyOpen(newOpen);
  }

  const countyHide2 = () => {
    setCountyOpen2(false);
  }

  const handleCountyOpenChange2 = (newOpen) => {
    setCountyOpen2(newOpen);
  }

  const incomeHide2 = () => {
    setIncomeOpen2(false);
  }

  const handleIncomeOpenChange2 = (newOpen) => {
    setIncomeOpen2(newOpen);
  }
  
  const homepriceHide = () => {
    setHomePriceOpen(false);
  }

  const handleHomePriceOpenChange = (newOpen) => {
    setHomePriceOpen(newOpen);
  }

  const DPAHide = () => {
    setDPAOpen(false);
  }

  const handleDPAOpenChange = (newOpen) => {
    setDPAOpen(newOpen);
  }

  const downpaymentHide = () => {
    setDownpaymentOpen(false);
  }

  const handleDownpaymentOpenChange = (newOpen) => {
    setDownpaymentOpen(newOpen);
  }

  return (
    <div >
      <div className="hfaLoanDetails text-left py-5">
        <Container>
          <div className="hfaSubTitle text-center mb-4 px-4">
            Please answer the following questions to see if you qualify for the
            program.
          </div>
          <div className="hfaTab">
            <div className={qualifiedTab === 1 ? 'hfaTab-active' : ''} onClick={() =>handleHfaTab(1)}>General Eligibility</div>
            <div className={qualifiedTab === 2 ? 'hfaTab-active' : ''} onClick={() =>handleHfaTab(2)}>Personal Information{hfaTabDisable() && <img src="/images/lock.svg" />}</div>
          </div>
          <Carousel ref={carousel} dots={false}>
            <Row className="hfaQualification">
              <Col
                lg={4}
                xs={12}
                className={
                  "hfaQualificationPart " +
                  (firsttimehomebuyer === ""
                    ? "hfaUnselectedBackground"
                    : firsttimehomebuyer === "true"
                    ? "hfaQualifiedBackground"
                    : "hfaUnqualifiedBackground")
                }
              >
                <Form.Group className="">
                  {getMessage("firsttimehomebuyer")}
                  <Form.Label className="hfaInputLabel" style={{marginBottom:!isMobile?"60px":""}}>
                    Are you a first time homebuyer?
                    <Tips
                      content={
                        <div>
                            CalHFA (California Housing Finance Agency) defines a first-time homebuyer as someone who has not owned or had an ownership interest in their PRINCIPAL RESIDENCE within the past THREE years. This means you: 
                          <ul>
                            <li>
                              CANNOT have been on TITLE for your principal residence in the last three years.
                            </li>
                            <li>
                              CANNOT be married to someone who has been on title for your principal residence in the last three years.
                            </li>
                            <li>
                              CAN have cosigned, inherited property or own a rental property as long as it is not a primary residence and you can prove you live somewhere else.
                            </li>
                            <li>
                              CAN have cosigned (i.e. for your parents) for the home where you live if you are not on title.”
                            </li>
                          </ul>
                        </div>
                      }
                      placement={smallScreen ? "bottom" : "top"}
                      title="What is first time buyer?"
                    />
                  </Form.Label>
                  <Form.Control
                    style={{ height: "2.75rem" }}
                    className="hfaSelect"
                    as="select"
                    value={firsttimehomebuyer}
                    onChange={(e) => {
                      setFirsttimehomebuyer(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="true">Yes, I am</option>
                    <option value="false">No, I am not</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                xs={12}
                className={
                  "hfaQualificationPart " +
                  (qualifiedAlien === ""
                    ? "hfaUnselectedBackground"
                    : qualifiedAlien === "true"
                    ? "hfaQualifiedBackground"
                    : "hfaUnqualifiedBackground")
                }
              >
                <Form.Group className="">
                  {getMessage("qualifiedAlien")}
                  <Form.Label className="hfaInputLabel" style={{marginBottom:!isMobile?"40px":""}}>
                    Are you a citizen, a National of the United States, or a “Qualified Alien?
                    <Tips
                      content={
                        <div>
                          Immigrants considered "Qualified Aliens" include the following:
                          <ul>
                            <li>
                              Persons lawfully admitted for permanent residence;
                            </li>
                            <li>
                              Persons admitted as refugees;
                            </li>
                            <li>
                            Persons granted asylum;
                            </li>
                            <li>
                            Persons granted status as Cuban and Haitian entrants;
                            </li>
                            <li>
                            Persons granted status as Cuban and Haitian entrants;
                            </li>
                            <li>
                            Persons admitted as Amerasian immigrants;
                            </li>
                            <li>
                            Persons whose deportation has been withheld;
                            </li>
                            <li>
                            Persons paroled into the United States for at least one year;
                            </li>
                            <li>
                            Persons granted conditional entry;
                            </li>
                            <li>
                            Persons determined to be battered or subject to extreme cruelty in
the United States by a family member;
                            </li>
                            <li>
                            Victims of trafficking; or
                            </li>
                            <li>
                            Veterans or persons on active duty in the Armed Forces and their
immediate family members.
                            </li>
                          </ul>
                        </div>
                      }
                      placement={smallScreen ? "bottom" : "top"}
                      title="What is a 'Qualified Alien'?"
                    />
                  </Form.Label>
                  <Form.Control
                    disabled={firsttimehomebuyer !== "true" ? true : false}
                    style={{ height: "2.75rem" }}
                    className="hfaSelect"
                    as="select"
                    value={qualifiedAlien}
                    onChange={(e) => {
                      setQualifiedAlien(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="true">Yes, I am</option>
                    <option value="false">No, I am not</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                xs={12}
                className={
                  "hfaQualificationPart " +
                  (firstgenerationHomebuyer === ""
                    ? "hfaUnselectedBackground"
                    : firstgenerationHomebuyer === "true"
                    ? "hfaQualifiedBackground"
                    : "hfaUnqualifiedBackground")
                }
              >
                <Form.Group className="">
                  {getMessage("firstgenerationHomebuyer")}
                  <Form.Label className="hfaInputLabel" style={{marginBottom:!isMobile?"20px":""}}>
                    Are you or at least one co-borrower a current California resident AND a first-generation home buyer?
                    <Tips
                      content={
                        <div>
                           A first-generation homebuyer is defined as: 
                          <ul>
                            <li>
                            a homebuyer who has not been on title, held an ownership interest or have been named on a mortgage to a home (on permanent foundation and owned land) in the United States in the last 7 years,
                            </li>
                            <div className="my-2" style={{fontWeight:"600"}}>
                              AND
                            </div>
                            <li>
                            To the best of the homebuyer’s knowledge whose parents (biological or adoptive) do not have any present ownership interest in a home in the United States or if deceased whose parents did not have any ownership interest at the time of death in a home in the United States,                             </li>
                            <div className="my-2" style={{fontWeight:"600"}}>
                            OR
                            </div>
                            <li>
                            An individual who has at any time been placed in foster care or institutional care (type of out of home residential care for large groups of children by non-related caregivers).                            </li>
                          </ul>
                        </div>
                      }
                      placement={smallScreen ? "bottom" : "top"}
                      title="What is first generation home buyer?"
                    />
                  </Form.Label>
                  <Form.Control
                    disabled={qualifiedAlien !== "true" || firsttimehomebuyer !== "true" ? true : false}
                    style={{ height: "2.75rem" }}
                    className="hfaSelect"
                    as="select"
                    value={firstgenerationHomebuyer}
                    onChange={(e) => {
                      setFirstgenerationHomebuyer(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="true">Yes, I am</option>
                    <option value="false">No, I am not</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="hfaQualification">
              <Col
                lg={4}
                xs={12}
                className={
                  "hfaQualificationPart " +
                  (loanCounty === ""
                    ? "hfaUnselectedBackground"
                    : "hfaQualifiedBackground")
                }
              >
                <Form.Group>
                  {getMessage("county")}
                  <Form.Label className="hfaInputLabel">What’s your county?</Form.Label>
                  <Form.Control
                    disabled={firstgenerationHomebuyer !== "true" ? true : false}
                    style={{ height: "2.75rem" }}
                    className="hfaSelect"
                    as="select"
                    value={loanCounty}
                    onChange={(e) => {
                      setLoanCounty(e.target.value);
                      setAffordabilityLoanCounty(e.target.value);
                    }}
                  >
                    {getCounties()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12} className={"hfaQualificationPart " + getIncomeClassname()}>
                <Form.Group>
                  {getMessage("income")}
                  <Form.Label className="hfaInputLabel">What’s your annual income?</Form.Label>
                  <InputGroup
                    style={{ border: "1px solid #CFD4D9", borderRadius: "4px" }}
                    className="hfaSelect"
                  >
                    <InputGroup.Text style={{ border: "none" }}> $</InputGroup.Text>
                    <Form.Control
                      disabled={loanCounty !== "" ? false : true}
                      type="text"
                      value={commaize(termIncome)}
                      placeholder={termIncome}
                      onChange={(e) => {
                        let n = e.target.value.replace(/[^\d.-]/g, "");
                        if (n === "") {
                          n = 0;
                        }
                        n = parseInt(n);
                        setTermIncome(n);
                      }}
                      style={{ border: "none" }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col lg={4} xs={12} className={"hfaQualificationPart " + getFicoClassname()}>
                <Form.Group>
                  {getMessage("fico")}
                  <Form.Label className="hfaInputLabel">What's your credit score?</Form.Label>
                  <Form.Control
                    disabled={loanCounty !== "" && termIncome !== 0 ? false : true}
                    style={{ height: "2.75rem" }}
                    className="hfaSelect"
                    as="select"
                    value={creditScore}
                    onChange={(e) => {
                      setCreditScore(e.target.value);
                      setAffordabilityCreditScore(e.target.value);
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={650}> {"<660"}</option>
                    <option value={670}>660-680</option>
                    <option value={700}>680+</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Carousel>
          <div className="hfaQualification-bottom">
              {getQualificationResult()}
          </div>
        </Container>
      </div>
      <div className="hfaLoanDetails text-left polishBackground py-5 " style={{border:"none"}}>
        <Container>
          <div className="affordabilityHeader my-3" style={{display:"flex",justifyContent:"center"}}>
            <div className="text-center">
              <div className="hfaHeader mb-3">
                How much can I afford?
              </div>
              <div className="hfaSubTitle mb-5">
                The Dream For All Shared Appreciation Loan is a down payment assistance program that provides first-time homebuyers with up to 20% of the home purchase price. This program must be used in conjunction with the Dream For All Conventional first mortgage.
              </div>
            </div>
          </div>
          <Row>
            <Col md={5} xs={12}>
              <Row className="hfaLable">
                <Col className="pl-0 mb-2">Loan term</Col>
                <Col>30 year, fixed rate</Col>
              </Row>
              <Row className="hfaLable mb-2">
                <Col className="pl-0">{`Interest rate (${getDate()})`} </Col>
                <Col>
                  <div>{baseRate}%</div>
                </Col>
              </Row>
              <Row className="hfaLable mb-2">
                <Col className="pl-0">APR</Col>
                <Col>
                  <div>{getAPR()}%</div>
                </Col>
              </Row>
              <Row className="hfaLable mb-3">
                <Col className="pl-0">LTV</Col>
                <Col>
                  <div>{ltv}%</div>
                </Col>
              </Row>
              <Row className="hfaLable mb-3">
                <Col className="pl-0">Annual income</Col>
                <Col className="d-flex editableComponents" style={{justifyContent:"space-between"}}>
                  <span className="editables">${commaize(termIncome)}</span>
                  <Popover
                    content={
                      <>
                      <div className="d-flex px-3" style={{alignItems:"center",width:"200px", border:"1px solid #ECECEC",backgroundColor:"#FCFCFC",borderRadius:"8px"}}>
                        $
                        <Form.Control
                          className="ml-2"
                          type="text"
                          value={commaize(termIncome)}
                          placeholder={termIncome}                       
                          onChange={(e) => {
                            let n = e.target.value.replace(/[^\d.-]/g, "");
                            if (n === "") {
                              n = 0;
                            }
                            n = parseInt(n);
                            setTermIncome(n);
                          }}
                          style={{ border: "none", backgroundColor:"#FCFCFC" }}
                        />
                      </div>
                      <div className="text-right mt-2">
                        <a onClick={incomeHide} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                      </div>
                      </>
                      }
                      title="Annual income"
                      trigger="click"
                      open={incomeOpen}
                      onOpenChange={handleIncomeOpenChange}
                    >
                      <span className="inputLink">Edit</span>
                  </Popover>
                </Col>
              </Row>
              <Row className="hfaLable mb-3">
                <Col className="pl-0">County</Col>
                <Col className="d-flex editableComponents" style={{justifyContent:"space-between"}}>
                  <span className="editables" >{affordabilityLoanCounty}</span>
                  <Popover
                    content={
                      <>
                        <div className="d-flex" style={{alignItems:"center",width:"200px",backgroundColor:"#FCFCFC"}}>
                        <Form.Control
                          className="countyEdtitable"
                          as="select"
                          value={affordabilityLoanCounty}
                          onChange={(e) => {
                            setAffordabilityLoanCounty(e.target.value);
                          }}
                        >
                          {getCounties()}
                        </Form.Control>
                        </div>
                        <div className="text-right mt-2">
                          <a onClick={countyHide} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                        </div>
                      </>
                      }
                      title="County"
                      trigger="click"
                      open={countyOpen}
                      onOpenChange={handleCountyOpenChange}
                    >
                      <span className="inputLink">Edit</span>
                  </Popover>
                </Col>
              </Row>
              <Row className="hfaLable mb-3" style={{alignItems:"center"}}>
                <Col xs={12} className="pl-0">Target home price</Col>
                <Col xs={12} className="affordabilityScrollBars">
                  <ScrollBar
                    hideSlider={true}
                    max={3000000}
                    min={1}
                    step={1000}
                    initVal={purchasePrice}
                    scrollChangeValue={(val) => {
                      setPurchsePrice(val);
                    
                    }}
                  />
                </Col>
              </Row>
              {/* <Row
                style={{
                  backgroundColor: "#D6E2FB",
                  borderRadius: "6px",
                  padding: "17px",
                  color: "#1F3988",
                  fontWeight: "600",
                  width: "97%",
                  marginBottom: "10px",
                }}
              >
                This program may offer you up to 20% of the purchase price
                towards your down payment and/or closing costs
              </Row> */}
              <Row className="hfaLable mb-3" style={{alignItems:"center"}}>
                <Col xs={12} className="pl-0">Shared appreciation loan</Col>
                <Col xs={12} className="affordabilityScrollBars">
                  <InputWithPercentage
                    max={Math.min(
                      20,
                      150000/purchasePrice*100
                    )}
                    min={0}
                    step={0.1}
                    initVal={Math.min(assistancePercentage,150000/purchasePrice*100)}
                    inputChangePercentage={(val) => {
                      setAssistancePercentage(val);
                      setLoanAmount(
                        purchasePrice -
                          purchasePrice * 0.01 * val -
                          downPaymentDollar
                      );
                    }}
                    percentageOf={purchasePrice}
                  />
                </Col>
              </Row>
              <Row className="hfaLable mb-4" style={{alignItems:"center"}}>
                <Col xs={12} className="pl-0">Your down payment</Col>
                <Col className="affordabilityScrollBars" xs={12}>
                  <InputWithPercentage
                    max={5}
                    min={0}
                    step={100}
                    initVal={downPaymentDollar}
                    inputChangePercentage={(val) => {
                      setDownPaymentDollar(val);
                      setLoanAmount(
                        purchasePrice -
                          purchasePrice * assistancePercentage * 0.01 -
                          val*purchasePrice*0.01
                      );
                    }}
                    percentageOf={purchasePrice}
                  />
                </Col>
              </Row>
              <Row>
                <div className="affordabilityDisclaimer">
                  <div className="affordabilityDisclaimerText mb-3">
                    *This program offers financial assistance for up to <span className="heavyText">20%</span> of your down payment, capped at <span className="heavyText">$150,000</span>.
                  </div>
                  <div className="affordabilityDisclaimerText">
                    *The <span className="heavyTextA">maximum down payment you can pay</span> out of pocket is <span className="heavyText">5%</span> of the home price.
                  </div>
                </div>
              </Row>
            </Col>
            <Col md={7} xs={12}>
              <Row
                className="px-3 py-3 mb-3"
                style={{
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
                  borderRadius: "6px",
                  width: "100%",
                  backgroundColor: "#FFF",
                }}
              >
                {overIncomeLimit ? (
                  <Col className="overAffordText" style={{paddingTop:"10px",paddingBottom:"10px",border:"1px solid #ECECEC",borderRadius:"8px",backgroundColor:"#FBEAD6",display:"flex",justifyContent:"center",flexDirection:"column"}}>
                    <Row className="mb-3">Your current annual income is over the county income limit of ${newCountyIncomeLimit[affordabilityLoanCounty]} and is not qualified for the shared appreciation loan.</Row>
                    <Row>Please contact us for more options.</Row>
                  </Col>
                ): overLTV ? (
                  <Col className="overAffordText" style={{paddingTop:"10px",paddingBottom:"10px",border:"1px solid #ECECEC",borderRadius:"8px",backgroundColor:"#FBEAD6",display:"flex",justifyContent:"center",flexDirection:"column"}}>
                    <Row className="mb-3">Your current LTV is over the restriction of max 97% LTV for the California Dream For All Conventional Loan.</Row>
                    <Row>Please try lowering your target home price or increase your down payment, or contact us for more options.</Row>
                  </Col>
                ):overafford ? (<>
                  <Col className="overAffordText" style={{paddingTop:"10px",paddingBottom:"10px",border:"1px solid #ECECEC",borderRadius:"8px",backgroundColor:"#FBEAD6",display:"flex",justifyContent:"center",flexDirection:"column"}}>
                    <Row className="mb-3">Your current target home price is not affordable based on your information.</Row>
                    <Row>Please try lowering your target home price or increase your down payment, or contact us for more options</Row>
                  </Col>
                  <Col style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:!overafford?"0":""}}>
                    <Row style={{display:"flex",flexDirection:overafford?"column":"row"}}>
                      <Col>
                        <Row className="hfaLable">You can afford a home up to:</Row>
                        <Row className="hfaBigText">${commaize(affordability)}</Row>
                      </Col>
                    </Row>
                  </Col></>
                ):(
                  <Col style={{paddingTop:"10px",paddingBottom:"10px",paddingLeft:!overafford?"0":""}}>
                    <Row style={{display:"flex",flexDirection:overafford?"column":"row"}}>
                      <Col>
                        <Row className="hfaLable">You can afford a home up to:</Row>
                        <Row className="hfaBigText">${commaize(affordability)}</Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                </Row>
              <Row
                className="px-3 py-3 mb-3"
                style={{
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
                  borderRadius: "6px",
                  backgroundColor: "#FFF",
                }}
              >
                <Col>
                  <Row className="hfaLable">Loan amount:</Row>
                  <Row className="hfaBigText">${commaize(loanAmount)}</Row>
                </Col>
                <Col>
                  <Row className="hfaLable">Monthly payment:</Row>
                  <Row className="hfaBigText">
                    ${commaize(monthlyPayment)}/mo
                  </Row>
                </Col>
              </Row>
              <Row
                className="py-4 px-3"
                style={{
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.06)",
                  borderRadius: "6px",
                  height: "56%",
                  backgroundColor: "#FFF",
                }}
              >
                <Col>
                  <Row className="hfaLable mb-4">Breakdown:</Row>
                  <Row>
                    <Col md={6} xs={12} className="pl-0">
                      <Row className="mb-2 pl-0">
                        <Col className="px-0">
                          <div className="hfaLable pl-0 d-flex ">
                            <div
                              className="hfaCircle"
                              style={{
                                backgroundColor: loanDetailsChartColors[0],
                              }}
                            ></div>
                            Down payment
                          </div>
                          <div style={{ marginLeft: "20px" }}>
                            ${commaize(downPaymentDollar*0.01*purchasePrice)}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2 pl-0">
                        <Col className="px-0">
                          <div className="hfaLable pl-0 d-flex ">
                            <div
                              className="hfaCircle"
                              style={{
                                backgroundColor: loanDetailsChartColors[1],
                              }}
                            ></div>
                            Shared appreciation loan
                          </div>
                          <div style={{ marginLeft: "20px" }}>
                            $
                            {commaize(
                              purchasePrice * assistancePercentageSplit * 0.01
                            )}
                          </div>
                        </Col>
                      </Row>
                      {/* <Row className="mb-2 pl-0">
                        <Col className="px-0">
                          <div className="hfaLable pl-0 d-flex ">
                            <div
                              className="hfaCircle"
                              style={{
                                backgroundColor: loanDetailsChartColors[2],
                              }}
                            ></div>
                            Closing Cost Assistance
                          </div>
                          <div style={{ marginLeft: "20px" }}>
                            $
                            {commaize(
                              purchasePrice *
                                (assistancePercentage -
                                  assistancePercentageSplit) *
                                0.01
                            )}
                          </div>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col className="px-0">
                          <div className="hfaLable pl-0 d-flex ">
                            <div
                              className="hfaCircle"
                              style={{
                                backgroundColor: loanDetailsChartColors[3],
                              }}
                            ></div>
                            Loan amount
                          </div>
                          <div style={{ marginLeft: "20px" }}>
                            {" "}
                            ${commaize(loanAmount)}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={12} className="px-0">
                      {getChart(
                        [
                          downPaymentDollar*0.01*purchasePrice,
                          purchasePrice * assistancePercentageSplit * 0.01,
                          // purchasePrice *
                          //   (assistancePercentage - assistancePercentageSplit) *
                          //   0.01,
                          loanAmount,
                        ],
                        [
                          "Down payment out of your pocket",
                          "Down payment assistance",
                          // "Closing costs assistance",
                          "Loan amount",
                        ],
                        loanDetailsChartColors,
                        "Home Value",
                        "300px",
                        "hfaLoanDetailsChartWrap"
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-left hfaLoanDetails polishBackground">
        {getcustomerid()==="individualcustomers" && loid ==="6502d405-7e12-4a7e-8e7e-8ffe960a267d" ? (
          <Container className="py-5">
            <Row>
              <Col md={5}>
                <div className="hfaBodyTitle mb-4">
                  Here is what to expect next
                </div>
                <div className="hfaBodyText mb-4">
                  CalHFA Dream for All loans are limited, and recipients are chosen through a lottery system
                </div>
                <div className="hfaBodyText mb-5">
                  Due to the time-sensitive nature of this process, we highly recommend you discuss your eligibility with a loan officer at your earliest convenience.
                </div>
              </Col>
              <Col md={7} className="px-3">
                <ol className="steps-list">
                  <li>
                    Talk to a <span className="educationLinks" style={{color:"blue"}} onClick={() => window.open("https://calendly.com/mtsay/q-a-for-loan-application","_blank")}>CalHFA certified loan officer</span> offering the Dream for All Program.
                  </li> 
                  <li>Work with your loan officer to secure a <span className="educationLinks" onClick={() => window.open("https://www.calhfa.ca.gov/homeownership/forms/pre-approval-letter-cadfa.pdf",'_blank')}>CalHFA Dream for All Pre-Approval Letter</span>.</li>
                </ol>            
              </Col>
            </Row>
            <Row className="d-flex">
              <div className="hfa-btn-whatsnext ml-2 mt-3" onClick={() => window.open("https://app.zeitro.com/services/preapp?customerid=individualcustomers&loid=6502d405-7e12-4a7e-8e7e-8ffe960a267d","_blank")}>
                    Apply now
              </div>
              <div className="hfa-btn-whatsnext ml-2 mt-3" onClick={() => window.open("https://calendly.com/mtsay/q-a-for-loan-application","_blank")}>
                    Mortgage Consultation
              </div>
            </Row>
          </Container>
        ):(
          <Container className="py-5">
            <Row>
              <Col md={5}>
                <div className="hfaBodyTitle mb-4">
                  Here is what to expect next
                </div>
                <div className="hfaBodyText mb-4">
                  CalHFA Dream for All loans are limited, and recipients are chosen through a lottery system
                </div>
                <div className="hfaBodyText mb-5">
                  Due to the time-sensitive nature of this process, we highly recommend you discuss your eligibility with a loan officer at your earliest convenience.
                </div>
                {getcustomerid()!=="zeitro" && !isMobile &&<div className="hfa-btn-whatsnext" onClick={() => getAction()}>
                    Apply now
                </div>}
              </Col>
              <Col md={7} className="px-3">
                <ol className="steps-list">
                  <li>Talk to a CalHFA preferred loan officer offering the Dream for All Program.</li> 
                  <li>Work with your loan officer to secure a <span className="educationLinks" onClick={() => window.open("https://www.calhfa.ca.gov/homeownership/forms/pre-approval-letter-cadfa.pdf",'_blank')}>CalHFA Dream for All Pre-Approval Letter</span>.</li>
                  <li>The registration application portal will open in <b>April</b>. You must submit an application and your pre-approval letter to be entered into a <b>randomized drawing</b> for a chance to participate in the program.</li>
                  <li>Take the free online 1-hour <span className="educationLinks" onClick={() => window.open("https://www.calhfadreamforall.com/",'_blank')}>California Dream For All education course</span> which covers what shared appreciation is and how it affects your mortgage repayment.</li>
                  <li>You will receive a <b>CalHFA Dream for All voucher</b> if you are selected as a recipient from the randomized drawing. This voucher is valid for 90 days.</li>
                  <li>You must <b>find a home, get under contract</b>, and <b>reserve your rate lock</b> within <b>90 days</b>.</li>
                  <li>That's it! Congratulations on completing the CalHFA Dream for All process!</li>
                </ol>            
              </Col>
              {getcustomerid()!=="zeitro" && isMobile && <div style={{display:"flex", justifyContent:"center"}}>
                <div className="hfa-btn-whatsnext" onClick={() => getAction()}>
                      Apply now
                </div>
              </div>}
            </Row>
          </Container>
        )}
      </div>                  
      <div className="hfaLoanDetails text-left pt-4" style={{border:"none"}}>
        <Container>
          <Row className="py-5">
            <Col md={6} xs={12}>
              <div className="hfaHeader mb-5">
                Here's what happens in the future.
              </div>
              <div className="hfaBodyTitle mb-4">Home Appreciation</div>
              <div className="mb-4 hfaBodyText">
                Home appreciation refers to the increase in value of a house or investment property over time. When paying back the original Dream For All Appreciation loan, you will also pay back a share of the appreciation. The amount of this share will be relative to your original appreciation loan amount.
              </div>
              <div className="mb-5 hfaBodyText">
                In California, the average appreciation rate is <b>7.58%.</b>
              </div>
              <div className="finePrints text-left">
                * Value based on average appreciation rates over the last 10 years in California from <span onClick={() => window.open("https://www.neighborhoodscout.com/ca/real-estate",'_blank')}style={{textDecoration:"underline", cursor:"pointer"}}>www.neighborhoodscout.com/ca/real-estate</span>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="hfaBodyTitle mb-4 mt-5">No interest, no monthly payments</div>
              <div className="mb-4 hfaBodyText">
                The Dream for All Appreciation Program has NO INTEREST and no monthly payments. You will pay back the original loan amount plus a portion of your home appreciation when you:
              </div>
              <div className="mb-4 hfaBodyText">
                <b style={{color:"#222", fontWeight:"600"}}>Sell your home</b>
                <div>
                  The selling price is used to determine the amount of shared equity.
                </div>
              </div>
              <div className="mb-4 hfaBodyText">
                <b style={{color:"#222", fontWeight:"600"}}>Refinance the home for the second time</b>
                <div>
                  You can only refinance (Rate and Term) your home once under this loan.
                </div>
              </div>
              <div className="mb-5 hfaBodyText">
                <b style={{color:"#222", fontWeight:"600"}}>In 30 years</b>
                <div>
                  You must pay back the loan by the end of the 30 year period.
                </div>
              </div>
              <div className="finePrints text-right">
                *Other cases may apply
              </div>
            </Col>
          </Row>
          <hr />
        </Container>
      </div>

      <div className="hfaLoanDetails text-left" style={{borderBottom:"none"}}>
        <Container>
          <Row style={{ fontSize: "24px", fontWeight: "700" }} className="my-4">
            <Col className="text-left hfaBodyTitle px-0">Let's help you calculate</Col>
          </Row>
          <Row className="mb-3">
            <Col md={2} xs={12} className="breakDownEditables inputLinkText d-flex px-0" style={{alignItems:"center"}}>
              <div>County:</div>
              <Popover
                    content={
                      <>
                        <div className="d-flex" style={{alignItems:"center",width:"200px",backgroundColor:"#FCFCFC"}}>
                        <Form.Control
                          className="countyEdtitable"
                          as="select"
                          value={affordabilityLoanCounty}
                          onChange={(e) => {
                            setAffordabilityLoanCounty(e.target.value);
                          }}
                        >
                          {getCounties()}
                        </Form.Control>
                        </div>
                        <div className="text-right mt-2">
                          <a onClick={countyHide2} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                        </div>
                      </>
                      }
                      title="County"
                      trigger="click"
                      open={countyOpen2}
                      onOpenChange={handleCountyOpenChange2}
                    >
                      <span className="inputLink ml-1">{affordabilityLoanCounty}</span>
                  </Popover>
            </Col>
            <Col md={2} xs={12} className="breakDownEditables inputLinkText d-flex px-0" style={{alignItems:"center"}}>
              <div>Income:</div>
              <Popover
                    content={
                      <>
                      <div className="d-flex px-3" style={{alignItems:"center",width:"200px", border:"1px solid #CFD4D9",backgroundColor:"#FCFCFC",borderRadius:"8px"}}>
                        $
                        <Form.Control
                          className="ml-2"
                          type="text"
                          value={commaize(termIncome)}
                          placeholder={termIncome}                       
                          onChange={(e) => {
                            let n = e.target.value.replace(/[^\d.-]/g, "");
                            if (n === "") {
                              n = 0;
                            }
                            n = parseInt(n);
                            setTermIncome(n);
                          }}
                          style={{ border: "none", backgroundColor:"#FCFCFC" }}
                        />
                      </div>
                      <div className="text-right mt-2">
                        <a onClick={incomeHide2} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                      </div>
                      </>
                      }
                      title="Annual income"
                      trigger="click"
                      open={incomeOpen2}
                      onOpenChange={handleIncomeOpenChange2}
                    >
                      <span className="inputLink ml-1">${commaize(termIncome)}</span>
                  </Popover>
            </Col>
            <Col md={2} xs={12} className="breakDownEditables inputLinkText d-flex px-0" style={{alignItems:"center"}}>
              <div>Home price:</div>
              <Popover
                    content={
                      <>
                      <div className="d-flex" style={{alignItems:"center",width:"200px", backgroundColor:"#FCFCFC",borderRadius:"8px"}}>
                        <ScrollBar
                          hideSlider={true}
                          max={3000000}
                          min={1}
                          step={1000}
                          initVal={purchasePrice}
                          scrollChangeValue={(val) => {
                            setPurchsePrice(val);
                            setLoanAmount(
                              val -
                                val * 0.01 * assistancePercentage -
                                downPaymentDollar
                            );
                          }}
                        />
                      </div>
                      <div className="text-right mt-2">
                        <a onClick={homepriceHide} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                      </div>
                      </>
                      }
                      title="Home price"
                      trigger="click"
                      open={homepriceOpen}
                      onOpenChange={handleHomePriceOpenChange}
                    >
                      <span className="inputLink ml-1">${commaize(purchasePrice)}</span>
                  </Popover>
            </Col>
            <Col md={3} xs={12} className="breakDownEditables inputLinkText d-flex px-0" style={{alignItems:"center"}}>
              <div>Shared appreciation loan:</div>
              <Popover
                    content={
                      <>
                      <div className="d-flex" style={{alignItems:"center",width:"200px",backgroundColor:"#FCFCFC",borderRadius:"8px"}}>
                        <InputWithPercentage
                          max={Math.min(
                            20,
                            150000/purchasePrice*100
                          )}
                          min={0}
                          step={0.1}
                          initVal={Math.min(assistancePercentage, 150000/purchasePrice*100)}
                          inputChangePercentage={(val) => {
                            setAssistancePercentage(val);
                            setLoanAmount(
                              purchasePrice -
                                purchasePrice * 0.01 * val -
                                downPaymentDollar
                            );
                          }}
                          percentageOf={purchasePrice}
                        />
                      </div>
                      <div className="text-right mt-2">
                        <a onClick={DPAHide} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                      </div>
                      </>
                      }
                      title="Shared appreciation loan"
                      trigger="click"
                      open={DPAOpen}
                      onOpenChange={handleDPAOpenChange}
                    >
                      <span className="inputLink ml-1">${commaize(Math.min(0.01*assistancePercentage*purchasePrice, 150000))}</span>
                  </Popover>
            </Col>
            <Col md={3} xs={12} className="breakDownEditables inputLinkText d-flex px-0" style={{alignItems:"center"}}>
              <div>Your Down payment:</div>
              <Popover
                    content={
                      <>
                      <div className="d-flex" style={{alignItems:"center",width:"200px",backgroundColor:"#FCFCFC",borderRadius:"8px"}}>
                        <InputWithPercentage
                          max={5}
                          min={0}
                          step={100}
                          initVal={downPaymentDollar}
                          inputChangePercentage={(val) => {
                            setDownPaymentDollar(val);
                            setLoanAmount(
                              purchasePrice -
                                purchasePrice * assistancePercentage * 0.01 -
                                val*purchasePrice*0.01
                            );
                          }}
                          percentageOf={purchasePrice}
                        />
                      </div>
                      <div className="text-right mt-2">
                        <a onClick={downpaymentHide} className="inputLink" style={{color:"#325CEB"}}>Close</a>
                      </div>
                      </>
                      }
                      title="Down payment"
                      trigger="click"
                      open={downpaymentOpen}
                      onOpenChange={handleDownpaymentOpenChange}
                    >
                      <span className="inputLink ml-1">${commaize(downPaymentDollar*0.01*purchasePrice)}</span>
                  </Popover>
            </Col>
          </Row>
          {overIncomeLimit ? (
            <Row className="overAffordText mb-3" style={{color:"#CB8600"}}>
              Your current annual income is over the county income limit of ${newCountyIncomeLimit[affordabilityLoanCounty]} and is not qualified for the shared appreciation loan. Please contact us for more options.
            </Row>
          ): overLTV ? (
            <Row className="overAffordText mb-3" style={{color:"#CB8600"}}>
              Your current LTV is over the restriction of max 97% LTV for the California Dream For All Conventional Loan. Please use the affordability calculator above to identify a more feasible purchasing option.
            </Row>
          ):overafford ? (
            <Row className="overAffordText mb-3" style={{color:"#CB8600"}}>
              Your current target home price is not affordable based on your information. Please use the affordability calculator above to identify a more feasible purchasing option.
            </Row>
          ):""}
          <div className="px-4 py-3" style={{width:"100%",border:"1px solid #DDD", borderRadius:"8px 8px 0px 0px"}}>
            <Row style={{justifyContent:"space-between"}}>
              <Col md={3} xs={12}>
                <div className="mb-2">If you sell the home in</div>
                <div className="appreciationInputWraper">
                  <Input 
                    type="number" 
                    value={sellYear} 
                    onChange={(e) => setSellYear(e)}
                  />
                  <div>years</div>
                </div>
              </Col>
              <Col md={3} xs={12}>
                <div className="mb-2">Annual appreciation rate</div>
                <div className="appreciationInputWraper">
                  <Input 
                    type="number" 
                    value={appreciationRate} 
                    onChange={(e) => setAppreciationRate(e)}
                  />
                  <div>%</div>
                </div>
              </Col>
              <Col md={3} xs={12}>
                <div className="mb-2">Cost to sell</div>
                <div className="appreciationInputWraper">
                  <Input 
                    type="number" 
                    value={closingCostsPercentage} 
                    onChange={(e) => setClosingCostsPercentage(e)}
                  />
                  <div>%</div>
                </div>
              </Col>
            </Row>
            <hr />
            <Collapse
              bordered={false}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (<DownOutlined rotate={isActive ? 180 : 0} style={{color:"#8A8C90"}}/>)}
              items={earningBreakdownItems}
              ghost        
            />         
          </div>
          <Tabs
            defaultActiveKey="loandetailsbreakdown"
            items={breakDownTabs}
            className="hfaBreakdownTabs mt-4 mb-5"
          />
        </Container>
      </div>

      {getcustomerid() !== "zeitro" && <div className="connectionForm">
        <Row>
          <Col xs={12} className="pt-4">
            <div className='connectionFormWraper'>
              <div className="connectionFormTitle mb-4">
                Ready to become a homeowner?
              </div>
              <div className="connectionSubTitle mb-4">
                We're excited to hear from you and help you find your dream home. Fill out the form below and your loan officer will get in touch with you shortly. 
              </div>
              <Form onSubmit={submitContact}>
                <Form.Group className="text-left mt-3 mb-3">
                  <Form.Label className="callbackFormLabel">Name</Form.Label>
                  <Form.Control
                    required
                    style={{backgroundColor: "white"}}
                    className="callbackInput"
                    placeholder="Your name"
                    value={borrowerName}
                    onChange={(e) => setBorrowerName(e.target.value)}
                  />
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    style={{backgroundColor: "white"}}
                    className="callbackInput"
                    placeholder="Your email address"
                    value={borrowerEmail}
                    onChange={(e) => setBorrowerEmail(e.target.value)}
                  />
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    style={{backgroundColor: "white"}}
                    className="callbackInput"
                    placeholder="Phone"
                    value={borrowerPhone}
                    onChange={(e) => setBorrowerPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Row className="d-flex justify-content-center">
                  {loadingContact ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Button className="callbackButton" type="submit">Connect with a loan officer</Button>
                  )}
                </Form.Row>
              </Form>
            </div>
          </Col>
          {/* {!isMobile && <Col md={5} xs={12} style={{padding:"0px"}}>
            <img 
             src="/images/potential-homebuyer.png"
             className="homebuyerImage"
             alt="CalHFA-homebuyer-image"
            />
          </Col>} */}
          
        </Row>
      </div>}
      {getcustomerid() === "zeitro" && <div className="mt-5 pb-3">
        <span className="zeitroDisclaimer">@2024 Powered by Zeitro. All rights reserved</span>
      </div>}
      {showContactmeModal && getContactmeModal()}
      {showContactDoneModal && getContactDoneModal()}
    </div>
  );
};

const calculateDimensions = (breakPoints, viewport) => {
  let w, h;
  if (breakPoints.xl) {
    w = 1000;
    h = 800;
  } else if (breakPoints.lg) {
    w = 800;
    h = 600;
  } else if (breakPoints.md) {
    w = 600;
    h = 400;
  } else if (breakPoints.sm || breakPoints.xs) {
    w = Math.round(viewport.width * 0.95);
    h = 300;
  }
  return { width: w, height: h };
};

function CalHFA(props) {
  window.drift.hide();
  const breakPoints = useBreakPoints();
  const viewport = useViewport();
  const { width, height } = calculateDimensions(breakPoints, viewport);
  const [loaded, setLoaded] = useState(false);

  const ref = useRef();

  useEffect(() => {
    SmartlookClient.init("6826641ee515b7a80a6dcd1172dcafd692a851bc");
    SmartlookClient.record({
      emails: true,
      forms: true,
      numbers: true,
      ips: true,
    })
  },[]);

  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: [width, height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });
  const sc = ` window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '6826641ee515b7a80a6dcd1172dcafd692a851bc', { region: 'eu' });
    smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
    `;
  
  let url = new URL(window.location.href)
  let segments = url.pathname.split("/")
  let urlCustomerid = segments[segments.length-1] // Handle potential trailing slash
  let urlLoid = url.searchParams.get("loid");
  let frameCustomerid = props.match.params["customerid"];
  let frameLoid = props.match.params["loid"];
  return (
    <Router>
      {!window.location.hostname.includes("localhost") && (
        <Helmet>
          <script type="text/javascript">{sc}</script>
        </Helmet>
      )}

      <div
        ref={ref}
        className="App py-0 my-0 mx-0 px-0 pb-0"
        style={{ textAlign: "left", borderTop: "none" }}
      >
        <div >
          <div className="topbanner">
            <div className="bannerContentWraper">
              <div className="text-left">
                <div className="hfaTitle">
                  California <span className="hightlightWord">Dream</span> For All 
                </div>
                <div className="hfaSubTitle">
                  Down Payment Assistance Calculator
                </div>
                <div className="hfaSubText">
                  The Dream For All Shared Appreciation Loan is a down payment assistance program that provides first-time homebuyers with up to 20% of the home purchase price. This program must be used in conjunction with the Dream For All Conventional first mortgage.
                </div>
              </div>
              <div  className="bannerIconWraper">
                <img
                  src="/images/CalHFA_bannerIcon.png"
                  className="hfaLogo"
                  alt="CalHFA"
                />
              </div>
              {/* <Col>
                <div
                  className={
                    "hfaSubTitle " + (width <= 480 ? "mt-3" : "vertical-center")
                  }
                  md={5}
                  xs={12}
                >
                  The Dream For All Shared Appreciation Loan is a down payment
                  assistance program for first-time homebuyers to be used in
                  conjunction with the Dream For All Conventional first mortgage
                  for down payment and/or closing costs. This down payment
                  assistance program offers up to 20% of the purchase price.
                </div>
              </Col> */}
            </div>
          </div>
          {/* <div className="calchfa-subtitle">This down payment assistance program offers up to 20% of the purchase price. Use this calculator to explore the math!</div> */}
          <CalHFAComponent {...props} frameLoid={frameLoid} urlLoid={urlLoid} />
          { (urlCustomerid === "gmcc" || frameCustomerid === "gmcc") && <div className="calhfa-GMCCFooter">
            <hr className="mb-3"/>
            <GMCCDisclaimer />
          </div>}
        </div>
      </div>
    </Router>
  );
}

export default withRouter(CalHFA);
