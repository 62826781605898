import { useState, useRef, useEffect } from 'react';
import { 
  Tabs,
  DatePicker,
  Button,
  Checkbox,
  Tooltip,
  Select,
  Form, 
  Input,
  InputNumber,
  Slider,
  Col, 
  Row 
} from 'antd';
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import * as com from "../../Common"
import {
  QuestionCircleFilled
} from '@ant-design/icons';
import "./LoanRateCalculator.css";

const mapStateToProps = (state) => {
  return {
      application: state.application,
      progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateHardMoneyLoanAttribute: (t, verb) => {
    dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
  },
  changeMainPropertyAttribute: (t, verb) => {
    dispatch(act.ChangeMainPropertyAttribute(t, verb));
  },
  changeMainPropertyPurpose: (val) => {
    dispatch(act.ChangeMainPropertyPurpose(val));
  },
  updateProduct: (v, verb) => {
    dispatch(act.UpdateProduct(v, verb))
},
});

export default function LoanRateCalculator(props) {
  const [active, setActive] = useState('1')
  const [rehabLoan, setRehabLoan] = useState(false)
  const [propertyCounty, setPropertyCounty] = useState('')
  const [form] = Form.useForm();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  useEffect(()=>{
    props.updateProduct("hardmoneyloan", "mortgageapplied");
    props.changeMainPropertyPurpose("purchase")
    props.updateHardMoneyLoanAttribute(780, "estimatedfico")
    props.updateHardMoneyLoanAttribute(12, "mortgagetermmonths")
    props.changeMainPropertyAttribute("1000000", "salesprice")
    props.updateHardMoneyLoanAttribute("700000", "initialloanamount")
  }, [])
  const tabsOnChange = (key) => {
    setActive(key);
    form.setFieldsValue({
      propertyType: 'singlefamilydetached',
    })
  };
  const handleloanTerm = (value) => {
    console.log(`selected ${value}`);
  };
  const rehabLoanOnChange = (e) => {
    setRehabLoan(e.target.checked);
  };
  const inputFormatter = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const inputParser = (value) => {
    return value.replace(/\\s?|(,*)/g, '')
  }
  const onFinish = (values) => {
    console.log("city", values.city)
    console.log("state", values.state)
    let r = {...props.application.hardmoneyloan.refinancedetails}
    props.changeMainPropertyAttribute(values.propertyType, "propertytype")
    // props.changeMainPropertyAttribute(values.purchasePrice, "salesprice")
    props.changeMainPropertyAttribute(values.address, "address")
    props.changeMainPropertyAttribute(values.state, "state")
    props.changeMainPropertyAttribute(values.city, "city")
    props.changeMainPropertyAttribute(propertyCounty, "county")
    props.changeMainPropertyAttribute(values.zipCode, "zipcode")
    props.updateHardMoneyLoanAttribute(values.closingDate, "targetclosingdate")
    
    props.updateHardMoneyLoanAttribute(com.safeParseInt(values.loanTerm), "mortgagetermmonths")
    props.updateHardMoneyLoanAttribute(rehabLoan, "rehabloan")
    props.updateHardMoneyLoanAttribute(rehabLoan?String(values.rehabLoanAmount): "", "rehabloanamount")
    props.updateHardMoneyLoanAttribute(active === "3"?String(values.projectedAfterRepair) : rehabLoan?String(values.projectedAfterRepair):"", "projectedafterrepairvalue")
    props.updateHardMoneyLoanAttribute(values.flipped, "timesofflippingaproperty")
    props.updateHardMoneyLoanAttribute(values.purchased, "timesofpurchasingarentalproperty")
    props.updateHardMoneyLoanAttribute(values.FICO, "estimatedfico")
    switch (active) {
      case "1":
        props.changeMainPropertyPurpose("purchaserehab")
        props.changeMainPropertyAttribute(values.purchasePrice, "salesprice")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "initialadvancerequested")
        if (rehabLoan) {
          props.updateHardMoneyLoanAttribute(String(com.safeParseInt(values.loanAmount) + com.safeParseInt(values.rehabLoanAmount)), "initialloanamount")
        } else {
          props.updateHardMoneyLoanAttribute(String(values.loanAmount), "initialloanamount")
        }
        break;
      case "2":
        props.changeMainPropertyPurpose("refinance")
        r["estimatedCurrentValue"] = String(values.purchasePrice)
        r["existingLoanAmount"] = String(values.existingLoanAmount)
        props.updateHardMoneyLoanAttribute(r, "refinancedetails")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "initialloanamount")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "refinanceloanamount")
        break;
      case "3":
        props.changeMainPropertyPurpose("construction")
        props.updateHardMoneyLoanAttribute("groundup", "loantype")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "initialloanamount")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "constructionloanamount")
        break;
      case "4":
        props.changeMainPropertyPurpose("cashoutrefinance")
        r["estimatedCurrentValue"] = String(values.purchasePrice)
        r["existingLoanAmount"] = String(values.existingLoanAmount)
        props.updateHardMoneyLoanAttribute(r, "refinancedetails")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "initialloanamount")
        props.updateHardMoneyLoanAttribute(String(values.loanAmount), "refinanceloanamount")
      default:
        break;
    }
    props.updateHardMoneyLoanAttribute(String(values.constructionBudget), "constructionbudget")
    props.setShowResult(true)
  }
  const formInitial = {
    propertyType: 'singlefamilydetached',
    constructionType: 'singlefamilydetached',
    purchasePrice: '1000000',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    closingDate: '',
    existingLoanAmount: '',
    loanAmount: '700000',
    loanTerm: '12',
    rehabLoanAmount: '',
    constructionBudget: '',
    projectedAfterRepair: '',
    flipped: 0,
    purchased: 0,
    FICO: 780
  }
  const items = [
    {
      key: '1',
      label: `Purchase`
    },
    {
      key: '2',
      label: `Rate term refi`
    },
    {
      key: '4',
      label: 'Cash out refi'
    },
    {
      key: '3',
      label: `Construction`
    },
  ];
  const PropertyTypeOptions = 
  [
    {
      value: 'singlefamilydetached',
      label: 'Single family residence'
    },
    {
      value: 'twotofourfamily',
      label: '2-4 unit family'
    },
    {
      value: 'mixeduse',
      label: 'Mixed Use(50%+ residential)'
    },
    {
      value: 'multistory',
      label: 'Multi family (5+ units)',
    },
  ]
  const constructionType = [
    {
      value: 'singlefamilydetached',
      label: 'Single family residence'
    }
  ]
  const loanTermOptions = [
    {
      value: '3',
      label: '3 Months'
    },
    {
      value: '6',
      label: '6 Months'
    },
    {
      value: '9',
      label: '9 Months'
    },
    {
      value: '12',
      label: '12 Months'
    },
    {
      value: '24',
      label: '24 Months'
    },
    {
      value: '36',
      label: '36 Months'
    },
    {
      value: '48',
      label: '48 Months'
    },
    {
      value: '60',
      label: '60 Months'
    }
  ]
  const googleOptions = {
    componentRestrictions: { country: "us"},
    fields: ["address_components", "formatted_address"]
 };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      googleOptions
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      const address = `${place.address_components.find(item =>item.types.includes("street_number")).short_name} ${place.address_components.find(item =>item.types.includes("route")).short_name}, ${place.address_components.find(item =>item.types.includes("country")).short_name}`
      const county = place.address_components.find(item => item.types.includes("administrative_area_level_2")).long_name
      setPropertyCounty(county)
      form.setFieldsValue({
        address: address,
        city: place.formatted_address.split(',')[1],
        state: place.address_components.find(item =>item.types.includes("administrative_area_level_1")).short_name,
        zipCode: place.address_components.find(item => item.types.includes("postal_code")).long_name
      });
      console.log("county", county)
    });
  }, []);
  return (
    <div className='loan-rate-calculator'>
      <Tabs className='calc-tabs' defaultActiveKey={active} type="card" items={items} onChange={tabsOnChange} />
      <div className='form-wrap'>
        <Form
          layout='vertical'
          form={form}
          initialValues={formInitial}
          onFinish={onFinish}
          size="large"
        >
          <Row gutter={80} style={{padding: '16px 50px 0 50px'}}>
            {
              (active === '1' || active === '2') && <Col span={24} md={12}>
                <Form.Item
                  name="propertyType"
                  label="Property Type"
                  className='tooltip-item'
                  // tooltip={{
                  //   title: 'Property Type',
                  //   icon: <QuestionCircleFilled className="tips-icon" />,
                  // }}
                >
                <Select
                  allowClear
                  options={PropertyTypeOptions}
                />
                </Form.Item>
              </Col>
            }
            {
              active === '4' && <Col span={24} md={12}>
                <Form.Item
                  name="propertyType"
                  label="Property Type"
                  className='tooltip-item'
                  // tooltip={{
                  //   title: 'Property Type',
                  //   icon: <QuestionCircleFilled className="tips-icon" />,
                  // }}
                >
                <Select
                  allowClear
                  options={constructionType}
                />
                </Form.Item>
              </Col>
            }
            {
              active !== '3' ? (
                <Col span={24} md={12}>
                  <Form.Item
                    name="purchasePrice"
                    label={active === '1' ? 'Purchase Price or As Is Value' : 'As Is Value'}
                  >
                    <InputNumber
                      prefix="$" 
                      style={{ width: '100%' }}
                      max={10000000}
                      min={0}
                      formatter={inputFormatter}
                      parser={inputParser}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24} md={24}>
                  <Form.Item
                    name="constructionType"
                    label="Construction Type"
                    className='tooltip-item'
                    // tooltip={{
                    //   title: 'Construction Type',
                    //   icon: <QuestionCircleFilled className="tips-icon" />,
                    // }}
                  >
                  <Select
                    allowClear
                    options={constructionType}
                  />
                  </Form.Item>
                </Col>
              )
            }
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="address" label="Property Address">
                <input className='addr-input' ref={inputRef} placeholder="Address" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="city">
                <Input readOnly placeholder="City" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="state">
                <Input readOnly placeholder="State" />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item name="zipCode">
                <Input readOnly placeholder="Zip Code" />
              </Form.Item>
            </Col>
          </Row>
          {
            active === '3' && (
              <Row gutter={80}>
                <Col span={24} md={12}>
                  <Form.Item name="constructionBudget" label="Construction Budget">
                    <InputNumber
                      prefix="$"
                      style={{ width: '100%' }}
                      max={10000000}
                      min={0}
                      formatter={inputFormatter}
                      parser={inputParser}
                    />
                  </Form.Item>
                </Col><Col span={24} md={12}>
                  <Form.Item name="projectedAfterRepair" label="Projected After-Repair Value">
                    <InputNumber
                      prefix="$"
                      style={{ width: '100%' }}
                      max={10000000}
                      min={0}
                      formatter={inputFormatter}
                      parser={inputParser}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )
          }
          <Row gutter={active === '3' ? 80 : 24}>
            {
              active === '1' && (
                <Col span={24} md={8}>
                  <Form.Item name="closingDate" label="Target Closing Date">
                    <DatePicker 
                        className='closing-date' 
                        placeholder="MM/DD/YYYY" 
                        format="MM/DD/YYYY" 
                    />
                  </Form.Item>
                </Col>
              )
            }
            {
              (active === '2' || active === '4') && (
                <Col span={24} md={8}>
                  <Form.Item name="existingLoanAmount" label="Existing Loan amount">
                    <InputNumber
                      prefix="$" 
                      style={{ width: '100%' }}
                      max={10000000}
                      min={0}
                      formatter={inputFormatter}
                      parser={inputParser}
                    />
                  </Form.Item>
                </Col>
              )
            }
            <Col span={24} md={active === '3' ? 12 : 8}>
              <Form.Item name="loanAmount" label={`${active === '1' ? 'Initial' : (active === '2' || active === '4') ? 'Refi' : 'Construction'} Loan amount`}>
                <InputNumber
                  prefix="$" 
                  style={{ width: '100%' }}
                  max={10000000}
                  min={0}
                  formatter={inputFormatter}
                  parser={inputParser}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={active === '3' ? 12 : 8}>
              <Form.Item name="loanTerm" label="Loan Term">
                <Select
                  allowClear
                  onChange={handleloanTerm}
                  options={loanTermOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          {
            active === '1' && (
              <Row gutter={80}>
                <Col span={24} md={12}>
                  <Form.Item style={{marginBottom: '0'}}>
                    <div className='check-item'>
                      <div><span style={{marginRight: '12px'}}>Add a rehab loan?</span><Checkbox checked={rehabLoan} onChange={rehabLoanOnChange} /></div>
                      {/* <Tooltip title="prompt text">
                        <QuestionCircleFilled className="tips-icon" />
                      </Tooltip> */}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}></Col>
                {
                  rehabLoan && (
                    <>
                      <Col span={24} md={12}>
                        <Form.Item name="rehabLoanAmount" label="Rehab Loan amount">
                          <InputNumber
                            prefix="$"
                            style={{ width: '100%' }}
                            max={10000000}
                            min={0}
                            formatter={inputFormatter}
                            parser={inputParser}
                          />
                        </Form.Item>
                      </Col><Col span={24} md={12}>
                        <Form.Item name="projectedAfterRepair" label="Projected After-Repair Value">
                          <InputNumber
                            prefix="$"
                            style={{ width: '100%' }}
                            max={10000000}
                            min={0}
                            formatter={inputFormatter}
                            parser={inputParser}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )
                }
              </Row>
            )
          }
          <Row gutter={80}>
            <Col span={24}>
              <div className='check-item'>
                <div><span style={{marginRight: '12px'}}>In the last 3 years, how many times have you...</span></div>
                {/* <Tooltip title="prompt text">
                  <QuestionCircleFilled className="tips-icon" />
                </Tooltip> */}
              </div>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="flipped" label="flipped a property?">
                <InputNumber 
                  style={{ width: '100%' }}
                  min={0}
                  precision={0}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="purchased" label="purchased a rental property?">
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  precision={0}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80} justify="center" style={{padding: '16px 50px 35px 50px'}}>
            <Col span={24} md={12} className='score-item'>
              <span className='label'>Your FICO credit score</span>
              <Form.Item name="FICO">
                <InputNumber
                  className='score-input'
                  style={{ width: '100%' }}
                  min={500}
                  max={1000}
                  precision={0}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12} className='score-item'>
              <span className='num'>500</span>
              <Form.Item name="FICO">
                <Slider
                  className='score-slider'
                  min={500}
                  max={1000}
                  trackStyle={{backgroundColor: '#0F62FE'}}
                  railStyle={{backgroundColor: '#C1C7CD'}}
                />
              </Form.Item>
              <span className='num'>1000</span>
            </Col>
            <Col span={24} md={16} >
              <Button className='calc-btn' htmlType="submit" block>
                Calculate
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

LoanRateCalculator = connect(mapStateToProps, mapDispatchToProps)(LoanRateCalculator);
