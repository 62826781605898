import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DatePicker,
    Button,
    Select,
    Modal,
    Form,
    Input,
    InputNumber,
    Col,
    Row,
    Checkbox,
    Tooltip,
    Skeleton,
} from 'antd';
import {
    PlusCircleOutlined,
    QuestionCircleOutlined,
    ExclamationCircleFilled
    
} from '@ant-design/icons';
import "./Common.css";
import "./Experience.css";
import * as com from "../../Common"
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import GoogleAutocomplete from '../../Common/GoogleAutocomplete/GoogleAutocomplete'
import { PropertyTypeOptions, YesNoOptions, validateMessages } from './constants'
import SmallModal from '../../Common/components/SmallModal'
import StatusBanner from '../../Common/components/Alerts/StatusBanner'
const _ = require('lodash');


const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
});


export default function Experience(props) {
    const interviewfinished = com.CurrentStatus[props.application.currentStatus] >= 200
    let disabledOnclick = interviewfinished ? props.onSubmittedDisableClick : null
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectId, setSelectId] = useState('');
    const [loanLimits, setLoanLimits] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [btnDisable, setBtnDisable] = useState(false)
    const [savedPropertyExperiences, setSavedPropertyExperiences] = useState([])
    const [loadingPropertyExperiences, setLoadingPropertyExperiences] = useState(false)
    const [showSaveToProfileModal, setShowSaveToProfileModal] = useState(false)
    const [saveToProfile, setSaveToProfile] = useState(false)
    const [reload, setReload] = useState(false)
    const [propertiesModified, setPropertiesModified] = useState(false)
    const loanList = [
        {
            id: '1',
            name: 'Construction',
            No: 'CON3500365',
            address: '350 Warren St, Jersey City, NJ 07302',
            editTime: '02/11/2023',
            loanMount: '$500,000'
        },
        {
            id: '2',
            name: 'Construction',
            No: 'CON3500365',
            address: '350 Warren St, Jersey City, NJ 07302',
            editTime: '02/11/2023',
            loanMount: '$500,000'
        },
        {
            id: '3',
            name: 'Construction',
            No: 'CON3500365',
            address: '350 Warren St, Jersey City, NJ 07302',
            editTime: '02/11/2023',
            loanMount: '$500,000'
        }
    ]
    const showModal = () => {
        setIsModalOpen(true);
    }

    const onFinishFailed = (errorInfo) => {
        let failedCardIdx = {};
        errorInfo.errorFields.forEach((item) => {
            failedCardIdx[item.name[0]] = [...(failedCardIdx[item.name[0]] || []), item.name[1]]
        })
        Object.keys(failedCardIdx).forEach((key) => {
            let list = form.getFieldValue(key)
            failedCardIdx[key].forEach((idx) => {
                list[idx].open = true
            })
            form.setFieldsValue({
                key: list,
            })
        })

    }

    const onFinish = (values) => {
        if (typeof values.rentalpropertyexperiences === "undefined" || values.rentalpropertyexperiences.length === 0) {
            props.updateHardMoneyLoanAttribute([], "rentalpropertyexperiences")
        }
        if (values.propertyflippingexperiences && values.propertyflippingexperiences.length > 0) {
            let flipVals = values.propertyflippingexperiences.map(e => {
                let c = Object.fromEntries(
                    Object.entries(e).filter(([key]) => key !== 'open')
                )
                c.purchaseprice = String(c.purchaseprice)
                c.salesprice = String(c.salesprice)
                c.profitorloss = String(c.profitorloss)
                let ds = new Date(c.datesold)
                c.datesold = c.datesold ? dayjs(ds).format('MM/DD/YYYY') : ''
                let pd = new Date(c.purchasedate)
                c.purchasedate = c.purchasedate ? dayjs(pd).format('MM/DD/YYYY') : ''
                c.rehabbudget = c.rehabbudget ? String(c.rehabbudget) : ''
                return c
            })
            props.updateHardMoneyLoanAttribute(flipVals, "propertyflippingexperiences")
        }
        if (typeof values.propertyflippingexperiences === "undefined" || values.propertyflippingexperiences.length === 0) {
            props.updateHardMoneyLoanAttribute([], "propertyflippingexperiences")
        }
        props.updateHardMoneyLoanAttribute(values.propertyflippingexperiences.length, "timesofflippingaproperty")
        if (values.rentalpropertyexperiences && values.rentalpropertyexperiences.length > 0) {
            let rentalVals = values.rentalpropertyexperiences.map(e => {
                let c = Object.fromEntries(
                    Object.entries(e).filter(([key]) => key !== 'open')
                )
                c.purchaseprice = String(c.purchaseprice)
                c.monthlyrent = String(c.monthlyrent)
                c.profitorloss = String(c.profitorloss)
                let ds = new Date(c.datesold)
                c.datesold = c.datesold ? dayjs(ds).format('MM/DD/YYYY') : ''
                let pd = new Date(c.purchasedate)
                c.purchasedate = c.purchasedate ? dayjs(pd).format('MM/DD/YYYY') : ''
                return c
            })
            props.updateHardMoneyLoanAttribute(rentalVals, "rentalpropertyexperiences")
        }
        props.updateHardMoneyLoanAttribute(values.rentalpropertyexperiences.length, "timesofpurchasingarentalproperty")
        
        // Save property experiences if checkbox is checked
        if (saveToProfile) {
            const propertyFlippingExperiences = _.cloneDeep(values.propertyflippingexperiences) || []
            const rentalPropertyExperiences = _.cloneDeep(values.rentalpropertyexperiences) || []

            const convertDateToISO = (date) => {
                if (date) {
                    return date ? dayjs(date).format('MM/DD/YYYY') : ""
                }
                return ""
            }

            const formatExperience = (experience) => {
                // Convert dates to ISO format for PostgreSQL timestamp
                experience.purchasedate = convertDateToISO(experience.purchasedate)
                experience.datesold = convertDateToISO(experience.datesold)
                experience.purchaseprice = String(experience.purchaseprice || '') 
                experience.salesprice = String(experience.salesprice || '')
                experience.profitorloss = String(experience.profitorloss || '')
                
                // Remove updatedtime key if it exists
                delete experience.updatedtime
            }

            propertyFlippingExperiences.forEach(experience => {
                experience.type = 'propertyFlipping'
                formatExperience(experience)
            })

            rentalPropertyExperiences.forEach(experience => {
                experience.type = 'rental'
                formatExperience(experience)
            })

            const experiences = propertyFlippingExperiences.concat(rentalPropertyExperiences)
            const requestBody = {
                Experiences: experiences
            }

            const token = sessionStorage.getItem('ZeitroA')
            fetch("/borrower/hardmoneyloan/savepropertyexperience", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify(requestBody)
            }).then(res => {
                if (res.status === 200) {
                    props.finish()
                    return
                }
            }).catch(err => {
                console.error("Error saving property experiences:", err);
            });
        }

        props.finish()
    };
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };
    const clickCollapse = (index, key) => {
        const list = form.getFieldValue(key)
        list[index].open = !list[index].open
        form.setFieldsValue({
            key: list,
        })
    }
    const done = (index, key) => {
        const fieldPaths =[
            [key, index, 'streetline1'],
            [key, index, 'state'],
            [key, index, 'county'],
            [key, index, 'zip'],
            [key, index, 'ownershipontitle'],
            [key, index, 'propertytype'],
            [key, index, 'purchaseprice'],
            [key, index, 'purchasedate'],
            [key, index, 'salesprice'],
            [key, index, 'datesold'],
            [key, index, 'rehabbudget'], // Optional field, include if you want to validate it as well
            [key, index, 'profitorloss'],
        ]
        if (key === 'rentalpropertyexperiences') {
            fieldPaths.push([key, index, 'tenantoccupied'])
            fieldPaths.push([key, index, 'monthlyrent']) 
        }
        form.validateFields(fieldPaths).then(() => {
            const list = form.getFieldValue(key)
            list[index].open = false
            form.setFieldsValue({
                key: list,
            })
        })
    }
    const addSelect = () => {
        setIsModalOpen(false);
        const list = form.getFieldValue()
        props.finish({ ...list, selectLoanId: selectId })
    }
    const skip = () => {
        setIsModalOpen(false);
        const list = form.getFieldValue()
        props.finish(list)
    }
    const onChangeSelect = (id) => {
        setSelectId(id)
    }
    const onFieldsChange = () => {
        continueDisabled()
        const hasError = form.getFieldsError().some(x => x.errors.length > 0)
        if (hasError) props.resetProgress()

        // Check if current experiences are different from saved ones
        if (savedPropertyExperiences.length > 0) {
            const currentExperiences = getCurrentExperience()
            const areSame = compareExperiences(currentExperiences, savedPropertyExperiences)
            setPropertiesModified(!areSame)
        }
    }
    const continueDisabled = () => {
        const property = form.getFieldValue('propertyflippingexperiences')
        const rental = form.getFieldValue('rentalpropertyexperiences')

        // not sure about this part, do we need at least 1 experience?
        // just valifate the form for now
        if (property === undefined || property.length === 0 || rental === undefined || rental.length === 0) {
            setBtnDisable(true)
        } else {
            setBtnDisable(false)
        }
    }
    const getAddress = (val, index, key) => {
        const list = form.getFieldValue(key)

        let num = com.getSubaddress(val.address_components, "street_number", "long_name");
        let street = com.getSubaddress(val.address_components, "route", "long_name")
        let city = com.getSubaddress(val.address_components, "locality", "long_name")
        let zipCode = com.getSubaddress(val.address_components, "postal_code", "long_name")
        let state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        let county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        let streetaddr = street;
        if (num) {
            streetaddr = num + " " + streetaddr;
        }

        list[index].streetline1 = streetaddr
        list[index].city = city
        list[index].zip = zipCode
        list[index].state = state
        list[index].county = county
        form.setFieldsValue({
            key: list,
        })
    }
    const fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res.json();
            })
            .then((res) => {
                setLoanLimits(res)
                setStateOptions(Object.keys(res).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })))
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const onChangeState = (val, index, key) => {
        const list = form.getFieldValue(key)
        list[index].county = undefined
        list[index].countyList = val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })) : []
        form.setFieldsValue({
            key: list,
        })
    }
    const onChangeZip = (e, index, key) => {
        const list = form.getFieldValue(key)
        list[index].zip = e.target.value.replace(/\-/g, "")
        form.setFieldsValue({
            key: list,
        })
    }

    const fetchSavedProperties = () => {

        setLoadingPropertyExperiences(true)
        const token = sessionStorage.getItem('ZeitroA')
        fetch("/borrower/hardmoneyloan/getpropertyexperience", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        })
            .then((res) => {
                if (res.status == 200) {  
                    res.json().then(data => {
                        if (data.Status === "OK") {
                            setSavedPropertyExperiences(data.Experiences)
                        }
                    })
                } else {
                    console.error("Error fetching property experiences:", res)
                }
            })
            .finally(() => {
                setLoadingPropertyExperiences(false)
            })
        
    }


    const formatExperiences = (experiences, format = "MM/DD/YYYY") => {
        for (let i = 0; i < experiences.length; i++) {
            // Convert dates to dayjs objects only if they are valid dates
            experiences[i].datesold = experiences[i].datesold ? 
                dayjs(experiences[i].datesold, format).isValid() ? 
                    dayjs(experiences[i].datesold, format) : 
                    null : 
                null;
            
            experiences[i].purchasedate = experiences[i].purchasedate ? 
                dayjs(experiences[i].purchasedate, format).isValid() ? 
                    dayjs(experiences[i].purchasedate, format) : 
                    null : 
                null;
            
            experiences[i].open = false;
        }
        return experiences;
    }

    useEffect(() => {
        fetchSavedProperties()
        continueDisabled()
        fetchLoanLimits()
    }, []);


    const getInitialValue = (useSaved = false) => {
        let val = { rentalpropertyexperiences: undefined, propertyflippingexperiences: undefined }

        if (useSaved) {
            let rental = savedPropertyExperiences.filter(experience => experience.type === 'rental')
            let flip = savedPropertyExperiences.filter(experience => experience.type === 'propertyFlipping')

            rental = formatExperiences(rental, "YYYY-MM-DD")
            flip = formatExperiences(flip, "YYYY-MM-DD")

            val.rentalpropertyexperiences = rental
            val.propertyflippingexperiences = flip

            return val
        }


        let rental = _.cloneDeep(props.application.hardmoneyloan.rentalpropertyexperiences)
        if (rental.length > 0) {
            rental = formatExperiences(rental)
        }
        val.rentalpropertyexperiences = rental

        let flip = _.cloneDeep(props.application.hardmoneyloan.propertyflippingexperiences)
        if (flip.length > 0) {
            flip = formatExperiences(flip)
        }
        val.propertyflippingexperiences = flip

        return val
    }

    const renderPropertyFlippingExperiences = () => <Form.List name="propertyflippingexperiences">
        {(fields, { add, remove }) => (
            <>
                <div className='form-title'>Property Flipping Experience</div>
                {fields.map((field, index) => (
                    <div key={field.key} className={`experience-collapse ${form.getFieldValue('propertyflippingexperiences')[index]?.open ? 'collapse-open' : 'collapse-close'}`}>
                        <div className='experience-collapse-title'>
                            <div onClick={() => clickCollapse(index, 'propertyflippingexperiences')} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <span>Track Record {index + 1}: {form.getFieldValue('propertyflippingexperiences')[index].streetline1}</span>{!form.getFieldValue('propertyflippingexperiences')[index]?.open && <img src="/images/arrow-down.svg" className="ml-2" style={{width: 13, height: 13}}/>}
                            </div>
                            {form.getFieldValue('propertyflippingexperiences')[index]?.open ? <img src='/images/arrow-up.svg' onClick={() => clickCollapse(index, 'propertyflippingexperiences')} style={{cursor:"pointer"}} /> : <img src="/images/blueMinusInCircleMark.png" alt="delete" style={{width: 20, height: 20, cursor: 'pointer'}} onClick={() => remove(field.name)}/>}
                        </div>
                        <Row gutter={30}>
                            <Col span={24}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'streetline1']}
                                    label="Property address"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <GoogleAutocomplete
                                        placeholder="Street address"
                                        getPlace={(val) => getAddress(val, index, 'propertyflippingexperiences')}
                                        disabled={interviewfinished}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'state']}
                                    rules={[
                                        {
                                            required: true,
                                            message: ''
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={stateOptions}
                                        onChange={(val) => onChangeState(val, index, 'propertyflippingexperiences')}
                                        placeholder="Select your state"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'county']}
                                    rules={[
                                        {
                                            required: true,
                                            message: ''
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={form.getFieldValue('propertyflippingexperiences')[index].countyList}
                                        placeholder="Select your county"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'zip']}
                                    rules={[
                                        {
                                            required: true,
                                            message: ''
                                        },
                                    ]}
                                >
                                    <Input
                                        type='text'
                                        style={{ width: '100%' }}
                                        placeholder="Zip Code"
                                        onChange={(e) => onChangeZip(e, index, 'propertyflippingexperiences')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Owner on title"
                                    name={[field.name, 'ownershipontitle']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Property type"
                                    name={[field.name, 'propertytype']}
                                    tooltip={{
                                        title: 'Property Type',
                                        icon: <QuestionCircleOutlined className="tips-icon" />,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={PropertyTypeOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Purchase price"
                                    name={[field.name, 'purchaseprice']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        disabled={interviewfinished}
                                        prefix="$"
                                        style={{ width: '100%' }}
                                        min={0}
                                        precision={0}
                                        formatter={inputFormatter}
                                        parser={inputParser}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Purchase date"
                                    name={[field.name, 'purchasedate']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Sales price"
                                    name={[field.name, 'salesprice']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix="$"
                                        style={{ width: '100%' }}
                                        disabled={interviewfinished}
                                        min={0}
                                        formatter={inputFormatter}
                                        parser={inputParser}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Date sold"
                                    name={[field.name, 'datesold']}
                                    rules={[
                                        {
                                            required: true
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Rehab cost"
                                    name={[field.name, 'rehabbudget']}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix="$"
                                        style={{ width: '100%' }}
                                        disabled={interviewfinished}
                                        min={0}
                                        precision={0}
                                        formatter={inputFormatter}
                                        parser={inputParser}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Profit / Loss"
                                    name={[field.name, 'profitorloss']}
                                    tooltip={{
                                        title: 'Enter a positive number for profit (e.g., 5000) and a negative number for loss (e.g., -5000).',
                                        icon: <QuestionCircleOutlined className="tips-icon" />,
                                    }}
                                    rules={[
                                        {
                                            required: true
                                        },
                                    ]}
                                >
                                <InputNumber
                                    prefix="$"
                                    style={{ width: '100%' }}
                                    disabled={interviewfinished}
                                    formatter={inputFormatter}
                                    parser={inputParser}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item onClick={disabledOnclick}>
                            <Button className='delete item-btn' onClick={() => remove(field.name)}>Delete</Button>
                            <Button className='done item-btn' type="primary" onClick={() => done(index, 'propertyflippingexperiences')}>Done</Button>
                        </Form.Item>
                    </div>
                ))}

                <Form.Item onClick={disabledOnclick}>
                    <Button
                        className='experience-add-btn'
                        size="large"
                        onClick={() => {
                            add({ open: true, countyList: [] })
                        }}>
                        <PlusCircleOutlined className='add-icon' />
                        Add a property flipping experience
                    </Button>
                </Form.Item>
            </>
        )}
    </Form.List>

    const renderRentalPropertyExperiences = () => <Form.List name="rentalpropertyexperiences">
        {(fields, { add, remove }) => (
            <>
                <div className='form-title'>Rental Property Experience</div>
                {fields.map((field, index) => (
                    <div key={field.key} className={`experience-collapse ${form.getFieldValue('rentalpropertyexperiences')[index]?.open ? 'collapse-open' : 'collapse-close'}`}>
                        <div className='experience-collapse-title'>
                            <div onClick={() => clickCollapse(index, 'rentalpropertyexperiences')} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <span>Track Record {index + 1}: {form.getFieldValue('rentalpropertyexperiences')[index].streetline1}</span>{!form.getFieldValue('rentalpropertyexperiences')[index]?.open && <img src="/images/arrow-down.svg" className="ml-2" style={{width: 13, height: 13}}/>}
                            </div>
                            {form.getFieldValue('rentalpropertyexperiences')[index]?.open ? <img src='/images/arrow-up.svg' onClick={() => clickCollapse(index, 'rentalpropertyexperiences')} style={{cursor:"pointer"}} /> : <img src="/images/blueMinusInCircleMark.png" alt="delete" style={{width: 20, height: 20, cursor: 'pointer'}} onClick={() => remove(field.name)}/>}
                        </div>
                        <Row gutter={30}>
                            <Col span={24}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'streetline1']}
                                    label="Property address"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <GoogleAutocomplete
                                        placeholder="Street address"
                                        disabled={interviewfinished}
                                        getPlace={(val) => getAddress(val, index, 'rentalpropertyexperiences')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'state']}
                                    rules={[
                                        {
                                            required: true,
                                            message: ''
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={stateOptions}
                                        onChange={(val) => onChangeState(val, index, 'rentalpropertyexperiences')}
                                        placeholder="Select your state"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'county']}
                                    rules={[
                                        {
                                            required: true,
                                            message: ''
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={form.getFieldValue('rentalpropertyexperiences')[index].countyList}
                                        placeholder="Select your county"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    name={[field.name, 'zip']}
                                    rules={[
                                        {
                                            required: true,
                                            message: ''
                                        },
                                    ]}
                                >
                                    <Input
                                        type='text'
                                        style={{ width: '100%' }}
                                        placeholder="Zip Code"
                                        onChange={(e) => onChangeZip(e, index, 'rentalpropertyexperiences')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Owner on title"
                                    name={[field.name, 'ownershipontitle']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Property type"
                                    name={[field.name, 'propertytype']}
                                    tooltip={{
                                        title: 'Property Type',
                                        icon: <QuestionCircleOutlined className="tips-icon" />,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={PropertyTypeOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Purchase price"
                                    name={[field.name, 'purchaseprice']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix="$"
                                        style={{ width: '100%' }}
                                        disabled={interviewfinished}
                                        min={0}
                                        precision={0}
                                        formatter={inputFormatter}
                                        parser={inputParser}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Purchase date"
                                    name={[field.name, 'purchasedate']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Tenant occupied (Yes/No)"
                                    name={[field.name, 'tenantoccupied']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        options={YesNoOptions}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Monthly rent"
                                    name={[field.name, 'monthlyrent']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix="$"
                                        style={{ width: '100%' }}
                                        disabled={interviewfinished}
                                        min={0}
                                        formatter={inputFormatter}
                                        parser={inputParser}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Date sold"
                                    name={[field.name, 'datesold']}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    onClick={disabledOnclick}
                                    label="Profit / Loss"
                                    name={[field.name, 'profitorloss']}
                                    tooltip={{
                                        title: 'Enter a positive number for profit (e.g., 5000) and a negative number for loss (e.g., -5000).',
                                        icon: <QuestionCircleOutlined className="tips-icon" />,
                                    }}
                                    rules={[
                                        {
                                            required: true
                                        },
                                    ]}
                                >
                                <InputNumber
                                    prefix="$"
                                    style={{ width: '100%' }}
                                    formatter={inputFormatter}
                                    disabled={interviewfinished}
                                    parser={inputParser}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item onClick={disabledOnclick}>
                            <Button className='delete item-btn' onClick={() => remove(field.name)}>Delete</Button>
                            <Button className='done item-btn' type="primary" onClick={() => done(index, 'rentalpropertyexperiences')}>Done</Button>
                        </Form.Item>
                    </div>
                ))}

                <Form.Item onClick={disabledOnclick}>
                    <Button
                        className='experience-add-btn'
                        size="large"
                        onClick={() => {
                            add({ open: true })
                        }}>
                        <PlusCircleOutlined className='add-icon' />
                        Add a rental property experience
                    </Button>
                </Form.Item>
            </>
        )}
    </Form.List>


    const renderActionButtons = () => <Row gutter={10}>
        <Col>
            <Form.Item>
                <Button
                    type="default"
                    className='secondary-btn'
                    disabled={false}
                    onClick={props.back}>
                    Back
                </Button>
            </Form.Item>
        </Col>
        <Col>
            <Form.Item>
                <Button
                    type="primary"
                    disabled={false}
                    className='common-btn'
                    htmlType="submit"
                    onClick={() => {
                        // Scroll to top after click continue
                        window.document.body.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                    }}
                >
                    Continue
                </Button>
            </Form.Item>
        </Col>
        <Col>
            <Form.Item>
                <Checkbox onChange={(e) => {
                    setSaveToProfile(e.target.checked)
                    if (e.target.checked) {
                        if (propertiesModified) {
                            setShowSaveToProfileModal(true);
                        }
                    }
                }} checked={saveToProfile}>
                    Save to profile  <Tooltip title="Save these experiences to your profile for quick access in future applications"><QuestionCircleOutlined className="tips-icon" /></Tooltip>
                </Checkbox>
            </Form.Item>
        </Col>
        {!saveToProfile && propertiesModified && <div className='d-flex' >
        <Form.Item style={{color:"#cb8600"}}>
            <ExclamationCircleFilled className='mr-2' /> Check "Save to profile" to update changed experience
            </Form.Item>
            </div>}
    </Row>

    const renderExperienceModal = () => <Modal
        className='experience-modal'
        open={false} // disable this function temporarily
        title="Save your time"
        footer={[
            <Button key="submit" className='add-select' type="primary" size="large" onClick={addSelect}>
                Add selected
            </Button>,
            <Button type="link" className='skip-step' onClick={skip}>Skip this step</Button>
        ]}
        width={767}
    >
        <div className='experience-modal-content'>
            <div className='content-title'>
                We noticed that you had 1 new loan since your last application, would you like to add it as your property investment experience?
            </div>
            <div className='content-sub-title'>
                You may always edit your information anytime during the application process.
            </div>
            <div className='experience-modal-card-list'>
                {
                    loanList.map(loan =>
                        <div className={`experience-modal-card-item ${loan.id === selectId ? 'select' : ''}`} key={loan.id} onClick={() => onChangeSelect(loan.id)}>
                            <div className='loan-check'></div>
                            <div className='loan-content'>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#333', marginBottom: '4px', lineHeight: 'normal' }}>{loan.name} | {loan.No}</div>
                                <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#1F3988', marginBottom: '14px' }}>Repaid</div>
                                <div style={{ fontSize: '14px', lineHeight: '24px', fontWeight: 'bold', color: '#333', marginBottom: '6px' }}>{loan.address}</div>
                                <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#6E6E70', paddingBottom: '12px', borderBottom: '1px solid #ECECEC' }}>Last edit at {loan.editTime}</div>
                                <div className='loan-amount'><div>Loan amount:</div><div style={{ fontWeight: 'bold' }}>{loan.loanMount}</div></div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    </Modal>

    const getCurrentExperience = () => {
        const propertyFlippingExperiences = form.getFieldValue('propertyflippingexperiences') || []
        const rentalPropertyExperiences = form.getFieldValue('rentalpropertyexperiences') || []
        return [...propertyFlippingExperiences, ...rentalPropertyExperiences]
    }

    const standardizeDate = (date) => {
        if (!date) return '';
        // Handle both dayjs objects and string dates
        if (dayjs.isDayjs(date)) {
            return date.format('YYYY-MM-DD');
        }
        const parsed = dayjs(date);
        return parsed.isValid() ? parsed.format('YYYY-MM-DD') : '';
    };

    const compareExperiences = (currentExperiences, savedExperiences) => {
        // Helper function to standardize experience object for comparison
        const standardizeExperience = (exp) => {
            return {
                streetline1: exp.streetline1 || '',
                state: exp.state || '',
                county: exp.county || '',
                zip: exp.zip || '',
                ownershipontitle: exp.ownershipontitle || '',
                propertytype: exp.propertytype || '',
                purchaseprice: String(exp.purchaseprice || ''),
                purchasedate: standardizeDate(exp.purchasedate),
                datesold: standardizeDate(exp.datesold),
                profitorloss: String(exp.profitorloss || ''),
                // Include rental-specific fields if they exist
                ...(exp.tenantoccupied && { tenantoccupied: exp.tenantoccupied }),
                ...(exp.monthlyrent && { monthlyrent: String(exp.monthlyrent) }),
                ...(exp.rehabbudget && { rehabbudget: String(exp.rehabbudget) }),
            };
        };

        const currentToCompare = currentExperiences.map(standardizeExperience);
        const savedToCompare = savedExperiences.map(standardizeExperience);

        // Compare lengths first
        if (currentToCompare.length !== savedToCompare.length) {
            return false;
        }
        // Compare each experience
        return currentToCompare.every(currentExp => 
            savedToCompare.some(savedExp => 
                JSON.stringify(currentExp) === JSON.stringify(savedExp)
            )
        );

    };

    const renderSaveToProfileModal = () => {
        const currentExperiences = getCurrentExperience()
        return <SmallModal
        open={showSaveToProfileModal}
        onClose={() => setShowSaveToProfileModal(false)}
        title="Confirm"
        content={<div>
            <div>
            You currently have {savedPropertyExperiences.length} saved properties in your profile. Saving now will replace them with your latest {currentExperiences.length} entries.
            </div>
            <div className='mt-3'>
            Would you like to proceed with the update?
            </div>
        </div>}
        footer={[
            <Button type="link" className='skip-step' onClick={() => {
                setShowSaveToProfileModal(false)
                setSaveToProfile(false)
            }}>Cancel</Button>,
            <Button key="submit" className='add-select' type="primary" size="medium" onClick={() => {
                setShowSaveToProfileModal(false)
                setSaveToProfile(true)
            }}>
                Yes, update
            </Button>,
        ]}
    />
    }

    const loadSavedProperty = () => {
        if (savedPropertyExperiences) {
            const initialValues = getInitialValue(true)
            form.setFieldsValue(initialValues)
            setReload(true)
        }
    }

    const renderStatusBanner = () => {
        if (savedPropertyExperiences && savedPropertyExperiences.length > 0) {
            return <StatusBanner
                onImport={loadSavedProperty}
                buttonText={reload ? <div><img src="/images/icon-refresh.svg" className='mr-2'/>Reload all saved experiences</div> : "Import saved experiences"}
                messageText={"You have " + savedPropertyExperiences.length + " saved experiences available"}
            />
        }
        return ""
    }

    return (
        <div className="application-form text-left">
            {renderStatusBanner()}
            {
                loadingPropertyExperiences ? <Skeleton /> :
                <Form
                    form={form}
                    disabled={interviewfinished}
                    initialValues={getInitialValue()}
                    name="dynamic_form_complex"
                    scrollToFirstError={{
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center',
                        block: 'center',
                    }}
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onFieldsChange={onFieldsChange}
                    validateMessages={validateMessages}
                    size="large"
                >
                    <div className='form-body' onClick={interviewfinished ? props.onSubmittedDisableClick: null}>
                        {renderPropertyFlippingExperiences()}
                        {renderRentalPropertyExperiences()}
                    </div>
                    {renderActionButtons()}
                </Form>
            }
            {renderExperienceModal()}
            {renderSaveToProfileModal()}
        </div>
    )
}

Experience = connect(mapStateToProps, mapDispatchToProps)(Experience);
