import React, { Component } from "react"
import { Card, Space, Typography } from 'antd'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { connect } from 'react-redux'
import './Closing.css'
import { assembleFullName } from '../Tools/String'

const { Text, Link } = Typography

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}

class Closing extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.state = {
            officer: null,
        }
    }

    componentDidMount() {
        this.getLoanOfficerInfo()
    }

    getLoanOfficerInfo = async () => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            const response = await fetch('/borrower/getloanofficerinfo', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })

            if (response.status !== 200) {
                console.warn('Get loan officer info failed: ', response.status)
                return
            }

            const data = await response.json()
            this.setState({ officer: data.Profile })
        } catch (error) {
            console.error('Error fetching loan officer info:', error)
        }
    }

    render() {
        const { officer } = this.state
        const { FirstName, MiddleName, LastName, Phone, Email, CompanyName } = officer || {}
        const fullName = assembleFullName(FirstName, MiddleName, LastName)

        return (
            <div className="text-left mb-3">
                <Row className="page-header-row">
                    <Col>
                        <div className="page-header">
                            <span className="page-header-primary">Closing</span>
                        </div>
                    </Col>
                </Row>

                {/* Congratulations Section */}
                <Row className="mt-4">
                    <Col>
                        <h1 className="closing-header">🎉 Congratulations!</h1>
                        <h1 className="closing-header">Your Loan is complete on our end.</h1>
                    </Col>
                </Row>

                {/* Main Message */}
                <Row className="mt-4">
                    <Col>
                        <p className="closing-message">
                            All your loan conditions and documents have been completed. Your loan officer will contact you soon with the next steps and inform you when to expect your final closing documents at escrow.
                        </p>
                    </Col>
                </Row>

                {/* Contact Section */}
                <Row className="mt-5">
                    <Col>
                        <h3 className="closing-contact-header">Contact</h3>
                        <p className="mt-3 closing-message">
                            If you have any questions in the meantime, please don't hesitate to contact your loan officer, or escrow agent directly.
                        </p>
                    </Col>
                </Row>

                {/* Contact Cards */}
                {officer && (
                    <div className="officer-cards-container">
                        <Card className="officer-card" style={{ body: { padding: '8px 22px' } }} bordered={false}>
                            <Space direction="vertical" size="small" style={{ width: '100%', textAlign: 'left' }}>
                                <Text style={{ fontWeight: '600', fontSize: '14px', color: '#081265' }}>
                                    LOAN OFFICER
                                </Text>

                                <Space direction="vertical" size={0} style={{ marginTop: '4px', marginBottom: '10px' }}>
                                    <Text style={{ fontSize: '20px', fontWeight: '600', display: 'block', color: '#222222' }}>
                                        {fullName}
                                    </Text>
                                    <Text style={{ fontSize: '16px', fontWeight: '400', color: '#6E6E70' }}>{CompanyName}</Text>
                                </Space>

                                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                    <Link
                                        href={`tel:${Phone}`}
                                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                                    >
                                        <PhoneOutlined rotate={90} style={{ fontSize: '18px', color: '#545F71' }} />
                                        <Text style={{ fontSize: '16px', fontWeight: '400', color: '#222222' }}>{Phone}</Text>
                                    </Link>

                                    <Link
                                        href={`mailto:${Email}`}
                                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                                    >
                                        <MailOutlined style={{ fontSize: '18px', color: '#545F71' }} />
                                        <Text style={{ fontSize: '16px', fontWeight: '400', color: '#325CEB' }}>{Email}</Text>
                                    </Link>
                                </Space>
                            </Space>
                        </Card>
                    </div>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps)(Closing)
