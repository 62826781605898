import React, { useEffect, useState, useRef } from 'react';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';

const StatsigWrapper = React.memo(({ children }) => {
    const initRef = useRef(false);
    const [client, setClient] = useState(null);

    const getEnvironment = () => {
        if (window.location.hostname === "localhost") return "development";
        if (window.location.hostname === "lo.zeitro.us") return "staging";
        return "production";
    };

    const { client: statsigClient } = useClientAsyncInit(
        "client-uqUh75VjWL0jwYGwv7R6qu09epEniRaL9EhRUEbwMTU",
        {
            userAgent: navigator.userAgent,
            language: navigator.language,
            custom: {
                origin: window.location.origin || "",
            }
        },
        {
            environment: {
                tier: getEnvironment()
            }
        }
    );

    useEffect(() => {
        if (!statsigClient || initRef.current) return;

        const initializeStatsig = async () => {
            if (statsigClient.loadingStatus === 'Ready' && !initRef.current) {
                try {
                    console.log("Statsig initializing...");
                    initRef.current = true;

                    const context = statsigClient.getContext();
                    const derivedFields = context?.values?.derived_fields;
                    const savedUserInfo = sessionStorage.getItem('statsig_user_info');

                    if (savedUserInfo) {
                        const userInfo = JSON.parse(savedUserInfo);
                        await statsigClient.updateUserAsync({
                            ...userInfo,
                            customIDs: {
                                ...(userInfo?.customIDs || {}),
                                IP: derivedFields?.ip || '',
                            }
                        });
                    } else {
                        await statsigClient.updateUserAsync({
                            customIDs: {
                                IP: derivedFields?.ip || '',
                            }
                        });
                    }

                    runStatsigAutoCapture(statsigClient);
                    runStatsigSessionReplay(statsigClient);

                    setClient(statsigClient);
                    console.log("Statsig initialized successfully");
                } catch (error) {
                    console.error('Error initializing Statsig:', error);
                    initRef.current = false;
                }
            }
        };

        initializeStatsig();
    }, [statsigClient?.loadingStatus]);



    return client ? (
        <StatsigProvider client={client}>
            {children}
        </StatsigProvider>
    ) : children;
});

StatsigWrapper.displayName = 'StatsigWrapper';

export default StatsigWrapper;