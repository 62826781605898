import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { sortBy } from "lodash";
import moment from "moment";
import { Space, message, Row, Button, Typography, Col, Skeleton } from "antd";

import * as com from "../../Common.js";

import PDFViewer from "./PDFViewer";
import ClickablePrequalLetterDisplay from "./ClickablePrequalLetterDisplay";
import { PrequalLetterHeader } from "./PrequalLetterHeader";
import { NoPrequalLetters } from "./NoPrequalLetters";
import { SharePreQualLetterModal } from "./SharePreQualLetterModal";
import "./PrequalLetterHistory.css";

const { Text } = Typography;

// Define the structure of the data
interface PreQualLetter {
  serialID: string;
  issueDate: string;
  loanAmount: string;
  expirationDate: string;
}

const PreQualLetterHistory = forwardRef((props, ref) => {
  let token = com.getUserToken();
  const paths = window.location.pathname.split("/");
  const loanID = paths[paths.length - 1];

  const [data, setData] = useState<PreQualLetter[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [trigger, setTrigger] = useState<number>(0);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [currentLetterIndex, setCurrentLetterIndex] = useState<number>(0);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);

  const fetchData = async () => {
    try {
      // Only set loading if there are no letters, if there are letters but
      // we are refetching the letters, we should set loading to true
      if (data.length === 0) {
        setLoading(true);
      }

      const response = await fetch(
        "/borrower/get_all_prequal_letters_for_loan",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache",
          },
          body: JSON.stringify({ loanid: loanID }),
        }
      );
      const rawData = await response.json();
      const sortedData = sortBy(
        rawData,
        (record) => -moment(record.issueDate).unix()
      );
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch data");
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  useEffect(() => {
    let ignore = false;
    fetchData();
    return () => {
      ignore = true;
    };
  }, [trigger]);

  useEffect(() => {
    if (data.length > 0) {
      getPDFContent(data[currentLetterIndex].serialID);
    }
  }, [data]);

  const getPDFContent = async (documentSerialID: string) => {
    setPdfUrl("");
    const response = await fetch(`/borrower/get_one_prequal_letter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify({ documentSerialID }),
    });

    const pdfBlob = await response.blob(); // Fetch PDF as Blob
    const pdfBlobUrl = URL.createObjectURL(pdfBlob); // Convert Blob to URL
    setPdfUrl(`${pdfBlobUrl}`); // Set Blob URL for iframe
  };

  const handleOpenSendEmailModal = () => {
    setIsSendEmailModalOpen(true);
  };

  const handleCloseSendEmailModal = () => {
    setIsSendEmailModalOpen(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      <PrequalLetterHeader />
      {loading && <Skeleton active />}
      {!loading && (
        <div>
          {data.length === 0 && <NoPrequalLetters />}
          {data.length > 0 && (
            <div>
              <Row className="prequal-letter-history-subheader">
                <Col
                  span={15}
                  className="prequal-letter-history-subheader-buttons"
                >
                  <Space style={{ alignItems: "flex-start" }} size="large">
                    <a
                      href={pdfUrl}
                      download="prequal-letter.pdf" // Suggested filename for download
                      className="prequal-letter-history-download"
                      style={{ textDecoration: "none" }}
                    >
                      <Button disabled={!pdfUrl}>
                        <img
                          src="/images/document-download-blue.svg"
                          alt="Download"
                        />
                        Download
                      </Button>
                    </a>
                    <Button
                      disabled={!pdfUrl}
                      onClick={handleOpenSendEmailModal}
                      style={{ display: "none" }}
                    >
                      <img src="/images/document-share-blue.svg" alt="Share" />
                      Share
                    </Button>
                  </Space>
                </Col>
                <Col span={1}></Col>
                <Col
                  span={8}
                  className="prequal-letter-history-subheader-history-text"
                >
                  <Text>Pre-qual letter history</Text>
                </Col>
              </Row>

              <SharePreQualLetterModal
                visible={isSendEmailModalOpen}
                onClose={handleCloseSendEmailModal}
              />

              <Row>
                <Col 
                  span={24}
                  md={{
                    span: 8,
                    order: 3,
                  }}>
                  <div className="prequal-letter-history-selection-container">
                    {data.map((dataRow, index) => {
                      return (
                        <ClickablePrequalLetterDisplay
                          purchasePrice={Number(dataRow.loanAmount)}
                          expirationDate={dataRow.expirationDate}
                          onClick={() => {
                            getPDFContent(dataRow.serialID);
                            setCurrentLetterIndex(index);
                          }}
                          active={
                            dataRow.serialID ===
                            data[currentLetterIndex].serialID
                          }
                        />
                      );
                    })}
                  </div>
                </Col>
                <Col span={24} md={{span: 1, order: 2}}></Col>
                <Col 
                  span={24}
                  xs={{ span: 0 }}
                  sm={{ span: 0 }}
                  md={{
                    span: 15,
                    order: 1,
                  }} 
                  style={{ height: "80vh" }}
                >
                  {pdfUrl ? (
                    <PDFViewer src={pdfUrl} fileName="prequal_letter.pdf" />
                  ) : (
                    <Skeleton active />
                  )}
                </Col>
              </Row>
              <Row className="prequal-letter-history-download-mobile-row">
                <Col span={24}>
                    <a
                      href={pdfUrl}
                      download="prequal-letter.pdf" // Suggested filename for download
                      className="prequal-letter-history-download-mobile"
                      style={{ textDecoration: "none" }}
                    >
                      <Button disabled={!pdfUrl}>
                        Download letter
                      </Button>
                    </a>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
export default PreQualLetterHistory;
