import React from 'react'
import { Modal } from 'antd'
import "./SmallModal.css"


const SmallModal = ({ open, onClose, title, content, footer }) => {
    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={footer}
            width={500}
            title={title}
            wrapClassName='small-modal'
            classNames={{
                content: 'small_modal_content',
                body: 'small_modal_body',
                header: 'small_modal_header',
                footer: 'small_modal_footer'
            }}
        >
            {content}
        </Modal>
    )
}

export default SmallModal
