import React, { useState, useEffect, useRef } from "react";
import { useBreakPoints, useViewport } from "../HistoricalChart/Hooks";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Tips } from "../Common/Tooltip";
import { Result } from "antd";
import { Form, Col, Row, InputGroup, Button, Container } from "react-bootstrap";
import { Spin, Alert, Divider } from "antd";
import { withRouter } from "react-router-dom";
import * as com from "../Common"
import "./GoogainCalculator.css";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import { CommaizedInput } from "../NumericalInput";
import DividerWithText from "../Common/components/DividerLineWithText";

const GoogainCalculatorComponent = (props) => {
  const [propertyAddress, setPropertyAddress] = useState("");
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyCounty, setPropertyCounty] = useState("");
  const [propertyCity, setPropertyCity] = useState("");
  const [propertyZip, setPropertyZip] = useState("");
  const [purpose, setPurpose] = useState("");
  const [occupancy, setOccupancy] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [unit, setUnit] = useState("1");
  const [firstTime, setFirstTime] = useState(false)
  const [loanAmount, setLoanAmount] = useState("");
  const [propertyValue, setPropertyValue] = useState("");
  
  const [baseIncome, setBaseIncome] = useState("")
  const [bonusIncome, setBonusIncome] = useState("")
  const [rsuIncome, setRsuIncome] = useState("") 

  const [income, setIncome] = useState("");
  const [qualified, setQualified] = useState(null)
  const [incentives, setIncentives] = useState("")
  const [isHighCostArea, setIsHighCostArea] = useState(false)
    
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPropertyInfo, setShowPropertyInfo] = useState(false);
  const [medianIncome, setMedianIncome] = useState(null);
  const [incomeIndicator, setIncomeIndicator] = useState(null);
  const [loanLimit, setLoanLimit] = useState(null);

  const [borrowerName, setBorrowerName] = useState("")
  const [borrowerPhone, setBorrowerPhone] = useState("")
  const [borrowerEmail, setBorrowerEmail] = useState("")
  const [loadingContact, setLoadingContact] = useState(false)

  const urlParams = new URLSearchParams(window.location.search);
  const loEmail = urlParams.get('email'); 
  const loFname = urlParams.get('fn'); 
  const loLname = urlParams.get('ln'); 
  const loNMLS = urlParams.get('nmls'); 
  const loPhone = urlParams.get('phone'); 
  const loCCID = urlParams.get('ccid'); 


  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");

    let city = com.getSubaddress(address, "locality", "long_name");
    setPropertyCity(city)
    let state = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "short_name"
    );
    setPropertyState(state)
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    setPropertyZip(zip)

    let county = com.getSubaddress(
      address,
      "administrative_area_level_2",
      "long_name"
    );
    setPropertyCounty(county)
    let longstate = com.getSubaddress(
      address,
      "administrative_area_level_1",
      "long_name"
      );
    

    let sfx = "";
    let short = com.getSubaddress(address, "route", "long_name");
    setPropertyAddress(num + " " + short)
    let stradd = num + " " + short + ", " + city + ", " + state + " " + zip;
    setDisplayedAddress(stradd)
  };

  const displayPropertyInfo = () => {
    if (medianIncome === null || incomeIndicator === null || loanLimit === null) {
      return ""
    }

    const mapIncomeIndicator = (indicator) => {
      switch (indicator) {
        case 1:
          return "low income"
        case 2:
          return "moderate income"
        case 3:
          return "middle income"
        case 4:
          return "upper income"
        default:
          return "Unknown"
      }
    }
    if (isHighCostArea) {
      return <div className="mdaPropertyInfo googainMarginAdjuster">The property is located in a {mapIncomeIndicator(incomeIndicator)}, high cost area, where the 120% median income is ${com.commaize(medianIncome * 1.2)}, and the conforming loan limit for a {unit}-unit property is ${com.commaize(loanLimit)}.</div>
    }
    return <div className="mdaPropertyInfo googainMarginAdjuster">The property is located in a {mapIncomeIndicator(incomeIndicator)} area, where the 100% median income is ${com.commaize(medianIncome)}, and the conforming loan limit for a {unit}-unit property is ${com.commaize(loanLimit)}.</div>
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setShowError(false)
    setQualified(null)
    setIncentives("")
    setShowPropertyInfo(false)
    setMedianIncome(null)
    setIncomeIndicator(null)
    setLoanLimit(null)
    setIncome("")
    setIsHighCostArea(false)

    setLoading(true)
    fetch("/data/loanlimitswithincome", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
      })
    })
    .then((res) => {
      if (!res.ok) {
        ;
        setLoading(false)
        setShowError(true)
        return
      }
      res.json().then((js) => {

        let conformingLoanLimit = [806500, 1032650, 1248150, 1551250]
        let highCost= false
        let ll = ""
        switch (unit) {
          case "1":
            ll = js.OneUnit
            setLoanLimit(js.OneUnit)
            if (js.OneUnit > conformingLoanLimit[0]) {
              highCost = true
              setIsHighCostArea(true)
              break
            }
          case "2":
            ll = js.TwoUnit
            setLoanLimit(js.TwoUnit)
            if (js.TwoUnit > conformingLoanLimit[1]) {
              highCost = true
              setIsHighCostArea(true)
              break
            }
          case "3":
            ll = js.ThreeUnit
            setLoanLimit(js.ThreeUnit)
            if (js.ThreeUnit > conformingLoanLimit[2]) {
              highCost = true
              setIsHighCostArea(true)
              break
            }
          case "4":
            ll = js.FourUnit
            setLoanLimit(js.FourUnit)
            if (js.FourUnit > conformingLoanLimit[3]) {
              highCost = true
              setIsHighCostArea(true)
              break
            }
          default:
            break
        }

        let ami = js.MedianIncome
        let incomeIndicator = js.IncomeIndicator

        setMedianIncome(ami)
        setIncomeIndicator(incomeIndicator)
        
        setShowPropertyInfo(true)
        let totalIncome = com.safeParseInt(baseIncome) + com.safeParseInt(bonusIncome) + com.safeParseInt(rsuIncome)
        setIncome(totalIncome)
  
        setLoading(false)

        if (firstTime) {
          if (highCost && totalIncome < ami * 1.2 || !highCost && totalIncome < ami) {
            setQualified(true)
            setFocus(1)
            setIncentives("You are qualified for LLPA waiver for loan amount up to $" + com.commaize(ll))
            return
          }
          if (totalIncome < ami * 0.8 || (incomeIndicator <= 2 && incomeIndicator >= 1)) {
            setQualified(true)
            setFocus(1)
            setIncentives("You are qualified for HomeRun and CRA Incentive for loan amount up to $" + com.commaize(ll))
            return
          }
          if (totalIncome < ami * 0.8 || incomeIndicator <= 2) {
            setQualified(true)
            setFocus(1)
            setIncentives("You are qualified for CRA Incentive without loan amount limit.")
            return
          }    
        } else {
          if (purpose === "purchase" && occupancy === "primary") {
            if (totalIncome < ami * 0.8 || incomeIndicator <= 2) {
              setQualified(true)
              setFocus(1)
              setIncentives("You are qualified for CRA Incentive without loan amount limit.")
              return
            } 
          }
          if (purpose !== "purchase") {
            if (totalIncome < ami * 0.8 || incomeIndicator <= 2) {
              setQualified(true)
              setFocus(1)
              setIncentives("You are qualified for CRA Incentive without loan amount limit.")
              return
            } 
          }
        }
        setFocus(1)
        setQualified(false)
      });

    })
    .catch((err) => {
      console.error(err);
      alert("Some internal error happened. Please try again later.")
    });

  }
  const submitContact = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setLoadingContact(true)
    fetch("/borrower/googain/contactme", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Address: propertyAddress,
        State: propertyState,
        City: propertyCity,
        Zip: propertyZip,
        Purpose: purpose,
        Occupancy: occupancy,
        PropertyType: propertyType,
        Unit: unit,
        FirstTime: firstTime,
        LoanAmount: loanAmount,
        PropertyValue: propertyValue,
        BaseIncome: String(com.safeParseInt(baseIncome)),
        BonusIncome: String(com.safeParseInt(bonusIncome)),
        RSUIncome: String(com.safeParseInt(rsuIncome)),
        MedianIncome: String(medianIncome===null?"":medianIncome),
        IncomeIndicator: String(incomeIndicator===null?"":incomeIndicator),
        LoanLimit: String(loanLimit===null?"":loanLimit),
        Incentive: incentives,
        IsHighCost: isHighCostArea,

        BorrowerName: borrowerName,
        BorrowerPhone: borrowerPhone,
        BorrowerEmail: borrowerEmail,

        LOEmail: loEmail===null?"":loEmail,
        LOPhone: loPhone===null?"":loPhone,
      })
    })
    .then((res) => {
      if (!res.ok) {
        setLoadingContact(false)
        ;
        return
      }

      setLoadingContact(false)
      alert("Thanks for your submission. We will contact you shortly.")

    })
    .catch((err) => {
      console.error(err);
      setLoadingContact(false)
      alert("Some internal error happened. Please try again later.")
    });

  }

  const contactMe = <Row className="mt-5">
    <Col></Col>
  <Col md={6} xs={12} className="text-left">
    <div className="callbackTitle-1 mb-2">Request a call back from your loan officer</div>

      {loEmail !== null && 
      <div className="callback-lo">
        <div className="callbackTitle-3 mb-1"><img src="/images/loanofficer.svg" style={{width:30}}/>Your Googain Loan Officer</div>
        <div className="callback-loinfo">
          <div className="callbackTitle-4">{loFname} {loLname}</div>
          <div className="callbackTitle-4">{loEmail}</div>
          <div className="callbackTitle-4">{loPhone!==null? com.fixPhoneInput(loPhone):""}</div>
          <div className="callbackTitle-4">NMLS #{loNMLS}</div>
        </div>
      </div>}

    <Form onSubmit={submitContact}>
      <Form.Group controlId="name">
        <Form.Label className="callbackTitle-2">Your name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" onChange={e=>setBorrowerName(e.target.value)} required/>
      </Form.Group>
      <Form.Group controlId="phone">
        <Form.Label className="callbackTitle-2">Your phone number</Form.Label>
        <Form.Control type="text" placeholder="Enter your phone number" onChange={e=>setBorrowerPhone(com.fixPhoneInput(e.target.value))} value={borrowerPhone} required/>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label className="callbackTitle-2">Your email</Form.Label>
        <Form.Control type="email" placeholder="Enter your email" onChange={e=>setBorrowerEmail(e.target.value)} required/>
      </Form.Group>
      <Button variant="zeitro-primary" type="submit" className="w-100" >
        {loadingContact ? <div className="googainSpin"><Spin  /></div> : "Submit"}
      </Button>
      <DividerWithText text={"Or"}/>
      <Button variant="zeitro-primary" className="w-100" onClick={()=>window.open("https://www.googain.com/LoanOfficer.html?loanapp&siteId=8020463439&workFlowId=124512&lar="+loCCID, '_blank', 'noopener,noreferrer')}>
        Apply Now
      </Button>
    </Form>
  </Col>
  <Col></Col>
  </Row>

  return (
    <div className="py-5">
      <div className="hfaSubTitle text-center mb-4 px-5">
      Check your eligibility for special incentive programs with attractive rates and start your homeownership journey!
      </div>


      <div className="p-5">   
        <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={propertyType==="multifamily" ? 3: 4} xs={12}>
            <AddressAutocomplete
            id="Address"
            label="Property address"
            required={true}
            name="addessline"
            defaultValue={displayedAddress}
            onChange={(e) => setDisplayedAddress(e.target.value)}
            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
            placeholder="Please type your home address and select from the dropdown addresses"
            goodfeedback=""
            badfeedback="Please provide your street address."
            onCompletion={e=>onStreetAddressCompletion(e)}
            size="md"
            />
          </Col>
          <Col md={propertyType==="multifamily" ? 3: 4} xs={12}>
            <Form.Group controlId="currentdwelling" >
                <Form.Label className="text-left" >Property type</Form.Label>
                <Form.Control required name="currentdwelling" as="select" 
                  size="md"
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="singlefamily">Single Family</option>
                    <option value="condo">Condo</option>
                    <option value="pud">PUD</option>
                    <option value="multifamily">2 - 4 Units</option>
                </Form.Control>
            </Form.Group>
          </Col>
          {propertyType==="multifamily" && <Col md={3} xs={12}>
            <Form.Group controlId="unit" >
                <Form.Label className="text-left" >Units</Form.Label>
                <Form.Control required name="unit" as="select" 
                    size="md"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </Form.Control>
            </Form.Group>
          </Col>}
          <Col md={propertyType==="multifamily" ? 3: 4} xs={12}>
            <Form.Label >{purpose==="purchase"?"Purchase price":"Home value"}</Form.Label>
              <CommaizedInput value={propertyValue} onChange={e=>setPropertyValue(e.target.value)} withDollarSign required/>
          </Col>
        </Row>
        <Row>
          <Col>
            {showPropertyInfo && displayPropertyInfo()}
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <Form.Group  >
                      <Form.Label className="text-left googainMarginAdjuster" >Loan purpose</Form.Label>
                      <Form.Control required name="currentdwelling" as="select" 
                        size="md"
                          value={purpose}
                          onChange={(e) => setPurpose(e.target.value)}
                      >
                          <option value="">...</option>
                          <option value="purchase">Purchase</option>
                          <option value="ratetermrefinance">Refinance</option>
                          <option value="cashoutrefinance">Cash out</option>
                      </Form.Control>
                  </Form.Group>
          </Col>
          <Col md={4} xs={12}>
            <Form.Group  >
                <Form.Label className="text-left" >Occupancy</Form.Label>
                <Form.Control required name="currentdwelling" as="select" 
                  size="md"
                    value={occupancy}
                    onChange={(e) => setOccupancy(e.target.value)}
                >
                    <option value="">...</option>
                    <option value="primary">Primary</option>
                    <option value="secondhome">Second home</option>
                    <option value="investment">Investment</option>
                </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4} xs={12} >
            <Form.Label >Loan amount</Form.Label>
              <CommaizedInput value={loanAmount} onChange={e=>setLoanAmount(e.target.value)} withDollarSign required/>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12} className="googainMarginAdjuster">
            <Form.Label >Yearly base income</Form.Label>
              <CommaizedInput value={baseIncome} onChange={e=>setBaseIncome(e.target.value)} withDollarSign required/>
          </Col>
          <Col md={4} xs={12} className="googainMarginAdjuster">
            <Form.Label >Yearly bonus income</Form.Label>
              <CommaizedInput value={bonusIncome} onChange={e=>setBonusIncome(e.target.value)} withDollarSign />
          </Col>
          <Col md={4} xs={12} className="googainMarginAdjuster">
            <Form.Label >Yearly RSU income</Form.Label>
              <CommaizedInput value={rsuIncome} onChange={e=>setRsuIncome(e.target.value)} withDollarSign />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="I am first time home buyer" checked={firstTime} onChange={(e)=>setFirstTime(e.target.checked)} />
          </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-center" >
            <Button type="submit" variant="zeitro-primary">{loading? <div className="googainSpin"><Spin  /></div>: "Check my eligibility"}</Button>
          </Col>
        </Row>
        </Form>
        
        {!loading && showError && <div className="mx-2 mt-3">
          <Alert message="Sorry, we're unable to process the information provided. Please verify your address and select an option from the dropdown list." type="warning" showIcon/>
          </div>}
        {!loading && qualified ===true &&   <Result
            status="success"
            title="You may be qualified!"
            subTitle="You may be qualified for a home loan incentive, contact your Googain agent for details."
        />}
        {!loading && qualified === false &&   <Result
            status="warning"
            title="You're not qualified for the incentive programs."
            subTitle="Your loan agent might be able to help you with other programs."
        />}
        <Divider />
        {contactMe}
        <Row>
          <Col>
          <div className="title-6 mb-3">
            Disclaimer:
          </div>
          <div className="title-6">
            Kindly be aware that mortgage programs and interest rates are subject to change due to market fluctuations and other external factors beyond our control. This calculator provides approximations for informational purposes only and may not reflect the current eligibility and rates at the time of your application. To ensure accuracy and address any inquiries or concerns, we strongly advise individuals to reach out to our loan officers for confirmation of the program details and to discuss any questions or uncertainties they may have. Any reply made by this website is not a commitment to lend. Loans are subject to underwriting approval.

          </div>
          </Col>
        </Row>
      </div>
    </div>
  );
  };

  const setFocus= (index) => {

    const { innerWidth } = window;
    let scrollY = 0
    if (innerWidth < 450) {
      switch (index) {
        case 0:
          scrollY = 0
          break;
        case 1:
          scrollY = 1000
          break;
        default:
          break;
      }
    } else {
      switch (index) {
        case 0:
          scrollY = 0
          break;
        case 1:
          scrollY = 400
          break;
        default:
          break;
      }
    }
    window.scrollTo({
      top: scrollY,
      behavior: 'smooth',
    });

  }

const calculateDimensions = (breakPoints, viewport) => {
  let w, h;
  if (breakPoints.xl) {
    w = 1000;
    h = 800;
  } else if (breakPoints.lg) {
    w = 800;
    h = 600;
  } else if (breakPoints.md) {
    w = 600;
    h = 400;
  } else if (breakPoints.sm || breakPoints.xs) {
    w = Math.round(viewport.width * 0.95);
    h = 300;
  }
  return { width: w, height: h };
};

function GoogainCalculator(props) {
  window.drift.hide();
  const breakPoints = useBreakPoints();
  const viewport = useViewport();
  const { width, height } = calculateDimensions(breakPoints, viewport);
  const [loaded, setLoaded] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: [width, height + 39] },
          "*"
        );
        // console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);

  });
  const sc = `window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '3d049056fe77046e5f5ff3cad9e35f9805d4abae', { region: 'eu' });
    smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
    `;
  return (
    <Router>
      {!window.location.hostname.includes("localhost") && (
        <Helmet>
          <script type="text/javascript">{sc}</script>
        </Helmet>
      )}

      <div
        ref={ref}
        className="App py-0 my-0 mx-0 px-0 pb-0"
        style={{ textAlign: "left", borderTop: "none" }}
      >
        <div className="navigation-bolck"></div>
        <div width={width} height={height}>
          <GoogainCalculatorComponent {...props} />
        </div>
      </div>
    </Router>
  );
}

export default withRouter(GoogainCalculator);
