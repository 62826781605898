import React from 'react';
import './StatusBanner.css';

const StatusBanner = ({ onImport, buttonText, messageText }) => {
  return (
    <div className="status-banner">
      <div className="status-banner-content">
        <div className="status-banner-left">
          <div className="icon-container">
            <img src="/images/components/status-banner.svg"  />
          </div>
          <span>{messageText}</span>
        </div>
        {onImport && buttonText && (
          <button 
            className="import-button" 
            onClick={onImport}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default StatusBanner;
