import React, { ref, useState, useEffect, useRef, useMemo } from "react"
import { useBreakPoints, useViewport } from "../HistoricalChart/Hooks"
import { BrowserRouter as Router } from "react-router-dom"
import { Helmet } from "react-helmet"
import {
    Form,
    Col,
    Row,
    InputGroup,
    Button,
} from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { Switch } from 'antd-mobile'
import Alert from "react-bootstrap/Alert"
import HeaderFixed from "../Common/components/HeaderFixed/HeaderFixed"
import ScrollBar from "../Common/components/ScrollBar"
import { commaize, logo } from "../Common"
import { AffordabilityHorizontalSlider } from "../Common/HorizontalSlider"
import { NumericalInputSimple } from "../NumericalInput"
import "./Affordability.css"
import CheckGroup from "../Common/components/CheckGroup"
import styled from "styled-components"
import OverlayTrig from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import {Select} from 'antd'

const Container = styled.span`
  display: flex;
  width: 100%;
  height: calc(0.625 * 100vw);
  align-items: center;
  justify-content: center;
`
let possiblePayments = {}
let counts = []
let loanLimits = {}
let customerUrl = "https://app.zeitro.com"
let customerid = null
let loid = null
let tempUrl = null

const PropertyUsageList = [
    {
        value: 'primary',
        label: 'Primary residence',
    },
    {
        value: 'secondHome',
        label: 'Second home',
    },
    {
        value: 'investment',
        label: 'Investment',
    },
]
const PropertyTypeList = [
    {
        value: 'singlefamily',
        label: 'Single family/Townhouse',
    },
    {
        value: 'multifamily',
        label: 'Multi family',
    },
    {
        value: 'condo',
        label: 'Condo',
    },
    // {
    //   value: 'pud',
    //   label: 'PUD',
    // }
]
const calcMonthlyInterestRate = (creditScore) => {
    if (creditScore <= 600) return undefined
    switch (true) {
        case (creditScore > 740 && creditScore <= 760):
            return 5.75
        case (creditScore > 720 && creditScore <= 740):
            return 6.125
        case (creditScore > 700 && creditScore <= 720):
            return 6.25
        case (creditScore > 680 && creditScore <= 700):
            return 6.375
        case (creditScore > 660 && creditScore <= 680):
            return 6.5
        case (creditScore > 640 && creditScore <= 660):
            return 6.725
        case (creditScore > 620 && creditScore <= 640):
            return 7.125
        case (creditScore === 620):
            return 7.25
        default:
            return 5.75
    }
}


const DEFAULT_LOAN_TERM = 30 //year
const DEFAULT_PROPERTY_TAXES_RATE = 1.00

const AffordabilityComponent = (props) => {
    const forMobileHome = typeof props.forMobileHome === "undefined" ? false : true;
    const [indexPayments, setIndexPayments] = useState(0)
    const [curPrice, setCurPrice] = useState({
        monthlyPayment: 0,
        homePrice: 0,
        mortgagePayment: 0,
        loanAmount: 0,
        ltv: 0,
        dti: 0,
        overLTV: false,
        overJumbo: false,
    })
    const [termIncome, setTermIncome] = useState(100000)
    const [monthlyDebt, setMonthlyDebt] = useState(500)
    const [monthlyPayment, setMonthlyPayment] = useState(
        (100000 / 12) * 0.38 - monthlyDebt
    )
    const [downPrice, setDownPrice] = useState(100000)
    const [mortgagePayment, setMortgagePayment] = useState(0)
    const [loanTerm, setLoanTerm] = useState(forMobileHome ? '20' : DEFAULT_LOAN_TERM)
    const [propertyTax, setPropertyTax] = useState(0)
    const [homeInsurance, setHomeInsurance] = useState(0)
    const [hoaDUE, setHOADue] = useState(0)
    const [creditScore, setCreditScore] = useState(760)
    const [baseRate, setBaseRate] = useState(forMobileHome ? '7.00' : calcMonthlyInterestRate(760).toFixed(2))
    const [incomeTerm, setIncomeTerm] = useState("yearly")
    const [unitNumber, setUnitNumber] = useState(1)
    const [occupancyType, setOccupancyType] = useState("primary")
    const [propertyType, setPropertyType] = useState("singlefamily")
    const [maxLTV, setMaxLTV] = useState(97)
    const [loanState, setLoanState] = useState("CALIFORNIA")
    const [loanCounty, setLoanCounty] = useState("SANTA CLARA COUNTY")
    const [showDtiSlider, setShowDtiSlider] = useState(false)
    const [sticky, setSticky] = useState("")
    const [selectedTerm, setSelectedTerm] = useState("30")
    const [pmi, setPmi] = useState(0)
    const [detailExpand, setDetailExpand] = useState(false)
    // on render, set listener for sticky house price card
    useEffect(() => {
        if (props.isMobile) {
            window.addEventListener("scroll", isSticky)
            return () => {
                window.removeEventListener("scroll", isSticky)
            }
        }
    }, [])


    const isSticky = () => {
        /* Method that will fix the housr price card after a specific scrollable */
        const scrollTop = window.scrollY

        let ele = document.getElementById("landingTools")
        let p = 0
        if (ele) p = ele.offsetTop
        const stickyClass = scrollTop >= p + 100 ? "is-sticky" : ""
        setSticky(stickyClass)
    }

    const stickyClasses = `affordabilityResult ${sticky} mx-3`

    let changeCreditScore = (v) => {
        console.log("v")
        console.log(typeof v)
        setCreditScore(v)
        setBaseRate(calcMonthlyInterestRate(v))
        // }
    }

    let changeIndexPayments = (v) => {
        setIndexPayments(v)
        setCurPrice(possiblePayments[indexPayments])
    }

    const resetAll = () => {
        setUnitNumber(1)
        setOccupancyType("primary")
        setPropertyType("singlefamily")
        setHOADue(0)
        setPropertyTax(0)
        setPmi(0)
        setHomeInsurance(0)
        setTermIncome(100000)
        setMonthlyDebt(500)
        setDownPrice(100000)
        setLoanTerm(DEFAULT_LOAN_TERM)
        setCreditScore(760)
        setBaseRate(forMobileHome ? 7.00 : calcMonthlyInterestRate(760))
        setIncomeTerm("yearly")
        setTaxes(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getIncome = () => {
        if (incomeTerm === "yearly") return termIncome / 12
        return termIncome
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getMaxConvLTV = () => {
        let ltv = -1
        if (occupancyType === "primary" && unitNumber === 1) {
            ltv = 97
        } else if (occupancyType === "primary" && unitNumber === 2) {
            ltv = 85
        } else if (
            occupancyType === "primary" &&
            (unitNumber === 3 || unitNumber === 4)
        ) {
            ltv = 75
        } else if (occupancyType === "secondHome" && unitNumber === 1) {
            ltv = 90
        } else if (occupancyType === "investment" && unitNumber === 1) {
            ltv = 85
        } else if (
            occupancyType === "investment" &&
            (unitNumber === 2 || unitNumber === 3 || unitNumber === 4)
        ) {
            ltv = 75
        }
        return ltv
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getMaxJumboLTV = () => {
        let ltv = -1
        if (occupancyType === "primary" && unitNumber === 1) {
            ltv = 90
        } else if (
            occupancyType === "primary" &&
            (unitNumber === 2 || unitNumber === 3 || unitNumber === 4)
        ) {
            ltv = 80
        } else {
            ltv = 80
        }
        return ltv
    }

    const getLoanLimit = () => {
        if (loanLimits[loanState][loanCounty] !== undefined) {
            if (unitNumber === 1) {
                return loanLimits[loanState][loanCounty].OneUnit
            } else if (unitNumber === 2) {
                return loanLimits[loanState][loanCounty].TwoUnit
            } else if (unitNumber === 3) {
                return loanLimits[loanState][loanCounty].ThreeUnit
            } else if (unitNumber === 4) {
                return loanLimits[loanState][loanCounty].FourUnit
            }
        } else {
            return 806500
        }
    }

    const getHousePriceByLTV = (maxConvLTV, maxJumboLTV, downPay) => {
        let loanAmountLimit = 1209750

        let hpConv = downPay / (1 - maxConvLTV * 0.01)
        if (hpConv * maxConvLTV * 0.01 > loanAmountLimit) {
            hpConv = loanAmountLimit + downPay
        }

        let hpJumbo = downPay / (1 - maxJumboLTV * 0.01)

        if (hpConv > hpJumbo) {
            setMaxLTV(maxConvLTV)
            return hpConv
        }
        setMaxLTV(maxJumboLTV)
        return hpJumbo
    }

    useEffect(() => {
        let maxConvLTV = getMaxConvLTV()
        let maxJumboLTV = getMaxJumboLTV()
        let housePriceLTV = getHousePriceByLTV(maxConvLTV, maxJumboLTV, downPrice)

        // console.log(maxConvLTV, maxJumboLTV);
        possiblePayments = {}
        counts = []

        if (termIncome > 0 && downPrice > 0 && creditScore >= 620) {
            let n = loanTerm * 12
            let rate = (Number(baseRate) * 0.01) / 12

            for (let dti = 20; dti <= 50; dti++) {
                let overLTV = false
                let overJumbo = false
                let monthlypay = getIncome() * dti * 0.01 - monthlyDebt
                // loanAmt * (rate * math.pow((1+rate), n)) / (math.pow((1+rate), n) - 1) + loanAmt * propertyTax + homeInsurance + hoaDUE = monthlyPayment
                let amorFactor =
                    (rate * Math.pow(1 + rate, n)) / (Math.pow(1 + rate, n) - 1)
                let loanAmt =
                    (monthlypay - hoaDUE / 12 - homeInsurance / 12) /
                    (amorFactor + (propertyTax * 0.01) / 12)

                let housePriceDTI = loanAmt + downPrice
                let finalHousePrice = housePriceDTI

                if (downPrice < (finalHousePrice * 0.2)) {
                    loanAmt =
                        (monthlypay - hoaDUE / 12 - homeInsurance / 12) /
                        (amorFactor + (propertyTax * 0.01) / 12 + (pmi * 0.01) / 12)
                    housePriceDTI = loanAmt + downPrice
                    finalHousePrice = housePriceDTI
                }

                if (housePriceDTI > housePriceLTV) {
                    overLTV = true
                    finalHousePrice = housePriceLTV
                    loanAmt = finalHousePrice - downPrice
                }

                if (loanAmt > 5000000) {
                    overJumbo = true
                    finalHousePrice = 5000000 + downPrice
                    loanAmt = 5000000
                }

                let mp = loanAmt * amorFactor
                let finalMonthlyPay = mp + (loanAmt * propertyTax * 0.01) / 12 + hoaDUE / 12 + homeInsurance / 12

                if (downPrice < (finalHousePrice * 0.2)) {
                    finalMonthlyPay = finalMonthlyPay + (loanAmt * pmi * 0.01) / 12
                }

                if (loanAmt > getLoanLimit() && dti > 43) {
                    break
                }

                if (finalMonthlyPay > 0 && finalHousePrice > 0) {
                    possiblePayments[dti] = {
                        monthlyPayment: finalMonthlyPay,
                        homePrice: finalHousePrice,
                        mortgagePayment: mp,
                        loanAmount: loanAmt,
                        ltv: Math.round((100 * loanAmt) / finalHousePrice),
                        dti: dti,
                        overLTV: overLTV,
                        overJumbo: overJumbo,
                    }
                    counts.push(dti)
                }
            }

            let i = counts.length - 6 //set dti default to 45%
            let curPay = possiblePayments[counts[i]]
            setIndexPayments(counts[i])
            setCurPrice(curPay)
        }

        // console.log(possiblePayments);
        // console.log(curPrice);
    }, [
        termIncome,
        incomeTerm,
        monthlyDebt,
        downPrice,
        loanTerm,
        creditScore,
        baseRate,
        hoaDUE,
        homeInsurance,
        propertyTax,
        propertyType,
        unitNumber,
        occupancyType,
        loanState,
        loanCounty,
        pmi
    ])

    let _setIncomeTerm = (e) => {
        setIncomeTerm(e.target.value)
    }

    let _setPropertyType = (e) => {
        setPropertyType(e.target.value)
        if (e.target.value === "multifamily") {
            setUnitNumber(2)
        } else {
            setUnitNumber(1)
        }
    }

    const onDtiSlider = () => {
        if (showDtiSlider) {
            setShowDtiSlider(false)
        } else {
            setShowDtiSlider(true)
        }
    }

    const getHousePriceCard = () => {
        let getStartedUrl = customerUrl + "/services/preapp?customerid=" + customerid
        if (typeof loid !== "undefined") {
            getStartedUrl = "https://app.zeitro.com/services/preapp?customerid=" + customerid + "&loid=" + loid
        }
        if (customerid === "wamlending"){
            getStartedUrl = "https://wamlending.my1003app.com/326424/register"
        }
        tempUrl = getStartedUrl
        const Textoverlay = ({ id, children, title }) => (
            <OverlayTrig placement="top" overlay={<Tooltip id={id}>{children}</Tooltip>}>
                <a href="#" style={{ textDecoration: "underline", fontWeight: "500", color: "#325CEB" }}>{title}</a>
            </OverlayTrig>
        )
        if (sticky !== "") {
            return (
                <div
                    className="housePriceCard"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                    <div>
                        <div className="housePriceTitle" style={{ fontSize: "14px" }}>
                            You can afford a house up to:{" "}
                        </div>
                        <div className="housePricePrice" style={{ fontSize: "20px" }}>
                            ${commaize(curPrice.homePrice.toFixed(0))}
                        </div>
                        <hr style={{ marginTop: "0.1rem", marginBottom: "0.1rem" }} />
                        <div>
                            {curPrice.overLTV && (
                                <div className="ltvAlert">
                                    <Row className="ltvAlertRow">
                                        <Col xs={1}>
                                            <img
                                                src="/images/tooltipBulb.png"
                                                style={{ maxWidth: "20px" }}
                                            />
                                        </Col>
                                        <Col xs={11}>
                                            Your current affordable house price is limited by maximum
                                            LTV requirements or loan limits, try increasing the
                                            down payment to get a higher house price.
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {curPrice.overJumbo && (
                                <div className="ltvAlert">
                                    <Row className="ltvAlertRow">
                                        <Col xs={1}>
                                            <img
                                                src="/images/warningSign.png"
                                                style={{ maxWidth: "20px" }}
                                                alt=''
                                            />
                                        </Col>
                                        <Col xs={11}>
                                            Currently we could provide a purchase loan as high as
                                            $5,000,000
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            <div>
                                <div className="housePriceDetailsSticky">
                                    <div>Monthly payment: </div>
                                    <div>${commaize(curPrice.monthlyPayment.toFixed(0))}</div>
                                </div>
                                <div className="housePriceDetailsSticky">
                                    <div>Mortgage payment:</div>
                                    <div>${commaize(curPrice.mortgagePayment.toFixed(0))}</div>
                                </div>
                                <div className="housePriceDetailsSticky">
                                    <div>Loan amount:</div>
                                    <div>
                                        ${commaize(curPrice.loanAmount.toFixed(0))} ({curPrice.ltv}%
                                        LTV)
                                    </div>
                                </div>
                                <div className="housePriceDetailsSticky">
                                    <div>Debt-to-income ratio (DTI):</div>
                                    <div>
                                        <b style={{ color: (curPrice.dti <= 37) ? '#00BE63' : ((curPrice.dti <= 44) && (curPrice.dti > 37)) ? '#FFAA29' : '#FF2929', }}>{curPrice.dti}% </b>{" "}
                                        <img
                                            onClick={() => onDtiSlider()}
                                            className="affordabilityPlusSign"
                                            src="/images/plusSign.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            {showDtiSlider && (
                                <div className="dtiSlider">
                                    <div className="dtiSliderTip">
                                        <div className="dtiSliderTipTitle">Why this ratio?</div>
                                        <div className="dtiSliderTipText">
                                            Typically your DTI should be 36% or lower to qualify for a
                                            mortgage. You may enter the percentage of your income you
                                            are comfortable spending towards your mortgage. This
                                            number will also take monthly debts into account.
                                        </div>
                                        <div className="dtiSliderTipTitle" style={{ marginBottom: '2rem' }}>
                                            Debt-to-income ratio
                                        </div>
                                    </div>
                                    <AffordabilityHorizontalSlider
                                        className="paymentSlider"
                                        onUpdate={changeIndexPayments}
                                        onChange={changeIndexPayments}
                                        step={1}
                                        domain={[counts[0], counts[counts.length - 1]]}
                                        values={[curPrice.dti]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="housePriceCard" style={{ marginTop: "10px" }} ref={ref}>
                <div>
                    <div className="text-center">
                        <div className="housePriceTitle">You can afford a house up to: </div>
                        <div className="housePricePrice">
                            ${commaize(curPrice.homePrice.toFixed(0))}
                        </div>
                    </div>
                    <div>
                        {curPrice.overLTV && (
                            <div className="ltvAlert">
                                <Row className="ltvAlertRow">
                                    <Col xs={1}>
                                        <img
                                            src="/images/tooltipBulb.png"
                                            style={{ maxWidth: "20px" }}
                                            alt=''
                                        />
                                    </Col>
                                    <Col xs={11}>
                                        Your current affordable house price is limited by maximum
                                        LTV requirements or loan limits, try increasing the
                                        down payment to get a higher house price.
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {curPrice.overJumbo && (
                            <div className="ltvAlert">
                                <Row className="ltvAlertRow">
                                    <Col xs={1}>
                                        <img
                                            src="/images/warningSign.png"
                                            style={{ maxWidth: "20px" }}
                                            alt=''
                                        />
                                    </Col>
                                    <Col xs={11}>
                                        Currently we could provide a purchase loan as high as
                                        $5,000,000
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <Row style={{ flexDirection: props.checkMobile ? "column" : "", alignContent: props.checkMobile ? "center" : "", marginTop: "15px", marginBottom: "10px", borderTop: "1px solid #DDDDDD", paddingTop: "18px" }}>
                            <Col className="mobile-monthpay-result" md={4} xs={4}>
                                <div className="main-text" style={{ whiteSpace: "nowrap" }}>Monthly payment</div>
                                <div style={{ display: "inline-flex" }}>
                                    <div className="main-value">${commaize(curPrice.monthlyPayment)}</div>
                                    {!props.checkMobile && <img
                                        src={!detailExpand ? "/images/showDetail-Expand.png" : "/images/showDetail-Close.png"}
                                        style={{ maxHeight: "20px", maxWidth: "20px", marginLeft: "15px", marginTop: "5px" }}
                                        onClick={() => setDetailExpand(!detailExpand)}
                                    />}
                                </div>
                            </Col>
                            <Col className={props.checkMobile ? "mobile-monthpay-result" : ""} md={4} xs={4}>
                                <div className="main-text" style={{ whiteSpace: "nowrap" }}>Down payment</div>
                                <div className="main-value">${commaize(downPrice)}</div>
                            </Col>
                            <Col className={props.checkMobile ? "mobile-monthpay-result" : ""} md={4} xs={4}>
                                <div className="main-text" style={{ whiteSpace: "nowrap" }}>Loan amount</div>
                                <div className="main-value">${commaize(curPrice.loanAmount.toFixed(0))}</div>
                            </Col>
                        </Row>
                        {(props.checkMobile ? true : detailExpand) && <div style={{ border: "1px solid #ECECEC", backgroundColor: "#FFFFFF", borderRadius: "4px", paddingTop: "5px", paddingBottom: "10px", paddingLeft: "25px", paddingRight: "25px" }}>
                            <div className="housePriceDetails">
                                <div>Mortgage payment</div>
                                <div className="sub-value">${commaize(curPrice.mortgagePayment.toFixed(0))}</div>
                            </div>
                            <div className="housePriceDetails">
                                <div>Property tax</div>
                                {!taxes && <div className="sub-value">$---</div>}
                                {taxes && <div className="sub-value">${commaize((curPrice.homePrice * propertyTax * 0.01) / 12)}</div>}
                            </div>
                            <div className="housePriceDetails">
                                <div>Homeowner insurance</div>
                                {!taxes && <div className="sub-value">$---</div>}
                                {taxes && <div className="sub-value">${homeInsurance / 12}</div>}
                            </div>
                            <div className="housePriceDetails">
                                <div>HOA fees</div>
                                {!taxes && <div className="sub-value">$---</div>}
                                {taxes && <div className="sub-value">${commaize(hoaDUE / 12)}</div>}
                            </div>
                            <div className="housePriceDetails">
                                <div>PMI</div>
                                {!taxes && <div className="sub-value">$---</div>}
                                {taxes && <div className="sub-value">{curPrice.ltv > 80 ? `$` + `${commaize((curPrice.loanAmount * pmi * 0.01) / 12)}` : "No Charge"}</div>}
                            </div>
                            {/* <div className="housePriceDetails">
                <div>Debt-to-income ratio (DTI):</div>
                <div>
                  <b style={{ color: (curPrice.dti <= 37) ? '#00BE63' : ((curPrice.dti <= 44) && (curPrice.dti > 37)) ? '#FFAA29' : '#FF2929', }}>{curPrice.dti}% </b>{" "}
                </div>
              </div> */}
                        </div>}
                        <div style={{ wordWrap: "break-word", marginTop: "20px", paddingLeft: "10px", paddingRight: "10px" }}>
                            Your affordability is calculated by a common Debt-to-income ratio (DTI) of 45%. {" "}
                            <Textoverlay title="Details" id='dti-tip'>
                                <div className="text-left">Your debt-to-income ratio (DTI) is all your monthly debt payments divided by your gross monthly income. This number is one way lenders measure your ability to manage the monthly payments to repay the money you plan to borrow.
                                </div>
                            </Textoverlay>
                        </div>
                        <div></div>
                        <div style={{ marginTop: "30px", fontSize: "20px", fontWeight: "600" }}><Button className="affordability-get-started" onClick={() => window.open(getStartedUrl, "_blank")}>Get Approved Now</Button></div>

                        {/* <div className="dtiSlider">
                <div className="dtiSliderTip">
                  <div className="dtiSliderTipTitle">Why this ratio?</div>
                  <div className="dtiSliderTipText">
                    Typically your DTI should be 36% or lower to qualify for a
                    mortgage. You may enter the percentage of your income you
                    are comfortable spending towards your mortgage. This numberA
                    will also take monthly debts into account.
                  </div>
                  <div className="dtiSliderTipTitle" style={{ marginBottom: '2rem' }}>Debt-to-income ratio</div>
                </div>
                <AffordabilityHorizontalSlider
                  className="paymentSlider"
                  onUpdate={changeIndexPayments}
                  onChange={changeIndexPayments}
                  step={1}
                  domain={[counts[0], counts[counts.length - 1]]}
                  values={[curPrice.dti]}
                />
              </div> */}

                    </div>
                </div>
            </div>
        )
    }

    const getChildValue = (val) => {
        setShowDtiSlider(val)
    }

    const getChangeChildValue = (val) => {
        console.log(val, "getChangeChildValue")
    }

    const getChangeChildValue1 = (val) => {
        console.log(val, "getChangeChildValue1")
    }

    const [propertyUsageValue, setPropertyUsageValue] = useState([])
    const [propertyTypevalue, setPropertyTypeValue] = useState([])

    const onChangeUsage = (e) => {
        console.log(e)
        setPropertyUsageValue(e)
    }

    const onChangeType = (e) => {
        console.log(e)
        setPropertyTypeValue(e)
    }

    const customStyle = {
        background: 'linear-gradient(to right, #00BE63, #00BE63 50%, #FFAA29 50%, #FFAA29 75%, #FFAAAA 75%, #FFAAAA);'
    }

    const [statusF, setstatusF] = useState(false)
    const [statusS, setstatusS] = useState(false)
    const [collapse1, setcollapse1] = useState(true)
    const [collapse2, setcollapse2] = useState(false)
    const [collapse3, setcollapse3] = useState(false)
    const [collapse4, setcollapse4] = useState(false)
    const changeStatus = (type) => {
        if (type === 1) {
            setstatusF(!statusF)
        } else {
            setstatusS(!statusS)
        }
    }
    const fixHeight = 5

    useEffect(() => {
        const scrollEle = document.getElementById("landingAffordability") || document.body // 获取滚动元素
        const handleScroll = (e) => {
            // todo
        }
        scrollEle.addEventListener("scroll", handleScroll)
        return () => {
            scrollEle.removeEventListener("scroll", handleScroll)
        }
    })

    const ref1 = useRef(null)
    const isInViewport = useIsInViewport(ref1)

    const changeScrollValue = (val) => {
        // console.log(val, "changeScrollValue");
        setTermIncome(val)
    }

    const changeScrollValue1 = (val) => {
        // console.log(val, "changeScrollValue1");
        setMonthlyDebt(val)
    }

    const changeScrollValue2 = (val) => {
        // console.log(val, "changeScrollValue2");
        setDownPrice(val)
    }
    const [taxes, setTaxes] = useState(false)
    const switchChange = () => {

        if (!taxes) {
            setHOADue(0)
            setPropertyTax(1.00)
            setHomeInsurance(0)
            setPmi(0.50)
            setTaxes(true)
        } else {
            setHOADue(0)
            setPropertyTax(0)
            setHomeInsurance(0)
            setPmi(0)
            setTaxes(false)
        }
    }

    return (
        <div
            id="landingAffordability"
            style={{ color: "black", alignItems: "start" }}
            className="affordabilityContainer mb-3 pb-2"
        >
            <div ref={ref1} className={stickyClasses}>
                {occupancyType === "secondHome" && unitNumber !== 1 ? (
                    <div>
                        <Alert className="alert mt-3">
                            We can not provide loans for second home multifamily houses.
                            <hr />
                            <div className="d-flex">
                                <Button onClick={() => resetAll()} className="resetbutton" variant="outline-primary">
                                    Reset All Numbers
                                </Button>
                            </div>
                        </Alert>
                    </div>
                ) : Object.keys(possiblePayments).length === 0 || typeof curPrice === "undefined" || creditScore <= 600 ? (
                    creditScore <= 600 ? (
                        <div >
                            <Alert className="alert mt-3">
                                Your credit score is too low for an automatic assessment. If
                                you’d like you to discuss your options. Please create an account
                                and contact with your loan officer.
                                <hr />
                                <div className="d-flex">
                                    <Button onClick={() => resetAll()} className="resetbutton" variant="outline-primary">
                                        Reset All Numbers
                                    </Button>
                                </div>
                            </Alert>
                        </div>
                    ) : (
                        <Alert className="alert mt-2">
                            Based on your information, homeownership is currently out of
                            reach. Try increasing income or down payment.
                            <hr />
                            <div className="d-flex">
                                <Button onClick={() => resetAll()} className="resetbutton" variant="outline-primary">
                                    Reset All Numbers
                                </Button>
                            </div>
                        </Alert>
                    )
                ) : (
                    <div>
                        {Object.keys(possiblePayments).length > 0 ? (
                            getHousePriceCard()
                        ) : (
                            <p></p>
                        )}
                    </div>
                )}
            </div>
            {
                (!isInViewport && typeof curPrice !== "undefined") && (
                    <div className="header-fixed" style={{ position: 'relative' }}>
                        <HeaderFixed getStartedUrl={tempUrl} changeIndexPayments={changeIndexPayments} curPrice={curPrice} counts={counts} fixHeight={fixHeight} getShowValue={getChildValue} homePrice={curPrice.homePrice.toFixed(0)} dti={curPrice.dti} loanAmount={curPrice.loanAmount.toFixed(0)} mortgagePayment={curPrice.mortgagePayment.toFixed(0)} />
                    </div>
                )
            }
            <div>
                <div className="main-heading" style={{ paddingLeft: "15px", marginBottom: "30px" }}>
                    <div style={{ fontSize: "36px", fontWeight: "600" }}>Affordability Calculator</div>
                    <div style={{ color: "#6E6E70", fontWeight: "400", fontSize: "16px", width: "450px", wordWrap: "break-word" }}>Estimate a comfortable mortgage amount based on your current budget.</div>
                </div>
                <div className="affordabilityForm-pc mx-3">
                    <Form>
                        <div style={{ fontWeight: "600", fontSize: "20px", marginBottom: "15px" }}>Basic Information</div>
                        <Row style={{ marginBottom: '8px' }} >
                            <Col md={6} sm={6} className="pl-0">
                                <Form.Group>
                                    <Form.Label className="affordabilityInputLabel">
                                        Annual Gross Income
                                    </Form.Label>
                                    <ScrollBar style={{ height: "2.75rem" }} max={3000000} min={1} step={100} initVal={termIncome} scrollChangeValue={changeScrollValue} hideSlider={true} />
                                </Form.Group>
                            </Col>
                            <Col md={6} sm={6} className="pl-0">
                                <Form.Group>
                                    <Form.Label className="affordabilityInputLabel">
                                        Monthly Debt
                                    </Form.Label>
                                    <ScrollBar style={{ height: "2.75rem" }} max={getIncome() * 0.6} min={1} step={100} initVal={monthlyDebt} scrollChangeValue={changeScrollValue1} hideSlider={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '8px' }} >
                            <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                                <Form.Group >
                                    <div style={{ display: 'flex', alignItems: 'top' }}>
                                        <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                                            FICO Score
                                        </Form.Label>
                                        <OverlayTrig placement="right" overlay={<Tooltip id='fico-tip'>
                                            Your FICO score, similar to a credit score, is a three-digit number indicating your creditworthiness, typically falling between 300 and 850. It plays an important role in determining the loan terms and interest rates you may be eligible for. You have several options for obtaining your FICO score, including checking your credit card statements, requesting it from major credit reporting agencies, or utilizing credit monitoring services
                                        </Tooltip>}>

                                            <img
                                                src="/images/tooltipBulb.png"
                                                style={{ width: "12px", height: "15px", marginLeft: "5px", marginBottom: "5px" }}
                                                alt=''
                                            />
                                        </OverlayTrig>
                                    </div>
                                    <div>
                                        <Select onChange={(e) => {
                                            changeCreditScore(e)
                                        }} value={creditScore} style={{ width: '100%', height: 44 }}>
                                            <option value="760">740+</option>
                                            <option value="740">721-740</option>
                                            <option value="720">701-720</option>
                                            <option value="700">681-700</option>
                                            <option value="680">661-680</option>
                                            <option value="660">641-660</option>
                                            <option value="640">621-640</option>
                                            <option value="620">Less than 621</option>
                                        </Select>

                                    </div>
                                    {/* <Form.Control
                                        className="affordability-form-inputs"
                                        style={{ height: '2.75rem' }}
                                        as="select"
                                        value={creditScore}
                                        onChange={(e) => {
                                            changeCreditScore(e.target.value)
                                        }}
                                    >
                                        <option value="760">740+</option>
                                        <option value="740">721-740</option>
                                        <option value="720">701-720</option>
                                        <option value="700">681-700</option>
                                        <option value="680">661-680</option>
                                        <option value="660">641-660</option>
                                        <option value="640">621-640</option>
                                        <option value="620">Less than 621</option>
                                    </Form.Control>
                                </Form.Group> */}
                                     </Form.Group>
                            </Col>
                            <Col md={6} xs={12} className="pl-0">
                                <Form.Group style={{ marginBottom: '10px' }}>
                                    <Form.Label className="affordabilityInputLabel">
                                        Estimated Rate
                                    </Form.Label>
                                    <InputGroup className="affordability-form-inputs" style={{ border: '1px solid #CFD4D9', borderRadius: '4px' }}>
                                        <Form.Control
                                            className="affordability-form-inputs"
                                            type="text"
                                            value={baseRate}
                                            placeholder={baseRate}
                                            onChange={(e) => {
                                                setBaseRate(e.target.value);
                                            }}
                                            onBlur={() => {
                                                // Format the value to two decimal places when the input loses focus
                                                setBaseRate(Number(baseRate).toFixed(2));
                                            }}
                                            style={{ border: "none" }}
                                        />
                                        <InputGroup.Text style={{ border: 'none', backgroundColor: '#FAFAFA !important' }}>
                                            {" "}
                                            %
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                                <Form.Group >
                                    <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                                        Loan Term
                                    </Form.Label>
                                    <div>
                                        <Select value={loanTerm}
                                            onChange={(e) => {
                                                setLoanTerm(e)
                                            }} style={{ width: '100%', height: 44 }}>
                                            <option value="30">30 year</option>
                                            <option value="25">25 year</option>
                                            <option value="20">20 year</option>
                                            <option value="15">15 year</option>
                                            <option value="10">10 year</option>
                                        </Select>

                                    </div>
                                    {/* <Form.Control
                                        className="affordability-form-inputs"
                                        style={{ height: '2.75rem' }}
                                        as="select"
                                        value={loanTerm}
                                        onChange={(e) => {
                                            setLoanTerm(e.target.value)
                                        }}
                                    >
                                        <option value="30">30 year</option>
                                        <option value="25">25 year</option>
                                        <option value="20">20 year</option>
                                        <option value="15">15 year</option>
                                        <option value="10">10 year</option>
                                    </Form.Control> */}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className="pl-0">
                                <Form.Group >
                                    <Form.Label className="affordabilityInputLabel">
                                        Down Payment
                                    </Form.Label>
                                    <ScrollBar style={{ height: "2.75rem" }} max={2000000} min={0} step={1000} initVal={downPrice} scrollChangeValue={changeScrollValue2} hideSlider={true} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0 mt-2">
                                <Form.Group >
                                    <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                                        Property Usage
                                    </Form.Label>
                                    <div>
                                        <Select
                                            value={occupancyType}
                                            onChange={(e) => {
                                                setOccupancyType(e)
                                            }}
                                            style={{ width: '100%', height: 44 }}>

                                            {PropertyUsageList.map(ele => (
                                                <option value={ele.value} key={ele.value}>{ele.label}</option>
                                            ))}
                                        </Select>

                                    </div>
                                    {/* <Form.Control
                                        className="affordability-form-inputs"
                                        style={{ height: '2.75rem' }}
                                        as="select"
                                        value={occupancyType}
                                        onChange={(e) => {
                                            setOccupancyType(e.target.value)
                                        }}
                                    >
                                        {PropertyUsageList.map(ele => (
                                            <option value={ele.value} key={ele.value}>{ele.label}</option>
                                        ))}
                                    </Form.Control> */}
                                </Form.Group>
                            </Col>
                            {!forMobileHome && (
                                <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                                    <Form.Group >
                                        <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                                            Property Type
                                        </Form.Label>
                                        <div>
                                            <Select
                                                value={propertyType}
                                                onChange={(e) => {
                                                    setPropertyType(e)
                                                }}
                                                style={{ width: '100%', height: 44 }}>

                                                {PropertyTypeList.map(ele => (
                                                    <option value={ele.value} key={ele.value}>{ele.label}</option>
                                                ))}
                                            </Select>

                                        </div>
                                        {/* <Form.Control
                                            className="affordability-form-inputs"
                                            style={{ height: '2.75rem' }}
                                            as="select"
                                            value={propertyType}
                                            onChange={(e) => {
                                                setPropertyType(e.target.value)
                                            }}
                                        >
                                            {PropertyTypeList.map(ele => (
                                                <option value={ele.value} key={ele.value}>{ele.label}</option>
                                            ))}
                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                        <Row style={{ marginBottom: "10px" }}>
                            {propertyType === "multifamily" && (
                                <Col xs={12} md={6} style={{ minWidth: "100px" }} className="pl-0">
                                    <Form.Group>
                                        <Form.Label className="affordabilityInputLabel">
                                            Multi Family Units
                                        </Form.Label>
                                        <div>
                                            <Select

                                                defaultValue={2}
                                                value={unitNumber}
                                                onChange={(e) => {
                                                    setUnitNumber(e)
                                                }}
                                                style={{ width: '100%', height: 44 }}>

                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                            </Select>

                                        </div>
                                        {/* <Form.Control
                                            className="affordability-form-inputs"
                                            size="sm"
                                            as="select"
                                            value={unitNumber}
                                            defaultValue={2}
                                            onChange={(e) => {
                                                setUnitNumber(parseInt(e.target.value))
                                            }}
                                        >
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>

                        <div className="collapse-header" style={{ marginBottom: "15px", paddingRight: "15px" }}>
                            <div>
                                <div style={{ fontWeight: "600", fontSize: "20px" }}>Tax, insurance and fees </div>
                                {taxes ? <div style={{ fontWeight: "500", color: "#62707C", lineHeight: "10px" }}>Including tax, insurance and fees</div> : <div style={{ fontWeight: "500", color: "#62707C", lineHeight: "10px" }}>Excluding tax, fee and insurance</div>}
                            </div>
                            <Switch className="taxses-switch" checked={taxes} onChange={switchChange} />
                        </div>
                        {taxes && <>
                            <Row>
                                <Col xs={6} style={{ paddingLeft: "0px" }}>
                                    <Form.Group className="affordabilityInput-pc">
                                        <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                            <div>Homeowners insurance</div>
                                            <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                        </Form.Label>
                                        <InputGroup style={{ border: '1px solid #CFD4D9', borderRadius: '4px' }}>
                                            <InputGroup.Text style={{ border: "none", backgroundColor: '#FFF' }}>
                                                $
                                            </InputGroup.Text>
                                            <Form.Control
                                                className="affordability-form-inputs"
                                                placeholder={homeInsurance}
                                                value={homeInsurance}
                                                onChange={(e) => {
                                                    setHomeInsurance(e.target.value === "" ? "" : e.target.value)
                                                }}
                                                style={{ border: "none" }}
                                                disabled={!taxes}
                                            />
                                        </InputGroup>
                                        {/* <NumericalInputSimple
                        size="md"
                        value={homeInsurance}
                        type="number"
                        onChange={(e) => {
                          setHomeInsurance(e.target.value === "" ? 0 : parseFloat(e.target.value));
                        }}
                        min={0}
                      /> */}
                                    </Form.Group>
                                </Col>
                                <Col xs={6} style={{ paddingLeft: "0px" }}>
                                    <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                        Property Tax
                                        <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                    </Form.Label>

                                    <InputGroup style={{ border: '1px solid #CFD4D9', borderRadius: '4px' }}>
                                        <Form.Control
                                            className="affordability-form-inputs"
                                            placeholder={propertyTax}
                                            value={propertyTax}
                                            onChange={(e) => {
                                                setPropertyTax(e.target.value === "" ? "" : e.target.value)
                                            }}
                                            style={{ border: "none" }}
                                            disabled={!taxes}
                                            step="0.001"
                                        />
                                        {Object.keys(possiblePayments).length > 0 && (
                                            <InputGroup.Text style={{ border: "none", backgroundColor: '#FFF' }}>
                                                %
                                            </InputGroup.Text>
                                        )}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={6} style={{ paddingLeft: "0px" }}>
                                    <Form.Group className="affordabilityInput-pc" style={{ marginBottom: '24px' }}>
                                        <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                            HOA fee
                                            <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                        </Form.Label>
                                        <InputGroup style={{ border: '1px solid #CFD4D9', borderRadius: '4px' }}>
                                            <InputGroup.Text style={{ border: "none", backgroundColor: '#FFF' }}>
                                                $
                                            </InputGroup.Text>
                                            <Form.Control
                                                className="affordability-form-inputs"
                                                placeholder={hoaDUE}
                                                value={hoaDUE}
                                                onChange={(e) => {
                                                    setHOADue(e.target.value === "" ? "" : e.target.value)
                                                }}
                                                style={{ border: "none" }}
                                                disabled={!taxes}
                                            />
                                        </InputGroup>
                                        {/* <NumericalInputSimple
                        size="md"
                        value={hoaDUE}
                        type="number"
                        onChange={(e) => {
                          setHOADue(e.target.value === "" ? 0 : parseFloat(e.target.value));
                        }}
                        min={0}
                      /> */}
                                    </Form.Group>
                                </Col>
                                <Col xs={6} style={{ paddingLeft: "0px" }}>
                                    <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                        PMI
                                        <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                    </Form.Label>

                                    <InputGroup style={{ border: '1px solid #CFD4D9', borderRadius: '4px' }}>
                                        <Form.Control
                                            className="affordability-form-inputs"
                                            placeholder={pmi}
                                            value={pmi}
                                            onChange={(e) => {
                                                setPmi(e.target.value === "" ? "" : e.target.value)
                                            }}
                                            style={{ border: "none" }}
                                            disabled={!taxes}
                                        />
                                        {Object.keys(possiblePayments).length > 0 && (
                                            <InputGroup.Text style={{ border: "none", backgroundColor: '#FFF' }}>
                                                %
                                            </InputGroup.Text>
                                        )}
                                    </InputGroup>
                                </Col>
                            </Row>
                        </>
                        }
                    </Form>
                </div>
                <div className="affordabilityForm mx-3">
                    <div className="affordabilityFormTitle1 mt-3">
                        Mortgage Information
                    </div>
                    <Form.Row className="mt-2">
                        <Col xs={12} >
                            <Form.Group className="inputSection">
                                <Form.Label className="affordabilityInputLabel">
                                    Property Usage
                                </Form.Label>
                                <CheckGroup data={PropertyUsageList} onChangeSelect={(e) => setOccupancyType(e)} value={occupancyType} type='radio' />
                            </Form.Group>
                        </Col>
                        {!forMobileHome && (
                            <Col xs={12} >
                                <Form.Group className="inputSection">
                                <Form.Label className="affordabilityInputLabel">
                                    Property Type
                                </Form.Label>
                                <CheckGroup data={PropertyTypeList} onChangeSelect={(e) => setPropertyType(e)} value={propertyType} type='radio' />
                                </Form.Group>
                            </Col>
                        )}

                        {propertyType === "multifamily" && (
                            <Col xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label className="affordabilityInputLabel">
                                        Units
                                    </Form.Label>
                                    <div>
                                        <Select

                                            defaultValue={2}
                                            value={unitNumber}
                                            onChange={(e) => {
                                                setUnitNumber(parseInt(e))
                                            }}
                                            style={{ width: '100%', height: 44 }}>

                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                        </Select>

                                    </div>
                                    {/* <Form.Control
                                        size="sm"
                                        as="select"
                                        value={unitNumber}
                                        defaultValue={2}
                                        onChange={(e) => {
                                            setUnitNumber(parseInt(e.target.value))
                                        }}
                                    >
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                    </Form.Control> */}
                                </Form.Group>
                            </Col>
                        )}
                    </Form.Row>

                    <div className="affordabilityFormTitle1">Basic Information</div>
                    <Form>
                        <Form.Row className="mt-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="affordabilityInputLabel">
                                        Annual Gross Income
                                    </Form.Label>
                                    <ScrollBar max={3000000} min={1} step={1000} initVal={termIncome} scrollChangeValue={changeScrollValue} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Col>
                                <Form.Group >
                                    <Form.Label className="affordabilityInputLabel">
                                        Monthly Debt
                                    </Form.Label>
                                    <ScrollBar max={getIncome() * 0.6} min={1} step={100} initVal={monthlyDebt} scrollChangeValue={changeScrollValue1} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs style={{ minWidth: "100px" }}>
                                <Form.Group className="inputSection">
                                    <div style={{ display: 'flex', alignItems: 'top' }}>
                                        <Form.Label className="affordabilityInputLabel" style={{ marginRight: '5px' }}>
                                            FICO Score
                                        </Form.Label>
                                        <OverlayTrig placement="right" overlay={<Tooltip id='fico-tip'>
                                            Your FICO score, similar to a credit score, is a three-digit number indicating your creditworthiness, typically falling between 300 and 850. It plays an important role in determining the loan terms and interest rates you may be eligible for. You have several options for obtaining your FICO score, including checking your credit card statements, requesting it from major credit reporting agencies, or utilizing credit monitoring services
                                        </Tooltip>}>

                                            <img
                                                src="/images/tooltipBulb.png"
                                                style={{ width: "12px", height: "15px", marginLeft: "5px", marginBottom: "5px" }}
                                                alt=''
                                            />
                                        </OverlayTrig>
                                    </div>
                                    <div>
                                        <Select

                                            defaultValue={2}
                                            value={creditScore}
                                            onChange={(e) => {
                                                changeCreditScore(e)
                                            }}
                                            style={{ width: '100%', height: 44 }}>

                                            <option value="760">740+</option>
                                            <option value="740">721-740</option>
                                            <option value="720">701-720</option>
                                            <option value="700">681-700</option>
                                            <option value="680">661-680</option>
                                            <option value="660">641-660</option>
                                            <option value="640">621-640</option>
                                            <option value="620">Less than 621</option>
                                        </Select>

                                    </div>
                                    {/* <Form.Control
                                        style={{ height: '2.75rem' }}
                                        as="select"
                                        value={creditScore}
                                        onChange={(e) => {
                                            changeCreditScore(e.target.value)
                                        }}
                                    >
                                        <option value="760">740+</option>
                                        <option value="740">721-740</option>
                                        <option value="720">701-720</option>
                                        <option value="700">681-700</option>
                                        <option value="680">661-680</option>
                                        <option value="660">641-660</option>
                                        <option value="640">621-640</option>
                                        <option value="620">Less than 621</option>
                                    </Form.Control> */}
                                </Form.Group>
                            </Col>
                        </Form.Row>


                        <div className="affordabilityFormTitle1 mt-3">Loan Information</div>
                        <Form.Row className="mt-3">
                            <Col xs style={{ minWidth: "200px" }}>
                                <Form.Group className="inputSection">
                                    <Form.Label className="affordabilityInputLabel">
                                        Down Payment
                                    </Form.Label>
                                    <ScrollBar max={2000000} min={0} step={1000} initVal={downPrice} scrollChangeValue={changeScrollValue2} />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Row>
                            <Form.Group className="inputSection" style={{ marginBottom: '15px' }}>
                                <Form.Label className="affordabilityInputLabel">
                                    Loan Term
                                </Form.Label>
                                <Row >
                                    {['30', '25', '20', '15', '10'].map(term => (
                                        <Col xs={12} md={4} className="radio-group-box affordability-form-inputs" key={term} onClick={() => setLoanTerm(term)} style={{ border: loanTerm === term ? '1px solid #325CEB' : "" }}>
                                            <div className="radioCircle" style={{ background: loanTerm == term ? "#325CEB" : "" }}></div>
                                            <div style={{ marginLeft: "10px" }}>{term} year</div>
                                        </Col>
                                    ))}
                                </Row>
                            </Form.Group>
                        </Row>
                        <Form.Row>
                            <Col xs style={{ minWidth: "150px" }}>
                                <Form.Group className="inputSection">
                                    <Form.Label className="affordabilityInputLabel">
                                        Estimated Rate:
                                    </Form.Label>
                                    <InputGroup className="affordability-form-inputs" style={{ border: '1px solid #CFD4D9', borderRadius: '4px' }}>
                                        <Form.Control
                                            type="text"
                                            value={baseRate}
                                            placeholder={baseRate}
                                            onChange={(e) => {
                                                setBaseRate(e.target.value)
                                            }}
                                            onBlur={() => {
                                                // Format the value to two decimal places when the input loses focus
                                                setBaseRate(Number(baseRate).toFixed(2));
                                            }}
                                            style={{ border: "none" }}
                                        />
                                        <InputGroup.Text style={{ border: 'none', backgroundColor: '#FAFAFA !important' }}>
                                            {" "}
                                            %
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="affordabilityFormTitle1 mt-3">
                            Taxes & Insurance
                            <Switch className="taxses-switch" checkedText="Include" uncheckedText="Exclude" checked={taxes} onChange={switchChange} />
                        </div>
                        {
                            taxes && (
                                <>
                                    <Form.Row className="mt-2">
                                        <Col>
                                            <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                                <div>Property Tax</div>
                                                <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                            </Form.Label>

                                            <InputGroup style={{ border: '1px solid #CFD4D9' }} className="mb-3">
                                                <Form.Control
                                                    className="fee-control"
                                                    placeholder={propertyTax}
                                                    value={propertyTax}
                                                    onChange={(e) => {
                                                        setPropertyTax(e.target.value === "" ? "" : e.target.value)
                                                    }}
                                                    style={{ border: 'none' }}
                                                    step="0.001"
                                                />
                                                {Object.keys(possiblePayments).length > 0 && (
                                                    <InputGroup.Text style={{ border: 'none', backgroundColor: '#FFF' }}>
                                                        {" "}
                                                        {/* / $
                          {((curPrice.loanAmount * propertyTax * 0.01) / 12).toFixed(
                            0
                          )} */}
                                                        %
                                                    </InputGroup.Text>
                                                )}
                                            </InputGroup>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col xs={12}>
                                            <Form.Group>
                                                <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                                    <div>Homeowners insurance</div>
                                                    <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                                </Form.Label>
                                                <NumericalInputSimple
                                                    size="sm"
                                                    value={homeInsurance}
                                                    type="number"
                                                    onChange={(e) => {
                                                        setHomeInsurance(e.target.value === "" ? "" : e.target.value)
                                                    }}
                                                    min={0}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group>
                                                <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                                    <div>HOA fees</div>
                                                    <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                                </Form.Label>
                                                <NumericalInputSimple
                                                    size="sm"
                                                    value={hoaDUE}
                                                    type="number"
                                                    onChange={(e) => {
                                                        setHOADue(e.target.value === "" ? "" : e.target.value)
                                                    }}
                                                    min={0}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Label style={{ justifyContent: "space-between" }} className="affordabilityInputLabel">
                                                <div>PMI</div>
                                                <div style={{ fontWeight: "400", color: "#8A8C90" }}>/year</div>
                                            </Form.Label>

                                            <InputGroup style={{ border: '1px solid #CFD4D9' }} className="mb-3">
                                                <Form.Control
                                                    className="fee-control"
                                                    placeholder={pmi}
                                                    value={pmi}
                                                    onChange={(e) => {
                                                        setPmi(e.target.value === "" ? "" : e.target.value)
                                                    }}
                                                    style={{ border: 'none' }}
                                                />
                                                {Object.keys(possiblePayments).length > 0 && (
                                                    <InputGroup.Text style={{ border: 'none', backgroundColor: '#FFF' }}>
                                                        {" "}
                                                        {/* / $
                          {((curPrice.loanAmount * propertyTax * 0.01) / 12).toFixed(
                            0
                          )} */}
                                                        %
                                                    </InputGroup.Text>
                                                )}
                                            </InputGroup>
                                        </Col>
                                    </Form.Row>
                                </>
                            )
                        }
                        {/* <Form.Row>
            <Col>
              <Form.Label style={{ color: '#6E6E70' }}>
                We find current rates as reference
              </Form.Label>

              <InputGroup className="mb-3">
                <ExchangeCalc getChangeValue={getChangeChildValue} closingCostValue={100} step={2} changeOpen={() => changeStatus(1)} open={statusF} title="30 Year Fixed" />
              </InputGroup>
              <InputGroup className="mb-3">
                <ExchangeCalc getChangeValue={getChangeChildValue1} closingCostValue={1000} step={1} changeOpen={() => changeStatus(2)} open={statusS} title="5/6 ARM" />
              </InputGroup>
              <InputGroup style={{ marginBottom: '2rem' }}>
                <Button variant="light" block className="check-more">Check more
                  <img
                    src="/images/checkMore.svg"
                    style={{ marginLeft: '0.5rem', cursor: 'pointer', width: '1.2rem', height: '1.2rem' }}
                    alt="checkMore"
                  />
                </Button>
              </InputGroup>
            </Col>
          </Form.Row> */}
                    </Form>
                    {/* <div className="preapprvoal-wrap">
          <div className="title">You've estimated your affordability.</div>
          <div className="sub-title">What’s next?</div>
          <div className="tips">Get pre-qualified by a lender to find out just how much you can borrow.</div>
          <Button variant="primarys" block className="btn">Apply for preapprvoal</Button>
        </div> */}
                </div>
            </div>
        </div>
    )
}

const calculateDimensions = (breakPoints, viewport) => {
    let w, h
    if (breakPoints.xl) {
        w = 1000
        h = 800
    } else if (breakPoints.lg) {
        w = 800
        h = 600
    } else if (breakPoints.md) {
        w = 600
        h = 400
    } else if (breakPoints.sm || breakPoints.xs) {
        w = Math.round(viewport.width * 0.95)
        h = 300
    }
    return { width: w, height: h }
}

function Affordability(props) {
    window.drift.hide()
    const breakPoints = useBreakPoints()
    const viewport = useViewport()
    const { width, height } = calculateDimensions(breakPoints, viewport)
    const [loaded, setLoaded] = useState(false)
    const checkMobile = window.innerWidth < 576
    // const [customerUrl, setCustomerUrl] = useState("https://app.zeitro.com");
    customerid = props.match.params["customerid"]
    if (typeof customerid === "undefined") {
        customerid = props.customerid
    }
    loid = props.match.params["loid"]
    let forDisplayOnly = typeof props.forDisplayOnly === "undefined" ? false : true
    if (typeof loid === "undefined") {
        loid = props.loid
    }

    const fetchData = () =>
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    
                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                loanLimits = res
                setLoaded(true)
            })
            .catch((err) => {
                console.error(err)
                setLoaded(false)
            })
    const getCustomerUrl = () => {
        fetch("/data/customerurls/" + customerid)
            .then((res) => {
                if (!res.ok) {
                    
                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                if (window.location.hostname.includes("localhost")) {
                    customerUrl = "http://localhost:3000"
                }
                else {
                    customerUrl = res.Prodapp
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        fetchData()
        getCustomerUrl()
    }, [])

    const ref = useRef()

    useEffect(() => {
        const myObserver = new ResizeObserver((entries) => {
            if (entries.length > 0) {
                let height = entries[0].contentRect.height
                let width = entries[0].contentRect.width
                window.parent.postMessage(
                    { source: "affordability", payload: [width, height + 39] },
                    "*"
                )
                // console.log("width:" + width + ", height: " + height);
            }
        })
        if (ref.current) myObserver.observe(ref.current)
    })
    const sc = `window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '427721916fe6f50a14f454bbf5578f31c92fd546', { region: 'eu' });
    smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
    `
    return (
        <Router>
            {!window.location.hostname.includes("localhost") && <Helmet>
                <script type='text/javascript'>
                    {sc}
                </script>
            </Helmet>}
            {!loaded ? (
                <Container>
                    <img
                        className="loader"
                        alt="spinner"
                        src={logo ? `data:image/png;base64, ${logo}` : "/logo.png"}
                    ></img>
                </Container>
            ) : (
                <div
                    ref={ref}
                    className="App py-0 my-0 mx-0 px-0 pb-0"
                    style={{ textAlign: "left", borderTop: "none" }}
                >
                    <div className="navigation-bolck"></div>
                    <div id="contain" width={width} height={height}>

                        <AffordabilityComponent checkMobile={checkMobile} {...props} />
                    </div>
                </div>
            )}
        </Router>
    )
}

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    )

    useEffect(() => {
        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    return isIntersecting
}


function isInViewPortOfOne(el) {
    // viewPortHeight 兼容所有浏览器写法
    const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    const offsetTop = el.offsetTop
    const scrollTop = document.documentElement.scrollTop
    const top = offsetTop - scrollTop
    return top <= viewPortHeight
}

export default withRouter(Affordability)
