import {
    CHANGE_APPLICATION, CHANGE_APPLICATION_BY_PATH, CHANGE_BORROWER_INFO,CHANGE_HAS_COBORROWER, CHANGE_COHABITING, 
    CHANGE_NONQM_INFO, CHANGE_BROKER_INFO,
    CHANGE_OCCUPATION_INFO, CHANGE_PAST_OCCUPATION_INFO,
    CLEAR_ADDITIONAL_OCCUPATIONS, ADD_ADDITIONAL_OCCUPATION, REMOVE_ADDITIONAL_OCCUPATION,
     CHANGE_JOINTLY,CHANGE_FINANCING_SAMEPROPERTY, CHANGE_APPLICATION_ATTRIBUTE,CHANGE_BORROWER_ATTRIBUTE,
     CHANGE_HARDMONEYLOAN_ATTRIBUTE,
    ADD_ADDITIONAL_INCOME, REMOVE_ADDITIONAL_INCOME, CHANGE_ADDITIONAL_INCOME, HAS_ADDITIONAL_INCOME,
    CLEAR_ADDITIONAL_INCOME, CHANGE_INCOME_MONTHLY, CHANGE_INCOME_ATTRIBUTE, CHANGE_EXPENSES, CHANGE_FINANCES, 
    ADD_BORROWER_BANKACCOUNT, BULKADD_BORROWER_BANKACCOUNTS, CLEAR_BORROWER_BANKACCOUNTS, REMOVE_BORROWER_BANKACCOUNT, CHANGE_BORROWER_BANKNAME,
    CHANGE_BORROWER_BANKACCOUNT,CHANGE_BORROWER_BANKBALANCE, CHANGE_BORROWER_BANKATTRIBUTION,
    ADD_BORROWER_BROKERACCOUNT, CLEAR_BORROWER_BROKERACCOUNTS,CHANGE_BORROWER_BANKACCOUNTTYPE,
    REMOVE_BORROWER_BROKERACCOUNT, CHANGE_BORROWER_BROKERNAME, CHANGE_BORROWER_BROKERACCOUNT,
    CHANGE_BORROWER_BROKERBALANCE,  CHANGE_BORROWER_BROKERATTRIBUTION, CHANGE_BORROWER_BROKERALLOCATION, 
    CHANGE_BORROWER_BROKERTAXSTATUS,
    ADD_BORROWER_AUTO, REMOVE_BORROWER_AUTO, 
    CLEAR_BORROWER_AUTOS, CHANGE_BORROWER_AUTO_MAKE, CHANGE_BORROWER_AUTO_YEAR, CHANGE_BORROWER_AUTO_VALUE, 
    ADD_BORROWER_OTHER_ASSET, BULKADD_BORROWER_OTHER_ASSETS, REMOVE_BORROWER_OTHER_ASSET, CLEAR_BORROWER_OTHER_ASSETS,
    CHANGE_BORROWER_OTHER_ASSET_NAME, CHANGE_BORROWER_OTHER_ASSET_VALUE,CHANGE_BORROWER_OTHER_ASSET_ATTRIBUTION,CHANGE_BORROWER_OTHER_ASSET,
    ADD_BORROWER_LOAN, BULKADD_BORROWER_LOANS, REMOVE_BORROWER_LOAN, CLEAR_BORROWER_LOANS, CHANGE_BORROWER_LOAN_COMPANY, 
    CHANGE_BORROWER_LOAN_ADDRESS, CHANGE_BORROWER_LOAN_ACCOUNT, CHANGE_BORROWER_LOAN_MONTHLY, 
    CHANGE_BORROWER_LOAN_REMAINING, CHANGE_BORROWER_LOAN_SATISFIEDUPON, CHANGE_BORROWER_LOAN_FORSUBJECT, CHANGE_BORROWER_LOAN,
    CHANGE_BORROWER_LOAN_CORRECTION,CHANGE_BORROWER_LOAN_EXPLANATION, CHANGE_BORROWER_LOAN_RATE,
    BULKADD_BORROWER_OWNED_PROPERTIES,ADD_OWNED_PROPERTY, ADD_BORROWER_OWNED_PROPERTY, ADD_COBORROWER_OWNED_PROPERTY,PREPEND_OWNED_PROPERTY,PREPEND_BORROWER_OWNED_PROPERTY,PREPEND_COBORROWER_OWNED_PROPERTY,REMOVE_OWNED_PROPERTY, REMOVE_BORROWER_OWNED_PROPERTY, REMOVE_COBORROWER_OWNED_PROPERTY,CLEAR_OWNED_PROPERTY, CLEAR_BORROWER_OWNED_PROPERTY, CLEAR_COBORROWER_OWNED_PROPERTY,CHANGE_OWNED_PROPERTY, CHANGE_BORROWER_OWNED_PROPERTY, CHANGE_COBORROWER_OWNED_PROPERTY,
    CHANGE_BORROWER_LOAN_MONTHSLEFTTOPAY,ADD_BORROWER_ADDITIONAL_PROPERTY, REMOVE_BORROWER_ADDITIONAL_PROPERTY,
    CLEAR_BORROWER_ADDITIONAL_PROPERTY, CHANGE_ADDITIONAL_PROPERTY,CHANGE_BORROWER_ADDITIONAL_PROPERTY_ADDRESS,
    CHANGE_BORROWER_ADDITIONAL_PROPERTY_STATUS, CHANGE_BORROWER_ADDITIONAL_PROPERTY_TYPE,CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTE,
    CHANGE_BORROWER_ADDITIONAL_PROPERTY_VALUE, CHANGE_BORROWER_ADDITIONAL_PROPERTY_LIENS,
    CHANGE_BORROWER_ADDITIONAL_PROPERTY_GROSSINCOME, CHANGE_BORROWER_ADDITIONAL_PROPERTY_PAYMENT,
    CHANGE_BORROWER_ADDITIONAL_PROPERTY_EXPENSES, CHANGE_BORROWER_ADDITIONAL_PROPERTY_NETINCOME,CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTION,
    ADD_BORROWER_ADDITIONAL_CREDITS, REMOVE_BORROWER_ADDITIONAL_CREDITS, CLEAR_BORROWER_ADDITIONAL_CREDITS, 
    CHANGE_BORROWER_ADDITIONAL_CREDIT_ALTNAME, CHANGE_BORROWER_ADDITIONAL_CREDIT_CREDITOR, CHANGE_BORROWER_FREEANDCLEAR, CHANGE_REVIEWED_LOANS,
    CHANGE_BORROWER_ADDITIONAL_CREDIT_ACCOUNT,CHANGE_PROPERTY_ADDRESS, CHANGE_PROPERTY_DESCRIPTION, CHANGE_PROPERTY_OCCUPANCY,
    CHANGE_PROPERTY_PURPOSE, CHANGE_PROPERTY_APPRAISAL, CHANGE_PROPERTY_SALESPRICE, CHANGE_PROPERTY_ATTRIBUTE, 
    CHANGE_PROPERTY_UNITS, CHANGE_PROPERTY_YEARBUILT,
    CHANGE_PROPERTY_INWHATNAME, CHANGE_PROPERTY_INWHATMANNER, CHANGE_PROPERTY_ESTATEHELDIN,
    CHANGE_PROPERTY_SOURCES, CHANGE_PROPERTY_REFINANCE_YEARACQUIRED, CHANGE_PROPERTY_REFINANCE_ORIGINALCOST,
    CHANGE_PROPERTY_REFINANCE_PURPOSE, CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSDESC, CHANGE_PROPERTY_REFINANCE_ATTRIBUTE,
    CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSSTATUS, CHANGE_PROPERTY_REFINANCE_COST,CHANGE_PROPERTY_REFINANCE_EXPLAINOTHER,
    CHANGE_PROPERTY_CONSTRUCTION_YEARACQUIRED, CHANGE_PROPERTY_CONSTRUCTION_ORIGINALCOST,
    CHANGE_PROPERTY_CONSTRUCTION_EXISTINGLIENS, CHANGE_PROPERTY_CONSTRUCTION_PRESENTVALUE,
    CHANGE_PROPERTY_CONSTRUCTION_COSTOFIMPROVEMENTS,CHANGE_PROPERTY_REFINANCE_EXISTINGLIENS,
    CHANGE_DECLARATIONS, CHANGE_MAINPROPERTY_EXPIRATION, CHANGE_EQUAL_OPPORTUNITY, CHANGE_EQUAL_OPPORTUNITY_SUB,
    CHANGE_SELECTION_FIELD, CHANGE_SELECTEDLOAN_FIELD, CHANGE_SELECTEDLOAN_PRODUCT_FIELD, CHANGE_PREVIOUS_ADDRESS, ADD_PREVIOUS_ADDRESS, 
    ADD_SELECTEDLOAN_SERVICE , REMOVE_SELECTEDLOAN_SERVICE, ADD_SELECTEDLOAN_CHARGE, REMOVE_SELECTEDLOAN_CHARGE,
    ADD_SELECTEDLOAN_ESTIMATEDCLOSING_COSTS,
    REMOVE_PREVIOUS_ADDRESS, CLEAR_PREVIOUS_ADDRESS, CHANGE_MAINPROPERTY_PROPERTYTYPE, CHANGE_MAINPROPERTY,CHANGE_MOBILE_HOME_INFO, COPY_BORROWER_LOAN,
    ADD_BORROWER_ALIMONY, BULKADD_BORROWER_ALIMONYS, REMOVE_BORROWER_ALIMONY, CLEAR_BORROWER_ALIMONY, 
    CHANGE_BORROWER_ALIMONY_AMOUNT, CHANGE_BORROWER_ALIMONY, CHANGE_BORROWER_ALIMONY_OWEDTO, CHANGE_BORROWER_ALIMONY_TYPE,
    CHANGE_BORROWER_CLEAR_INSURANCE, CHANGE_BORROWER_DELETE_INSURANCE, 
    CHANGE_BORROWER_ADD_INSURANCE, CHANGE_BORROWER_CHANGE_INSURANCE,
    ADD_REMOTE_COBORROWER, REMOVE_REMOTE_COBORROWER, CLEAR_REMOTE_COBORROWER, 
    CHANGE_REMOTE_COBORROWER_FIRSTNAME, CHANGE_REMOTE_COBORROWER_LASTNAME, CHANGE_REMOTE_COBORROWER_EMAIL,
    CHANGE_REMOTE_COBORROWER_STATUS,CHANGE_REMOTE_COBORROWER_USE, CHANGE_SOLAR_PANELS,
    ADD_BORROWER_OTHER_LIEN, REMOVE_BORROWER_OTHER_LIEN, CLEAR_BORROWER_OTHER_LIENS,
    CHANGE_BORROWER_OTHER_LIEN_VALUE, CHANGE_CHOSENLOAN_ATTRIBUTE,
    ADD_BORROWER_OTHER_OCCUPATION, REMOVE_BORROWER_OTHER_OCCUPATION, CLEAR_BORROWER_OTHER_OCCUPATIONS,
    CHANGE_BORROWER_OTHER_OCCUPATION_VALUE, UPDATE_FICO, UPDATE_CR_ERRORS, CLEAR_CR_ERRORS,
    ADD_BORROWER_PREVIOUS_OCCUPATION, REMOVE_BORROWER_PREVIOUS_OCCUPATION, CLEAR_BORROWER_PREVIOUS_OCCUPATIONS, 
    CHANGE_BORROWER_PREVIOUS_OCCUPATION_INCOME, CHANGE_BORROWER_PREVIOUS_OCCUPATION_VALUE,
    CHANGE_BORROWER_OTHER_OCCUPATION_INCOME,
    UPDATE_CANNOTSHOPSERVICES, UPDATE_CANSHOPSERVICES, UPDATE_FEES, UPDATE_ORIGINALCOSTS, UPDATE_OTHERCOSTS,
    UPDATE_PAYMENT_PERIOD, UPDATE_PAYMENT_AMOUNT, ADD_SELECTEDLOAN_PREPAID, REMOVE_SELECTEDLOAN_PREPAID,
    UPDATE_PREPAID_DEFAULT, ADD_BORROWER_GIFT,BULKADD_BORROWER_GIFTS, CLEAR_BORROWER_GIFT, CHANGE_BORROWER_GIFT, REMOVE_BORROWER_GIFT,
    ADD_BORROWER_ADDITIONAL_PROPERTIES_CREDIT, ADD_BORROWER_ADDITIONAL_PROPERTIES_ATTRIBUTES,
    ADD_NEW_MORTGAGE_LOAN,BULKADD_NEW_MORTGAGE_LOAN, REMOVE_NEW_MORTGAGE_LOAN, CLEAR_NEW_MORTGAGE_LOANS, UPDATE_NEW_MORTGAGE_ATTRIBUTE,
    UPDATE_ESCROW_INFO, UPDATE_APPRAISAL_INFO


} from '../Defs/interviewdefs'


import {CHANGE_PRODUCT, CHANGE_TRANSACTION} from '../Defs/loinput'
import {previousoccupation, otherincome, account, otheroccupation,
    automobile, otherasset, loan, property, creditunderothername, application, allocation,
    alimony, address, insurance, remotecoborrower, otherlien, charge, service, payment, gift, newmortgage, OwnedProperty, escrowinfo, appraisalinfo} from '../State.js'
const cloneDeep = require('lodash/cloneDeep');

const applicationInitialState = new application()
const applicationInitialStateJSON = JSON.parse( applicationInitialState.toJson() )

function applicationReducer(state = applicationInitialStateJSON, action) {

    state = application.fromJson(state)
    switch (action.type) {
        case CHANGE_APPLICATION:
            //this one IS TRICKY!  
            state =  application.fromJson( action.payload ); 
            break;
        case CHANGE_APPLICATION_BY_PATH:
            let paths = action.path.split('.')
            let obj = state
            for(let i = 0; i < paths.length; i++) {
                if(i === paths.length - 1) {
                    obj[paths[i]] = action.value
                } else {
                    obj = obj[paths[i]]
                }
            }
            break;

        case UPDATE_FICO:
            state.scre = parseInt(action.payload)
            break;
        case UPDATE_CR_ERRORS: {
            state.assetsandliabilities.warnings = action.payload
            break
        }
        case CLEAR_CR_ERRORS:
            state.assetsandliabilities.warnings = null
            break
        case CHANGE_SELECTION_FIELD:
            state.selection[action.verb] = action.payload
            break;
        case CHANGE_SELECTEDLOAN_FIELD:
            state.selection.selectedloan[action.verb] = action.payload
            break;
        case CHANGE_SELECTEDLOAN_PRODUCT_FIELD: {
            
            state.selection.selectedloan.matchingproducts[action.index][action.verb] = action.payload

            break;
        }
        case CHANGE_HAS_COBORROWER:
            state.hascoborrower = action.payload; break;
        case CHANGE_BORROWER_INFO: {
            let obj = state[action.who] 
            obj[action.verb] = action.payload
            break;
        }
        case CHANGE_APPLICATION_ATTRIBUTE : {
            let obj = state

            obj[action.verb] =  action.payload
            break;
        }
        case CHANGE_HARDMONEYLOAN_ATTRIBUTE : {
            let obj = state["hardmoneyloan"]
            obj[action.verb] =  action.payload
            break;
        }
        case CHANGE_BORROWER_ATTRIBUTE: {
            let obj = state[action.who]
            obj[action.verb] =  action.payload
            break;
        }
        case CHANGE_CHOSENLOAN_ATTRIBUTE: {
            let obj = state.selection.selectedloan
            obj[action.verb] =  action.payload
            break;
        }
        case CHANGE_COHABITING:
            state.cohabiting = action.payload; break;
        case CHANGE_FINANCING_SAMEPROPERTY:
            state.financingsameproperty = action.payload; break;
        case CHANGE_JOINTLY:
            state.assetsliabilitiesjointly = action.payload; break;
        case CHANGE_FINANCES: {
            state[action.who].finances[action.verb] = action.payload;
            break;
        }
        case CHANGE_NONQM_INFO: {
            let obj = state[action.who].nonqm
            obj[action.verb] = action.payload
            break
        }
        case CHANGE_OCCUPATION_INFO: {
            let obj = state[action.who].occupation
            obj[action.verb] = action.payload
            break;
        }
        case CHANGE_PAST_OCCUPATION_INFO: {
            let obj = state[action.who].previousoccupations[action.index]
            obj[action.verb] = action.payload
            break;
        }
        case CLEAR_ADDITIONAL_OCCUPATIONS: {
            let obj = state[action.who]
            obj.previousoccupations = []
            break;
        }
        case ADD_ADDITIONAL_OCCUPATION: {
            let obj = state[action.who]
            if(obj.previousoccupations === null)
                obj.previousoccupations = []
            let add = new previousoccupation()
            add.key = Math.random().toString() 
            obj.previousoccupations.push(add)
            break;
        }
        case REMOVE_ADDITIONAL_OCCUPATION: {
            let obj = state[action.who]
            obj.previousoccupations.splice(parseInt(action.index), 1)
            obj.previousoccupations= cloneDeep(obj.previousoccupations)
            break;
        }         

        case CHANGE_PREVIOUS_ADDRESS : {
            let obj = state[action.who].previousaddresses[action.index]
            obj[action.verb] = action.payload        
            break    
        }
        case CLEAR_PREVIOUS_ADDRESS: {
            let obj = state[action.who]
            obj.previousaddresses = []
            break;
        }
        case ADD_PREVIOUS_ADDRESS: {
            let obj = state[action.who]
            let add = new address()
            add.key = Math.random().toString() 
            obj.previousaddresses.push(add)
            break;
        }
        case REMOVE_PREVIOUS_ADDRESS: {
            let obj = state[action.who]
            obj.previousaddresses.splice(parseInt(action.index), 1)
            obj.previousaddresses= cloneDeep(obj.previousaddresses)
            break;
        }         
        case CHANGE_INCOME_MONTHLY: {
            let obj = state[action.who].occupation
            obj.income[action.verb] = action.payload
            break;
        }
        case CHANGE_INCOME_ATTRIBUTE : {
            let obj = state[action.who]
            obj.income[action.verb] = action.payload            
            break;
        }
        case CLEAR_ADDITIONAL_INCOME: {
            let obj = state[action.who].income
            obj.otherincome = []
            break;
        }
        case ADD_ADDITIONAL_INCOME: {
            let obj = state[action.who].income
            if(obj.otherincome === null)
                obj.otherincome = []
            let add = new otherincome()
            add.key = Math.random().toString() 
            obj.otherincome.push(add)
            break;
        }
        case HAS_ADDITIONAL_INCOME: {
            let obj = state[action.who].income
            obj.hasotherincome = action.payload
            break;
        }
        case REMOVE_ADDITIONAL_INCOME: {
            let obj = state[action.who].income
            obj.otherincome.splice(parseInt(action.payload), 1)
            state.borrower.income.otherincome = cloneDeep(obj.otherincome)
            break;
        }
        case CHANGE_EXPENSES: {
            let obj = state[action.who].expenses
            obj[action.verb] = action.payload
            break;
        }
        case CHANGE_ADDITIONAL_INCOME: {
            let obj = state[action.who].income
            obj.otherincome[parseInt(action.index)][action.verb] = action.payload
            break;
        }
        case CLEAR_BORROWER_BANKACCOUNTS: {
            let obj = state.assetsandliabilities
            obj.accounts = []
            break;
        }
        case ADD_BORROWER_BANKACCOUNT: {
            let obj = state.assetsandliabilities
            if(null === obj.accounts)
                obj.accounts = []
            let add = new account()
            add.key = Math.random().toString() 
            obj.accounts.push(add)
            break;
        }
        case BULKADD_BORROWER_BANKACCOUNTS: {
            let obj = state.assetsandliabilities
            obj.accounts = action.payload
            break;
        }
        case REMOVE_BORROWER_BANKACCOUNT: {
            let obj = state.assetsandliabilities
            obj.accounts.splice(parseInt(action.payload), 1)
            break;
        }
        case CHANGE_BORROWER_BANKNAME: {
            let obj = state.assetsandliabilities
            obj.accounts[parseInt(action.index)].bankname = action.payload
            break;
        }
        case CHANGE_BORROWER_BANKACCOUNT: {
            let obj = state.assetsandliabilities
            obj.accounts[parseInt(action.index)].account = action.payload
            break;
        }
        case CHANGE_BORROWER_BANKBALANCE: {
            let obj = state.assetsandliabilities
            obj.accounts[parseInt(action.index)].balance = action.payload
            break;
        }
        case CHANGE_BORROWER_BANKACCOUNTTYPE: {
            let obj = state.assetsandliabilities
            obj.accounts[parseInt(action.index)].accounttype = action.payload
            break;
        }
        case CHANGE_BORROWER_BANKATTRIBUTION: {
            let obj = state.assetsandliabilities
            obj.accounts[parseInt(action.index)].attribution = parseInt(action.payload)
            break;
        }
        case CLEAR_BORROWER_BROKERACCOUNTS: {
            let obj = state.assetsandliabilities
            obj.brokerages = []
            break;
        }
        case ADD_BORROWER_BROKERACCOUNT: {
            let obj = state.assetsandliabilities
            if(null === obj.brokerages)
                obj.brokerages = []
            let add = new account()
            add.key = Math.random().toString() 
            add.allocation = new allocation()
            obj.brokerages.push(add)
            break;
        }
        case REMOVE_BORROWER_BROKERACCOUNT: {
            let obj = state.assetsandliabilities
            obj.brokerages.splice(parseInt(action.payload), 1)
            break;
        }
        case CHANGE_BORROWER_BROKERNAME: {
            let obj = state.assetsandliabilities
            obj.brokerages[parseInt(action.index)].bankname = action.payload
            break;
        }
        case CHANGE_BORROWER_BROKERACCOUNT: {
            let obj = state.assetsandliabilities
            obj.brokerages[parseInt(action.index)].account = action.payload
            break;
        }
        case BULKADD_BORROWER_ALIMONYS: {
            let obj = state[action.who].finances
            obj.alimony = action.payload
            break;
        }
        case ADD_BORROWER_ALIMONY: {
            if(null === state[action.who].finances.alimony)
                state[action.who].finances.alimony = []
            let obj = state[action.who].finances
            let add = new alimony()
            add.key = Math.random().toString() 
            obj.alimony.push( add )
            break;
        }
        case REMOVE_BORROWER_ALIMONY: {
            let obj = state[action.who].finances
            obj.alimony.splice(parseInt(action.payload), 1)
            break;
        }
        case CLEAR_BORROWER_ALIMONY: {
            let obj = state[action.who].finances
            obj.alimony = []
            break;
        }
        case CHANGE_BORROWER_ALIMONY_AMOUNT: {
            let obj = state[action.who].finances
            obj.alimony[parseInt(action.index)].amount = action.payload
            break;
        }
        
        case CHANGE_BORROWER_ALIMONY: {          
            let obj = state[action.who].finances
            obj.alimony[parseInt(action.index)][action.verb] = action.payload
            break;
        }

        case CHANGE_BORROWER_ALIMONY_TYPE: {
            let obj = state[action.who].finances
            obj.alimony[parseInt(action.index)].alimonyorsupport = action.payload
            break;
        }

        
        case CHANGE_BORROWER_ALIMONY_OWEDTO: {
            let obj = state[action.who].finances
            obj.alimony[parseInt(action.index)].owedto = action.payload
            break;
        }
 
        case CHANGE_BORROWER_BROKERBALANCE: {
            let obj = state.assetsandliabilities
            obj.brokerages[parseInt(action.index)].balance = action.payload
            break;
        }
        case CHANGE_BORROWER_BROKERATTRIBUTION: {
            let obj = state.assetsandliabilities
            obj.brokerages[parseInt(action.index)].attribution = parseInt(action.payload)
            break;
        }
        case CHANGE_BORROWER_BROKERTAXSTATUS: {
            let obj = state.assetsandliabilities
            obj.brokerages[parseInt(action.index)].isretirement = action.payload
            break;
        }
        case CHANGE_BORROWER_BROKERALLOCATION: {
            let obj = state.assetsandliabilities
            let a = new allocation()
            a.stocks = action.payload[0]
            a.bonds = action.payload[1]
            a.cash = action.payload[2]
            obj.brokerages[parseInt(action.index)].allocation = a
            break;
        }

        case CHANGE_BROKER_INFO: {
            let obj = state.brokerinfo
            obj[action.verb] = action.payload
            break;
        }


        case CHANGE_BORROWER_CLEAR_INSURANCE: {
            let obj = state.assetsandliabilities
            obj.lifeinsurance = []
            break;
        }
        case CHANGE_BORROWER_DELETE_INSURANCE: {
            let obj = state.assetsandliabilities
            obj.lifeinsurance.splice(parseInt(action.payload), 1)
            break;
        }                       
        case CHANGE_BORROWER_ADD_INSURANCE: {
            let obj = state.assetsandliabilities
            if(null === obj.lifeinsurance)
            obj.lifeinsurance = []
            let add = new insurance()
            add.key = Math.random().toString() 
            obj.lifeinsurance.push(add)
            break;                
        }
        case CHANGE_BORROWER_CHANGE_INSURANCE: {
            let obj = state.assetsandliabilities
            obj.lifeinsurance[parseInt(action.index)][action.verb] = action.payload
            break;
        }

        case CLEAR_BORROWER_AUTOS: {
            let obj = state.assetsandliabilities
            obj.automobiles = []
            break;
        }
        case ADD_BORROWER_AUTO: {
            let obj = state.assetsandliabilities
            let add = new automobile()
            add.key = Math.random().toString() 
            obj.automobiles.push( add )
            break;
        }
        case REMOVE_BORROWER_AUTO: {
            let obj = state.assetsandliabilities
            obj.automobiles.splice(parseInt(action.payload), 1)
            break;
        }
        case CHANGE_BORROWER_AUTO_MAKE: {
            let obj = state.assetsandliabilities
            obj.automobiles[parseInt(action.index)].make = action.payload
            break;
        }
        case CHANGE_BORROWER_AUTO_YEAR: {
            let obj = state.assetsandliabilities
            obj.automobiles[parseInt(action.index)].year = action.payload
            break;
        }
        case CHANGE_BORROWER_AUTO_VALUE: {
            let obj = state.assetsandliabilities
            obj.automobiles[parseInt(action.index)].value = action.payload
            break;
        }
        case CLEAR_BORROWER_OTHER_ASSETS: {
            let obj = state.assetsandliabilities
            obj.otherassets = []
            break;
        }
        case ADD_BORROWER_OTHER_ASSET: {
            let obj = state.assetsandliabilities
            if(null === obj.otherassets)
                obj.otherassets = []
            let add = new otherasset()
            add.key = Math.random().toString() 
            obj.otherassets.push(add)
            break;
        }
        case BULKADD_BORROWER_OTHER_ASSETS: {
            let obj = state.assetsandliabilities
            obj.otherassets = action.payload
            break;
        }
        case REMOVE_BORROWER_OTHER_ASSET: {
            let obj = state.assetsandliabilities
            obj.otherassets.splice(parseInt(action.payload), 1)
            break;
        }
        case CHANGE_BORROWER_OTHER_ASSET_NAME: {
            let obj = state.assetsandliabilities
            obj.otherassets[parseInt(action.index)].description = action.payload
            break;
        }
        case CHANGE_BORROWER_OTHER_ASSET_VALUE: {
            let obj = state.assetsandliabilities
            obj.otherassets[parseInt(action.index)].value = action.payload
            break;
        }

        case CHANGE_BORROWER_OTHER_ASSET_ATTRIBUTION: {
            let obj = state.assetsandliabilities
            obj.otherassets[parseInt(action.index)].attribution = action.payload
            break;
        }
        case CHANGE_BORROWER_OTHER_ASSET: {
            let obj = state.assetsandliabilities
            obj.otherassets[parseInt(action.index)][action.verb] = action.payload
            break;
        }        
        
        case ADD_BORROWER_OTHER_LIEN: {
            if(null === state.otherliens) {
                state.otherliens = []
            }
            let obj = state.otherliens
            let add = new otherlien()
            add.key = Math.random().toString() 
            obj.push(add)
            break;
        }    
        case CLEAR_BORROWER_OTHER_LIENS: {
            state.otherliens = []
            break;
        }      
        case REMOVE_BORROWER_OTHER_LIEN: {
            let obj = state.otherliens
            obj.splice(parseInt(action.payload), 1)
            break;
        }          
        case CHANGE_BORROWER_OTHER_LIEN_VALUE: {
            let obj = state.otherliens
            obj[parseInt(action.index)][action.verb]= action.payload
            break;
        }

        case ADD_BORROWER_OTHER_OCCUPATION: {

            if(null === state[action.who].otheroccupations )
            state[action.who].otheroccupations = []
            let obj = state[action.who].otheroccupations
            let add = new otheroccupation()
            add.key = Math.random().toString() 
            obj.push(add)
            break;
        }    
        case CLEAR_BORROWER_OTHER_OCCUPATIONS: {

            state[action.who].otheroccupations = []
            break;
        }      
        case REMOVE_BORROWER_OTHER_OCCUPATION: {
            let obj = state[action.who].otheroccupations
            obj.splice(parseInt(action.payload), 1)
            break;
        }          
        case CHANGE_BORROWER_OTHER_OCCUPATION_VALUE: {
            let obj = state[action.who].otheroccupations
            obj[parseInt(action.index)][action.verb]= action.payload
            break;
        }                
        case CHANGE_BORROWER_OTHER_OCCUPATION_INCOME: {
            let obj = state[action.who].otheroccupations
            obj[parseInt(action.index)].income[action.verb]= action.payload            
            break;
        }

        case ADD_BORROWER_PREVIOUS_OCCUPATION: {

            if(null === state[action.who].previousoccupations )
            state[action.who].previousoccupations = []
            let obj = state[action.who].previousoccupations
            let add = new previousoccupation()
            add.key = Math.random().toString() 
            obj.push(add)
            break;
        }    
        case CLEAR_BORROWER_PREVIOUS_OCCUPATIONS: {

            state[action.who].previousoccupations = []
            break;
        }      
        case REMOVE_BORROWER_PREVIOUS_OCCUPATION: {
            let obj = state[action.who].previousoccupations
            obj.splice(parseInt(action.payload), 1)
            break;
        }          
        case CHANGE_BORROWER_PREVIOUS_OCCUPATION_VALUE: {
            let obj = state[action.who].previousoccupations
            obj[parseInt(action.index)][action.verb]= action.payload
            break;
        }                
        case CHANGE_BORROWER_PREVIOUS_OCCUPATION_INCOME: {
            let obj = state[action.who].previousoccupations
            obj[parseInt(action.index)].income = action.payload            
            break;
        }

        case BULKADD_BORROWER_LOANS: {
            let obj = state.assetsandliabilities
            obj.loans = action.payload
            break;
        }
        case ADD_BORROWER_LOAN: {
            let obj = state.assetsandliabilities
            let add = new loan()
            add.key = Math.random().toString() 
            obj.loans.push( add )
            break;
        }
        case COPY_BORROWER_LOAN: {
            let obj = state.assetsandliabilities
        
            obj.loans.push( action.payload )
            break;
        }        
        case REMOVE_BORROWER_LOAN: {
            let obj = state.assetsandliabilities
            obj.loans.splice(parseInt(action.payload), 1)
            break;
        }
        case CLEAR_BORROWER_LOANS: {
            let obj = state.assetsandliabilities
            obj.loans = []
            break;
        }
        case CHANGE_BORROWER_LOAN: {
            let obj = state.assetsandliabilities.loans
            obj[action.index][action.verb] = action.payload
            break;
        }        
        
        case CHANGE_BORROWER_LOAN_COMPANY: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].name = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_ADDRESS: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].address = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_ACCOUNT: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].accountnumber = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_MONTHLY: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].monthlypayment = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_REMAINING: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].remainingbalance = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_SATISFIEDUPON: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].satisfiedupon = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_RATE: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].rate = action.payload            
            break;
        }
        case CHANGE_BORROWER_LOAN_FORSUBJECT: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].forsubjectproperty = action.payload
            break;
        }

        case CHANGE_BORROWER_LOAN_MONTHSLEFTTOPAY: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].monthslefttopay = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_CORRECTION: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].correction = action.payload
            break;
        }
        case CHANGE_BORROWER_LOAN_EXPLANATION: {
            let obj = state.assetsandliabilities
            obj.loans[parseInt(action.index)].explanation = action.payload
            break;
        }
        case BULKADD_BORROWER_OWNED_PROPERTIES: {
            let obj = state.assetsandliabilities
            obj.properties = action.payload
            break;
        }
        case ADD_OWNED_PROPERTY: {
            let obj = state.assetsandliabilities
            let add = new OwnedProperty()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.properties == null)
                obj.properties = []
            obj.properties.push( add )
            break;
        }
        case ADD_BORROWER_OWNED_PROPERTY: {
            let obj = state.borrower
            let add = new OwnedProperty()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.ownedproperties == null)
                obj.ownedproperties = []
            obj.ownedproperties.push( add )
            break;
        }
        case ADD_COBORROWER_OWNED_PROPERTY: {
            let obj = state.coborrower
            let add = new OwnedProperty()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.ownedproperties == null)
                obj.ownedproperties = []
            obj.ownedproperties.push( add )
            break;
        }
        case PREPEND_OWNED_PROPERTY: {
            let obj = state.assetsandliabilities
            let add = new OwnedProperty()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.ownedproperties == null)
                obj.ownedproperties = []
            obj.ownedproperties.unshift( add )
            break;
        }
        case PREPEND_BORROWER_OWNED_PROPERTY: {
            let obj = state.borrower
            let add = new OwnedProperty()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.ownedproperties == null)
                obj.ownedproperties = []
            obj.ownedproperties.unshift( add )
            break;
        }
        case PREPEND_COBORROWER_OWNED_PROPERTY: {
            let obj = state.coborrower
            let add = new OwnedProperty()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.ownedproperties == null)
                obj.ownedproperties = []
            obj.ownedproperties.unshift( add )
            break;
        }
        case REMOVE_OWNED_PROPERTY: {
            let obj = state.assetsandliabilities
            obj.ownedproperties.splice(parseInt(action.payload), 1)
            break;
        }
        case REMOVE_BORROWER_OWNED_PROPERTY: {
            let obj = state.borrower
            obj.ownedproperties.splice(parseInt(action.payload), 1)
            break;
        }
        case REMOVE_COBORROWER_OWNED_PROPERTY: {
            let obj = state.coborrower
            obj.ownedproperties.splice(parseInt(action.payload), 1)
            break;
        }
        case CLEAR_OWNED_PROPERTY: {
            let obj = state.assetsandliabilities
            obj.ownedproperties = []
            break;
        }
        case CLEAR_BORROWER_OWNED_PROPERTY: {
            let obj = state.borrower
            obj.ownedproperties = []
            break;
        }
        case CLEAR_COBORROWER_OWNED_PROPERTY: {
            let obj = state.coborrower
            obj.ownedproperties = []
            break;
        }
        case CHANGE_OWNED_PROPERTY: {
            let obj = state.assetsandliabilities
            obj.ownedproperties[parseInt(action.index)][action.verb] = action.payload
            break;
        }
        case CHANGE_BORROWER_OWNED_PROPERTY: { 
            let obj = state.borrower
            obj.ownedproperties[parseInt(action.index)][action.verb] = action.payload
            break;
        }
        case CHANGE_COBORROWER_OWNED_PROPERTY: { 
            let obj = state.coborrower
            obj.ownedproperties[parseInt(action.index)][action.verb] = action.payload
            break;
        }
        case ADD_BORROWER_ADDITIONAL_PROPERTY: {
            let obj = state.assetsandliabilities
            let add = new property()
            add.ownedfreeandclear = true
            add.key = Math.random().toString() 
            if(obj.properties == null)
                obj.properties = []
            obj.properties.push( add )
            break;
        }
        case REMOVE_BORROWER_ADDITIONAL_PROPERTY: {
            let obj = state.assetsandliabilities
            obj.properties.splice(parseInt(action.payload), 1)
            break;
        }
        case CLEAR_BORROWER_ADDITIONAL_PROPERTY: {
            let obj = state.assetsandliabilities
            obj.properties = []
            break;
        }
        case CHANGE_ADDITIONAL_PROPERTY: { // catch all
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)][action.verb] = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_ADDRESS: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].address = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_STATUS: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].status = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_TYPE: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].propertytype = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_VALUE: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].marketvalue = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_LIENS: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].liens = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_GROSSINCOME: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].grossrentalincome = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_PAYMENT: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].payment = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_EXPENSES: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].insurancemaintenancetaxes = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_NETINCOME: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].netrentalincome = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTE: {
   
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)][action.verb] = action.value
            break;
        }

        
        case CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTION: {
            let obj = state.assetsandliabilities
            obj.properties[parseInt(action.index)].attribution = parseInt(action.payload)
            break;
        }
        case CHANGE_BORROWER_FREEANDCLEAR: {
            let obj = state.assetsandliabilities
            obj.propertyownedfreeandclear = action.payload
            break;
        }
        case CHANGE_REVIEWED_LOANS: {
            let obj = state.assetsandliabilities
            obj.loansreviewed = action.payload
            break;
        }        
        
        case ADD_BORROWER_ADDITIONAL_CREDITS: {
            let obj = state[action.who].finances
            let add = new creditunderothername()
            add.key = Math.random().toString() 
            obj.creditsunderothername.push( add )
            break;
        }
        case REMOVE_BORROWER_ADDITIONAL_CREDITS: {
            let obj = state[action.who].finances
            obj.creditsunderothername.splice(parseInt(action.payload), 1)
            break;
        }
        case CLEAR_BORROWER_ADDITIONAL_CREDITS: {
            let obj = state[action.who].finances
            obj.creditsunderothername = []
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_CREDIT_ALTNAME: {
            let obj = state[action.who].finances
            obj.creditsunderothername[parseInt(action.index)].altname = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_CREDIT_CREDITOR: {
            let obj = state[action.who].finances
            obj.creditsunderothername[parseInt(action.index)].creditor = action.payload
            break;
        }
        case CHANGE_BORROWER_ADDITIONAL_CREDIT_ACCOUNT: {
            let obj = state[action.who].finances
            obj.creditsunderothername[parseInt(action.index)].account = action.payload
            break;            
        }

        case ADD_BORROWER_GIFT: {
            let obj = state.assetsandliabilities
            let add = new gift()
            if(null === obj.gifts)
                obj.gifts = []
            add.key = Math.random().toString() 
            obj.gifts.push( add )
            break;
        }
        case BULKADD_BORROWER_GIFTS: {
            let obj = state.assetsandliabilities
            obj.gifts = action.payload
            break;
        }
        case CLEAR_BORROWER_GIFT: {
            let obj = state.assetsandliabilities
            obj.gifts = []
            break;
        }
        case REMOVE_BORROWER_GIFT: {
            let obj = state.assetsandliabilities
            obj.gifts.splice(parseInt(action.index), 1)
            break;
        }
        case CHANGE_BORROWER_GIFT: {
            let obj = state.assetsandliabilities
            obj.gifts[action.index][action.verb] = action.value
            break;
        }        

        case ADD_BORROWER_ADDITIONAL_PROPERTIES_CREDIT: {            
            let obj = state.assetsandliabilities
            obj.propertiesfrompull = action.payload
            break;
        }

        case ADD_BORROWER_ADDITIONAL_PROPERTIES_ATTRIBUTES: {
            let obj = state.assetsandliabilities.propertiesfrompull
            obj[action.index][action.verb] = action.payload
            break;            
        }

        case ADD_REMOTE_COBORROWER: {
            let add = new remotecoborrower()
            add.key = Math.random().toString() 
            state.remotecoborrowers.push( add )
            break;
        }
        case REMOVE_REMOTE_COBORROWER: {
            state.remotecoborrowers.splice(parseInt(action.payload), 1)
            break;
        }
        case CLEAR_REMOTE_COBORROWER: {
            state.remotecoborrowers = []
            break;
        }
        case CHANGE_REMOTE_COBORROWER_FIRSTNAME: {
            let add = new remotecoborrower()
            add.key = Math.random().toString() 
            state.remotecoborrowers[parseInt(action.index)].firstname = action.payload
            break;
        }        
        case CHANGE_REMOTE_COBORROWER_LASTNAME: {
            let add = new remotecoborrower()
            add.key = Math.random().toString() 
            state.remotecoborrowers[parseInt(action.index)].lastname = action.payload
            break;
        }        
        case CHANGE_REMOTE_COBORROWER_EMAIL: {
            let add = new remotecoborrower()
            add.key = Math.random().toString() 
            state.remotecoborrowers[parseInt(action.index)].email = action.payload
            break;
        }        
        case CHANGE_REMOTE_COBORROWER_STATUS: {
            let add = new remotecoborrower()
            add.key = Math.random().toString() 
            state.remotecoborrowers[parseInt(action.index)].status = action.payload
            break;
        }        
        
        case CHANGE_REMOTE_COBORROWER_USE: {
            let add = new remotecoborrower()
            add.key = Math.random().toString() 
            state.remotecoborrowers[parseInt(action.index)].usetoqualify = action.payload
            break;
        }        

        case CHANGE_PROPERTY_DESCRIPTION:
            state.property.description = action.payload; break;
        case CHANGE_PROPERTY_ADDRESS:
            state.property.address = action.payload; break;
        case CHANGE_PROPERTY_OCCUPANCY:
            state.property.occupancy = action.payload; break;
        case CHANGE_PROPERTY_PURPOSE:
            state.property.purpose = action.payload; break;
        case CHANGE_PROPERTY_APPRAISAL:
            state.property.appraisal = action.payload; break;
        case CHANGE_PROPERTY_SALESPRICE:
            state.property.salesprice = action.payload; break;
        case CHANGE_PROPERTY_UNITS:
            state.property.units = action.payload; break;
        case CHANGE_PROPERTY_YEARBUILT:
            state.property.yearbuilt = action.payload; break;
        case CHANGE_PROPERTY_INWHATNAME:
            state.property.inwhatname = action.payload; break;
        case CHANGE_PROPERTY_ESTATEHELDIN:
            state.property.estateheldin = action.payload; break;
        case CHANGE_PROPERTY_INWHATMANNER:
            state.property.inwhatmanner = action.payload; break;
        case CHANGE_PROPERTY_SOURCES:
            state.property.sources = action.payload; break;      
        case  CHANGE_PROPERTY_ATTRIBUTE: {
            state.property[action.verb] = action.payload
            break;
        }        
        case CHANGE_PROPERTY_REFINANCE_YEARACQUIRED:
            state.property.refinance.yearacquired = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_ORIGINALCOST:
            state.property.refinance.originalcost = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_PURPOSE:
            state.property.refinance.purpose = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSDESC:
            state.property.refinance.improvementsdesc = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSSTATUS:
            state.property.refinance.improvementsstatus = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_COST:
            state.property.refinance.cost = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_EXPLAINOTHER:
            state.property.refinance.explainotherpurpose = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_ATTRIBUTE:
            state.property.refinance[action.verb] = action.payload; break;
        case CHANGE_PROPERTY_CONSTRUCTION_YEARACQUIRED:
            state.property.construction.yearacquired = action.payload; break;
        case CHANGE_PROPERTY_CONSTRUCTION_ORIGINALCOST:
            state.property.construction.originalcost = action.payload; break;
        case CHANGE_PROPERTY_CONSTRUCTION_EXISTINGLIENS:
            state.property.construction.existingliens = action.payload; break;
        case CHANGE_PROPERTY_REFINANCE_EXISTINGLIENS:
            state.property.refinance.existingliens = action.payload; break;
        case CHANGE_PROPERTY_CONSTRUCTION_PRESENTVALUE:
        state.property.construction.presentvalue = action.payload; break;
        case CHANGE_PROPERTY_CONSTRUCTION_COSTOFIMPROVEMENTS:
            state.property.construction.costofimprovements = action.payload; break;
        case CHANGE_DECLARATIONS: {
            let obj = state[action.who].declarations 
            obj[action.verb] = action.payload
            break;
        }
        case CHANGE_MAINPROPERTY_EXPIRATION: {
            state.property.expirationdate  = action.payload
            break;
        }        
        case CHANGE_MAINPROPERTY_PROPERTYTYPE: {
            state.property.propertytype  = action.payload
            break;
        }
        case CHANGE_MOBILE_HOME_INFO: {
            state.property.mobilehomeinfo[action.verb]  = action.payload
            break;
        }        
        case CHANGE_MAINPROPERTY: {
            let obj = state.property
            let secs = action.verb.split('|')

            for(let i = 0; i < secs.length - 1; i++) {
                obj = obj[secs[i]]
            }
            obj[secs[secs.length - 1]]  = action.payload
            break;
        }        
        
        case CHANGE_EQUAL_OPPORTUNITY: {
            let obj = state[action.who].equalopportunity
            obj[action.verb] = action.payload
            break;
        }
        case CHANGE_EQUAL_OPPORTUNITY_SUB: {       
            let obj = state[action.who].equalopportunity
            let secs = action.section.split('|')
            secs.forEach( sec => {
                obj = obj[sec]
            })
            obj[action.verb] = action.payload
            break;
        }        
        case CHANGE_PRODUCT: {
            let obj = state.product
            obj[action.verb] = action.payload            
            break;
        }
        case CHANGE_TRANSACTION:{
            let obj = state.transaction
            obj[action.verb] = action.payload            
            break;
        }
        case CHANGE_SOLAR_PANELS: {
            state.property.solarpanels[action.verb]  = action.payload
            break
        }
        case UPDATE_CANNOTSHOPSERVICES: {
       
            let obj = state.selection.selectedloan.estimatedclosingcosts.cannotshopservices
            let i = 0
            let found = false
            for(; i < obj.length; i++) {
                if(obj[i].servicename === action.servicename) {
                    obj[i].estimateamount = action.estimateamount
                    found = true
                    break
                }
            }
            if(found)
                break
            let newfee = new service()
            newfee.servicename = action.servicename
            newfee.estimateamount = action.estimateamount
            obj.push(newfee)
            break
        }
        case UPDATE_CANSHOPSERVICES: {
            let obj = state.selection.selectedloan.estimatedclosingcosts.canshopservices
            let i = 0
            let found = false
            for(; i < obj.length; i++) {
                if(obj[i].servicename === action.servicename) {
                    obj[i].estimateamount = action.estimateamount
                    found = true
                    break
                }
            }
            if(found)
                break
            let newfee = new service()
            newfee.servicename = action.servicename
            newfee.estimateamount = action.estimateamount
            obj.push(newfee)
            break
        }
        case UPDATE_FEES: {
            let obj = state.selection.selectedloan.estimatedclosingcosts
            obj[action.feename] =  action.estimateamount
            break
        }        
        case UPDATE_ORIGINALCOSTS: {
            let obj = state.selection.selectedloan.estimatedclosingcosts.originalcosts
            let i = 0
            let found = false
            for(; i < obj.length; i++) {
                if(obj[i].chargename === action.chargename) {
                    obj[i].estimateamount = action.estimateamount
                    found = true
                    break
                }
            }
            if(found)
                break
            let newfee = new charge()
            newfee.chargename = action.chargename
            newfee.estimateamount = action.estimateamount
            obj.push(newfee)
            break
        }
        case UPDATE_OTHERCOSTS: {
            let obj = state.selection.selectedloan.estimatedclosingcosts.othercosts
            let i = 0
            let found = false
            for(; i < obj.length; i++) {
                if(obj[i].chargename === action.chargename) {
                    obj[i].estimateamount = action.estimateamount
                    found = true
                    break
                }
            }
            if(found)
                break
            let newfee = new charge()
            newfee.chargename = action.chargename
            newfee.estimateamount = action.estimateamount
            obj.push(newfee)
            break
        }
        case ADD_SELECTEDLOAN_ESTIMATEDCLOSING_COSTS: {
            state.selection.selectedloan.estimatedclosingcosts = cloneDeep(action.payload)
            break
        }
        case ADD_SELECTEDLOAN_SERVICE: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            for(; index < obj.length; index++) {
                if(obj[index].servicename === action.chargename) {
                    found = true
                    break
                }
            }           
            if(found)
                break            
            let newfee = new service()
            newfee.servicename = action.chargename
            newfee.estimateamount = 0
            obj.push(newfee)
            break;
        }
        case REMOVE_SELECTEDLOAN_SERVICE: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            for(; index < obj.length; index++) {
                if(obj[index].servicename === action.chargename) {
                    found = true
                    break
                }
            }             
            if(found) {
                obj.splice(index, 1)
            }           
            break;
        }
        case ADD_SELECTEDLOAN_CHARGE: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            for(; index < obj.length; index++) {
                if(obj[index].chargename === action.chargename) {
                    found = true
                    break
                }
            }           
            if(found)
                break            
            let newfee = new charge()
            newfee.chargename = action.chargename
            newfee.estimateamount = 0
            obj.push(newfee)
            break;
        }
        case REMOVE_SELECTEDLOAN_CHARGE: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            for(; index < obj.length; index++) {
                if(obj[index].chargename === action.chargename) {
                    found = true
                    break
                }
            }             
            if(found) {
                obj.splice(index, 1)
            }           
            break;
        }        

        case ADD_SELECTEDLOAN_PREPAID: {
     
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            for(; index < obj.length; index++) {
                if(obj[index].paymentname === action.chargename) {
                    found = true
                    break
                }
            }        
            if(found)
                break
            let newfee = new payment()
            newfee.paymentname = action.chargename
            newfee.estimateamount = 0
            newfee.period = action.period
            obj.push(newfee)
            break;
        }
        case REMOVE_SELECTEDLOAN_PREPAID: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            for(; index < obj.length; index++) {
                if(obj[index].paymentname === action.chargename) {
                    found = true
                    break
                }
            }             
            if(found) {
                obj.splice(index, 1)
            }           
            break;
        }     

        case UPDATE_PAYMENT_PERIOD: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]            
            let index = 0

            for(; index < obj.length; index++) {
                if(obj[index].paymentname === action.name) {
                    obj[index].prepaidperiods = action.period
                    break
                }
            }      
            break
        }
        case UPDATE_PAYMENT_AMOUNT: {
            let obj = state.selection.selectedloan.estimatedclosingcosts[action.service]            
            let index = 0

            for(; index < obj.length; index++) {
                if(obj[index].paymentname === action.name) {
                    obj[index ].amount = action.amount
                    break
                }
            }      
            break
        }
        case UPDATE_PREPAID_DEFAULT: {
            let obj = state.selection.estimatedclosingcosts[action.service]
            let found = false
            let index = 0
            let entry
            for(; index < obj.length; index++) {
                if(obj[index].paymentname === action.name) {
                    found = true
                    entry = obj[index]
                    break
                }
            }        
            if(!found) {
                entry = new payment()
                obj.push(entry)         
            } 

            entry.paymentname = action.name
            entry.amount = action.amount
            entry.period = action.period 
            entry.prepaidperiods = action.prepaidperiods
            break;
        }
        case ADD_NEW_MORTGAGE_LOAN: {
            let obj = state.assetsandliabilities
            if(null === obj.newloans)
                obj.newloans = []
            let add = new newmortgage()
            add.key = Math.random().toString() 
            obj.newloans.push(add)
            break;            
        }
        case BULKADD_NEW_MORTGAGE_LOAN: {
            let obj = state.assetsandliabilities
            obj.newloans = action.payload
            break;           
        }
        case REMOVE_NEW_MORTGAGE_LOAN: {
            let obj = state.assetsandliabilities
            obj.newloans.splice(parseInt(action.payload), 1)            
            break
        }
        case CLEAR_NEW_MORTGAGE_LOANS: {
            let obj = state.assetsandliabilities
            obj.newloans = []            
            break;
        }
        case UPDATE_NEW_MORTGAGE_ATTRIBUTE: {

            let obj = state.assetsandliabilities
            obj.newloans[action.index][action.verb] = action.value
            break
        }
        case UPDATE_ESCROW_INFO: {
            if (state.escrowInfo === null) {
                state.escrowInfo = new escrowinfo()
            }
                state.escrowInfo[action.verb] = action.value
            break
        }
        case UPDATE_APPRAISAL_INFO: {
            if (state.appraisalInfo === null) {
                state.appraisalInfo = new appraisalinfo()
            }
            if (action.index !== undefined) {
                state.appraisalInfo.appraisaldetails[action.index][action.verb] = action.value
            } else {
                state.appraisalInfo[action.verb] = action.value
            }
            break
        }
        default:
            break;
    }
    return JSON.parse( state.toJson() );
}

export default applicationReducer;